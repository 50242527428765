import { useAuth } from '@src/auth';
import { useEffect, useMemo } from 'react';
import { useLazySimulateRewardAllocationQuery } from '@async-calls/rewardAllocations';

const UseRewardConvertCard = reward => {
  const { hierarchyNodeUser } = useAuth();
  const defaultPoint = hierarchyNodeUser.point_balance.available;
  const [simulate, simulationResult] = useLazySimulateRewardAllocationQuery();

  const disableConvert = useMemo(() => {
    return (
      !simulationResult?.data?.max_points_usable ||
      !simulationResult?.data?.order_suggested.points
    );
  }, [simulationResult, hierarchyNodeUser, reward]);

  useEffect(() => {
    if (!defaultPoint) return;
    simulate({
      id: reward._context.allocation.uuid,
      points: defaultPoint,
      hierarchyNodeUserId: hierarchyNodeUser.uuid,
    });
  }, [defaultPoint, reward._context, hierarchyNodeUser.uuid]);

  return { simulationResult, defaultPoint, disableConvert };
};

export default UseRewardConvertCard;
