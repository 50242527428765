import React from 'react';
import { ConvertPoints } from '@src/Spider/scenes/ConvertPoints/ConvertPoints';
import { ConvertPointsProvider } from '@src/Spider/hooks/useConvertPoints';
import { useLocation } from 'react-router-dom';

const Component = () => {
  const location = useLocation();
  const reward = location.state?.uuid ? location.state : null;

  return (
    <ConvertPointsProvider rewardData={reward}>
      <ConvertPoints />
    </ConvertPointsProvider>
  );
};

const ConvertPointWrapper = Component;

export { ConvertPointWrapper };
