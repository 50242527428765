import { PointCommandIds } from '@src/Spider/enums';
import kscCard from '@spider:src/assets/img/homeParticipantsConvert/ksc-card.png';
import cksCard from '@spider:src/assets/img/homeParticipantsConvert/cks-card.png';
import ksCard from '@spider:src/assets/img/homeParticipantsConvert/ks-card.png';
import ckuCard from '@spider:src/assets/img/homeParticipantsConvert/cku-card.png';

export const useDefaultRewardImage = reward => {
  switch (reward.uuid) {
    case PointCommandIds.CKS:
      return cksCard;
    case PointCommandIds.CKU:
      return ckuCard;
    case PointCommandIds.KSC:
      return kscCard;
    case PointCommandIds.KS:
      return ksCard;
    default:
      return ckuCard;
  }
};
