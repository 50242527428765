export const useDecimalHelper = () => {
  /**
   * @param {string} value - The value to format
   * @returns {string | null}
   */
  const convertDecimalToText = value => {
    return value?.toString()?.replaceAll('.', ',');
  };

  /**
   * @param {string} value - The value to format in case of validation
   * @param {number} numberOfDigits - The number of acceptable digits
   * @param {string} initialValue - The initialValue
   * @returns {string}
   */
  const convertTextWithComma = (
    value,
    numberOfDigits = 2,
    initialValue = '',
  ) => {
    if (typeof value !== 'string') return initialValue;
    const dynamicRegex = RegExp(`^[\\d]*[,.]?[\\d]{0,${numberOfDigits}}$`);
    if (dynamicRegex.exec(value)) {
      return value?.replaceAll('.', ',');
    }
    return initialValue;
  };

  /**
   * @param {string} value - The value to format and parse
   * @returns {number}
   */
  const convertTextToDecimal = value => {
    const parsed = parseFloat(value?.toString()?.replaceAll(',', '.'));
    return isNaN(parsed) ? 0 : parsed;
  };
  return { convertDecimalToText, convertTextWithComma, convertTextToDecimal };
};
