import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { neutralColors } from '@src/Spider';
import { useMediaQuery } from '@mui/material';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5rem',
  },
  helperContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    padding: '1.5rem 0rem 1.5rem 1.5rem',
    [createTheme().breakpoints.down('sm')]: {
      padding: '1.5rem',
    },
  },
  helperTitle: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    gap: '0.5rem',
  },
  helperDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    borderRadius: '1rem',
    backgroundColor: neutralColors.neutralColdWhite,
  },
});

/**
 * @param {{
 *   title: string,
 *   subtitle: string,
 *   imageSrc: string,
 *   imageAlt: string
 *   children: React.ReactNode
 * }} props
 */
const ConvertHelpWrapper = ({
  title,
  subtitle,
  imageSrc,
  imageAlt,
  children,
}) => {
  const isMobile = useMediaQuery(createTheme().breakpoints.down('sm'));
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <div className={classes.helperContent}>
        <div className={classes.helperTitle}>
          <Typography
            variant={'h3'}
            component={'h3'}
            style={{ textAlign: 'left' }}
          >
            {title}
          </Typography>
          {subtitle?.length > 0 && (
            <Typography variant={'body2'} style={{ textAlign: 'left' }}>
              {subtitle}
            </Typography>
          )}
        </div>
        <div className={classes.helperDetails}>{children}</div>
      </div>
      {!isMobile && <img src={imageSrc} alt={imageAlt} />}
    </div>
  );
};

export { ConvertHelpWrapper };
