import { all, call, put, takeLatest } from 'redux-saga/effects';
import { createMainKeySlice } from './slices';
import api from '../../../data/api/api';
import { ConfigCodes } from '../../enums/configCodes';

function* loadMainConfigs(slice, action) {
  try {
    const { teamGroupId } = action;

    const configs = yield call(api.configs.permanent, teamGroupId, [
      ConfigCodes.MAIN_COLOR,
    ]);

    yield put(slice.actions.loadConfigsSuccess({ configs }));
  } catch (error) {
    yield put(slice.actions.mainFailure(error?.response?.data ?? 'UNKNOWN'));
  }
}

function* createMain(slice, action) {
  try {
    const { teamGroupId } = action.payload;

    const hierarchyNode = yield call(api.hierarchyNodes.get, teamGroupId);

    yield put(slice.actions.setMainHierarchyNode(hierarchyNode.data));
    return hierarchyNode.data;
  } catch (error) {
    yield put(slice.actions.mainFailure(error?.response?.data ?? 'UNKNOWN'));
  }
}

function* watchCreateMain(slice) {
  yield takeLatest(slice.actions.mainStart.type, function* (action) {
    const hierarchyNode = yield call(createMain, slice, action);
    if (hierarchyNode) {
      yield call(loadMainConfigs, slice, { teamGroupId: hierarchyNode.code });
    }
  });
}

export default function* createMainSaga() {
  yield all([watchCreateMain(createMainKeySlice)]);
}
