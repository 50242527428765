import { Link, Typography } from '@material-ui/core';
import { IconDetails } from '@spider:components/IconDetails';
import { Card, ExportSquare, Lock1, Mobile, Send, Sms } from 'iconsax-react';
import { systemColors } from '@src/Spider';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLinkOpener } from '@src/Spider/hooks/useLinkOpener';
import { ConvertHelpWrapper } from '@spider:components/ConvertHelp/ConvertHelpWrapper';
import CksHelpImage from '@src/Spider/assets/img/cks-help.png';

const CKSHelper = () => {
  const intl = useIntl();
  const { openLink } = useLinkOpener();

  const details = [
    {
      icon: Card,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.cks.help.features.shop',
      }),
    },
    {
      icon: Mobile,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.cks.help.features.app',
      }),
    },
    {
      icon: Lock1,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.cks.help.features.lock',
      }),
    },
  ];

  const todos = [
    {
      icon: Send,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.cks.help.steps.one',
      }),
    },
    {
      icon: Sms,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.cks.help.steps.two',
      }),
    },
  ];

  return (
    <ConvertHelpWrapper
      title={intl.formatMessage({
        id: 'spider.convertPoints.home.cks.help.title',
      })}
      subtitle={intl.formatMessage({
        id: 'spider.convertPoints.home.cks.help.subtitle',
      })}
      imageSrc={CksHelpImage}
      imageAlt='cks-help'
    >
      <Typography
        variant='body1'
        style={{ textAlign: 'left', fontWeight: '700' }}
      >
        {intl.formatMessage({
          id: 'spider.convertPoints.home.cks.help.feature_title',
        })}
      </Typography>

      {details.map((detail, index) => (
        <IconDetails key={index} Icon={detail.icon} message={detail.message} />
      ))}

      <Link
        className={'bold small icon'}
        onClick={() => openLink(process.env.REACT_APP_EDENRED_CKS_HELP_LINK)}
        style={{ textAlign: 'left' }}
      >
        <ExportSquare size='16' color={systemColors.infoRegular} />
        {intl.formatMessage({
          id: 'spider.convertPoints.home.cks.help.cta',
        })}
      </Link>

      <Link
        className={'bold small icon'}
        onClick={() =>
          openLink(process.env.REACT_APP_EDENRED_CKS_HELP_LINK_CARD)
        }
        style={{ textAlign: 'left' }}
      >
        <ExportSquare size='16' color={systemColors.infoRegular} />
        {intl.formatMessage({
          id: 'spider.convertPoints.home.cks.help.cta2',
        })}
      </Link>

      <Typography
        variant='body1'
        style={{ textAlign: 'left', fontWeight: '700' }}
      >
        {intl.formatMessage({
          id: 'spider.convertPoints.home.cks.help.how_it_work',
        })}
      </Typography>

      {todos.map((detail, index) => (
        <IconDetails key={index} Icon={detail.icon} message={detail.message} />
      ))}
    </ConvertHelpWrapper>
  );
};

export { CKSHelper };
