import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useGetHierarchyNodeUserDetailsQuery } from '@async-calls/hierarchyNodeUsers';
import {
  Box,
  CircularProgress,
  Typography,
  createTheme,
  makeStyles,
} from '@material-ui/core';
import { ParticipantUrl } from '@src/Spider/enums/participantUrl';
import { ProfileCard } from './components/ProfileCard';
import { StatusCard } from './components/StatusCard';
import { DetailsTab } from './tabs/Details';
import { PointsList } from '@src/Spider/components/PointsList/PointsList';
import { OwnerEnum } from '@src/Spider/enums/ownerType';
import { OrdersList } from '@src/Spider/components/OrdersList/OrdersList';
import { TabRoutes } from '@src/Spider/components/TabRoutes';
import { Button } from '@src/Spider/components';
import { useIntl } from 'react-intl';
import { ImportDialog } from '@src/Spider/components/ImportDialog';
import { ImportLogsTypes } from '@src/Spider/enums/importLogsTypes';
import { PointsAdminUrl } from '@src/Spider/enums/pointsAdminUrl';
import { ArrowLeft } from 'iconsax-react';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  header: {
    padding: '0rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    [createTheme().breakpoints.down('xs')]: {
      padding: '0rem 1rem',
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  updateButton: {
    width: 'fit-content',
    maxWidth: 'unset',
    [createTheme().breakpoints.down('xs')]: {
      width: '100%',
    },
  },
});

const Users = () => {
  const classes = useStyles();
  /** @type {{contract: string, user_id: string}} */
  const { contract, user_id } = useParams();
  const { data, isSuccess, isLoading } = useGetHierarchyNodeUserDetailsQuery({
    user_id,
    include_address: true,
    include_cku: true,
    include_ksc: true,
    include_manager: true,
    include_point_balance: true,
  });
  const intl = useIntl();
  const [isImportOpen, setIsImportOpen] = useState(false);
  const history = useHistory();

  const redirect = options => {
    if (!options?.type) return;
    if (
      options.type === ImportLogsTypes.POINTS &&
      history.location.pathname.includes('users')
    ) {
      history.push(`/nodes/${contract}/points/${PointsAdminUrl.HISTORY}`);
    }
  };

  const tabPaths = Object.values(ParticipantUrl);

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '30vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );

  const routes = [
    {
      path: ParticipantUrl.DETAILS,
      component: <DetailsTab user={data} isLoading={isLoading} />,
    },
    {
      path: ParticipantUrl.POINTS,
      component: (
        <PointsList
          queryParams={{
            id: contract,
            owner_types: [OwnerEnum.HIERARCHY_NODE_USER],
            hierarchy_node_user: user_id,
          }}
        />
      ),
    },
    {
      path: ParticipantUrl.ORDERS,
      component: (
        <OrdersList
          queryParams={{
            id: contract,
            owner_types: [OwnerEnum.HIERARCHY_NODE_USER],
            hierarchy_node_user: user_id,
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Box>
        <Box className={classes.header}>
          <Box className={classes.title}>
            <Button
              variant='text'
              size='small'
              component={Link}
              to={`/nodes/${contract}/users/`}
            >
              <ArrowLeft />
            </Button>
            <Typography variant='h1' component='h1' className='underline-left'>
              {data?.firstname} {data?.lastname}
            </Typography>
          </Box>
          <Button
            className={classes.updateButton}
            color='primary'
            variant='outlined'
            size='small'
            onClick={() => setIsImportOpen(true)}
          >
            {intl.formatMessage({ id: 'spider.participants.update' })}
          </Button>
        </Box>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '1.5rem',
            marginTop: '2rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: '1rem',
              gap: '1rem',
              [createTheme().breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            }}
          >
            {isSuccess && (
              <>
                <ProfileCard user={data} />
                <StatusCard user={data} />
              </>
            )}
          </Box>
          <TabRoutes
            basePath={`/nodes/:contract/users/:user_id/infos`}
            baseUrl={`/nodes/${contract}/users/${user_id}/infos`}
            tabPaths={tabPaths}
            tabNamespace='spider.hierarchy_node_user_pages.tabs'
            routes={routes}
            defaultTab={ParticipantUrl.DETAILS}
          />
        </Box>
      </Box>
      <ImportDialog
        open={isImportOpen}
        setOpen={setIsImportOpen}
        onFileCreated={redirect}
        defaultTab={'2'}
        fixedCacheKey={'participant'}
      />
    </>
  );
};

export default Users;
