import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { CardAction } from '@spider:src/components/CardAction';
import UserBoldOctoStyle from '@spider:src/assets/img/user-bold-octo-style.svg';
import CupBoldOctoStyle from '@spider:src/assets/img/cup-bold-octo-style.svg';
import Laptop from '@spider:src/assets/img/admin-home-laptop.svg';
import { withStyles } from '@mui/styles';
import { createTheme } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { TitleContainer } from '@src/Spider/components/TitleContainer';
import { useLinkOpener } from '@src/Spider/hooks/useLinkOpener';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    paddingRight: '0.5rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    [createTheme().breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
};

const AdminHome = ({ ...props }) => {
  const intl = useIntl();
  const { contract } = useParams();
  const history = useHistory();
  const { openLink } = useLinkOpener();

  return (
    <div className={props.classes.root}>
      <TitleContainer>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({ id: 'spider.home.welcome' })}
        </Typography>
      </TitleContainer>

      <CardAction
        title={intl.formatMessage({
          id: 'spider.home.admin.cardActions.accessPersonalSpace.title',
        })}
        description={intl.formatMessage({
          id: 'spider.home.admin.cardActions.accessPersonalSpace.description',
        })}
        buttonLabel={intl.formatMessage({
          id: 'spider.home.admin.cardActions.accessPersonalSpace.buttonLabel',
        })}
        onClick={() => openLink(process.env.REACT_APP_EDENRED_CLIENT_SPACE)}
        sourceImage={Laptop}
        enableBackgroundImage={true}
      />
      <div className={props.classes.actions}>
        <CardAction
          title={intl.formatMessage({
            id: 'spider.home.admin.cardActions.addParticipant.title',
          })}
          description={intl.formatMessage({
            id: 'spider.home.admin.cardActions.addParticipant.description',
          })}
          buttonLabel={intl.formatMessage({
            id: 'spider.home.admin.cardActions.addParticipant.buttonLabel',
          })}
          onClick={() => {
            history.push(`/nodes/${contract}/users`);
          }}
          sourceImage={UserBoldOctoStyle}
        />
        <CardAction
          title={intl.formatMessage({
            id: 'spider.home.admin.cardActions.distributePoints.title',
          })}
          description={intl.formatMessage({
            id: 'spider.home.admin.cardActions.distributePoints.description',
          })}
          buttonLabel={intl.formatMessage({
            id: 'spider.home.admin.cardActions.distributePoints.buttonLabel',
          })}
          onClick={() => {
            history.push(`/nodes/${contract}/points`);
          }}
          sourceImage={CupBoldOctoStyle}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(AdminHome);
