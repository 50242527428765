import React from 'react';
import { useIntl } from 'react-intl';
import { createTheme, withStyles } from '@material-ui/core/styles';
import { Divider, Typography, useMediaQuery } from '@material-ui/core';
import { BaseAddressForm } from '@spider:components/Form/BaseAddressForm';
import { Alert } from '@spider:components/Alert';
import { PointCommandIds } from '@src/Spider/enums';
import { SystemCode } from '@src/Spider/enums/systemCode';
import { ReactComponent as WarningIcon } from '@src/Spider/assets/img/warning-icon.svg';
import { ReactComponent as InfoCircle } from '@src/Spider/assets/img/homeParticipantsConvert/info-circle.svg';
import { useAuth } from '@src/auth';
import { neutralColors } from '@src/Spider';
import { ParticipantType } from '@src/Spider/enums/participantType';

const styles = {
  blockWrapper: {
    display: 'flex',
    gap: '1.5rem',
    flexDirection: 'row',
    [createTheme().breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  informationCard: {
    display: 'flex',
    padding: '1rem 1rem 1rem 1rem',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'center',
    borderRadius: '1rem',
    overflow: 'clip',
    position: 'relative',
    backgroundColor: neutralColors.neutralColdWhite,
  },
};

/**
 * @param {{
 *   convertPointsContext: Object,
 *   formData: Object,
 *   classes: any,
 *   handleFormChange: (formData: Object) => void,
 * }} props
 */
const Component = ({
  convertPointsContext,
  formData,
  classes,
  handleFormChange,
}) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('sm'));
  const { hierarchyNodeUser } = useAuth();

  const hasDivider = !isMobile && formData.personal_address;
  const isInfoAlert =
    convertPointsContext.reward?.uuid === PointCommandIds.CKU &&
    hierarchyNodeUser.type === ParticipantType.MORALE;

  return (
    <div className={classes.blockWrapper}>
      {formData.personal_address && (
        <div className={classes.block}>
          <Typography variant={'h4'} component={'h4'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.informationStep.personalAddress.titlePersonal',
            })}
          </Typography>
          <Alert
            type={SystemCode.WARNING}
            Icon={() => <WarningIcon />}
            title={intl.formatMessage({
              id: 'spider.convertPoints.stepper.informationStep.personalAddress.alertTitle',
            })}
            titleVariant={'subtitle1'}
            titleComponent={'span'}
          >
            <Typography
              variant={'body2'}
              style={{
                color: neutralColors.neutral600,
              }}
            >
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.informationStep.personalAddress.alertMessage',
              })}
            </Typography>
          </Alert>
          <BaseAddressForm
            formData={formData.personal_address}
            baseProps={'personal_address'}
            error={convertPointsContext?.error?.form?.personal_address}
            classes={classes}
            handleFormChange={handleFormChange}
          />
        </div>
      )}
      {hasDivider && <Divider orientation='vertical' flexItem />}
      <div className={classes.block}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.informationStep.deliveryAddress.title',
          })}
        </Typography>
        {(hierarchyNodeUser.type === ParticipantType.MORALE ||
          convertPointsContext.reward?.uuid === PointCommandIds.CKU) && (
          <Alert
            type={isInfoAlert ? SystemCode.INFO : SystemCode.WARNING}
            Icon={() => (isInfoAlert ? <InfoCircle /> : <WarningIcon />)}
            title={intl.formatMessage({
              id:
                convertPointsContext.reward?.uuid === PointCommandIds.CKU
                  ? 'spider.convertPoints.stepper.informationStep.deliveryAddress.alertTitleSpe'
                  : 'spider.convertPoints.stepper.informationStep.deliveryAddress.alertTitle',
            })}
            titleVariant={'subtitle1'}
            titleComponent={'span'}
          >
            <Typography
              variant={'body2'}
              style={{
                color: neutralColors.neutral600,
              }}
            >
              {intl.formatMessage({
                id:
                  convertPointsContext.reward?.uuid === PointCommandIds.CKU
                    ? 'spider.convertPoints.stepper.informationStep.deliveryAddress.alertMessageSpe'
                    : 'spider.convertPoints.stepper.informationStep.deliveryAddress.alertMessage',
              })}
            </Typography>
          </Alert>
        )}
        {hierarchyNodeUser.type === ParticipantType.MORALE ? (
          <div
            className={classes.informationCard}
            style={{
              justifyContent: isInfoAlert ? 'start' : 'center',
              height: isInfoAlert ? '100%' : 'auto',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              {hierarchyNodeUser.legal_entity_address.company_name && (
                <Typography variant={'body2'}>
                  {hierarchyNodeUser.legal_entity_address.company_name}
                </Typography>
              )}
              {hierarchyNodeUser.legal_entity_address.line1 && (
                <Typography variant={'body2'}>
                  {hierarchyNodeUser.legal_entity_address.line1}
                </Typography>
              )}
              {hierarchyNodeUser.legal_entity_address.line2 && (
                <Typography variant={'body2'}>
                  {hierarchyNodeUser.legal_entity_address.line2}
                </Typography>
              )}
              {hierarchyNodeUser.legal_entity_address.city &&
                hierarchyNodeUser.legal_entity_address.postal_code && (
                  <Typography variant={'body2'}>
                    {`${hierarchyNodeUser.legal_entity_address.postal_code} ${hierarchyNodeUser.legal_entity_address.city}`}
                  </Typography>
                )}
            </div>
          </div>
        ) : (
          formData.delivery_address && (
            <BaseAddressForm
              formData={formData.delivery_address}
              baseProps={'delivery_address'}
              error={convertPointsContext?.error?.form?.delivery_address}
              classes={classes}
              handleFormChange={handleFormChange}
            />
          )
        )}
      </div>
    </div>
  );
};

const FormAddressInformation = withStyles(styles)(Component);

export { FormAddressInformation };
