import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import neutralColors from '@src/Spider/themes/colors/neutralColors';
import { useAppConfiguration } from '@src/Spider/hooks/useAppConfiguration';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  waypointBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '0.5rem',
    position: 'relative',
  },
  waypoint: {
    display: 'flex',
    borderRadius: '4px',
    width: '2rem',
    minWidth: '2rem',
    height: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '2',
    backgroundColor: ({ mainColor }) => mainColor,
  },
  waypointLink: {
    position: 'absolute',
    left: '1rem',
    top: '1rem',
    height: 'calc(100% + 1rem)',
    width: '2px',
    zIndex: '1',
    backgroundColor: ({ mainColor }) => mainColor,
  },
});

/**
 * @param {{
 *   waypoints: {message: string}[],
 * }} props
 */
const WaypointsStepper = ({ waypoints }) => {
  const { config } = useAppConfiguration();
  const classes = useStyles({
    mainColor: config.mainColor?.value,
  });
  return (
    <div className={classes.root}>
      {waypoints.map((waypoint, index) => (
        <div key={index} className={classes.waypointBlock}>
          <div className={classes.waypoint}>
            <Typography
              variant={'h4'}
              component={'h4'}
              style={{ color: neutralColors.neutralWhite }}
            >
              {index + 1}
            </Typography>
          </div>

          <Typography style={{ textAlign: 'left' }}>
            {waypoint.message}
          </Typography>
          {index < waypoints?.length - 1 && (
            <div className={classes.waypointLink} />
          )}
        </div>
      ))}
    </div>
  );
};

export { WaypointsStepper };
