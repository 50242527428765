import React from 'react';
import { PointCommandIds } from '@spider:src/enums';
import { MainRewardConvertCard } from '../MainRewardConvertCard';
import { RewardConvertCard } from '../RewardConvertCard';

const RewardCard = ({ reward, ...props }) => {
  return (
    <React.Fragment>
      {reward.uuid === PointCommandIds.KSC && (
        <MainRewardConvertCard reward={reward} {...props} />
      )}
      {reward.uuid !== PointCommandIds.KSC && (
        <RewardConvertCard reward={reward} {...props} />
      )}
    </React.Fragment>
  );
};

export default RewardCard;
