import { Link, Typography } from '@material-ui/core';
import { IconDetails } from '@spider:components/IconDetails';
import {
  Card,
  CardAdd,
  ExportSquare,
  Lock1,
  Mobile,
  Send,
  Sms,
  Wallet,
} from 'iconsax-react';
import { systemColors } from '@src/Spider';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLinkOpener } from '@src/Spider/hooks/useLinkOpener';
import { ConvertHelpWrapper } from '@spider:components/ConvertHelp/ConvertHelpWrapper';
import CkuHelpImage from '@src/Spider/assets/img/cku-help.png';

const CKUHelper = () => {
  const intl = useIntl();
  const { openLink } = useLinkOpener();

  const details = [
    {
      icon: Card,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.cku.help.features.card',
      }),
    },
    {
      icon: Wallet,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.cku.help.features.reload',
      }),
    },
    {
      icon: Mobile,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.cku.help.features.app',
      }),
    },
    {
      icon: Lock1,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.cku.help.features.lock',
      }),
    },
  ];

  const todos = [
    {
      icon: Send,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.cku.help.steps.one',
      }),
    },
    {
      icon: Sms,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.cku.help.steps.two',
      }),
    },
  ];

  return (
    <ConvertHelpWrapper
      title={intl.formatMessage({
        id: 'spider.convertPoints.home.cku.help.title',
      })}
      subtitle={intl.formatMessage({
        id: 'spider.convertPoints.home.cku.help.subtitle',
      })}
      imageSrc={CkuHelpImage}
      imageAlt='cku-help'
    >
      <Typography
        variant='body1'
        style={{ textAlign: 'left', fontWeight: '700' }}
      >
        {intl.formatMessage({
          id: 'spider.convertPoints.home.cku.help.feature_title',
        })}
      </Typography>

      {details.map((detail, index) => (
        <IconDetails key={index} Icon={detail.icon} message={detail.message} />
      ))}

      <Link
        className={'bold small icon'}
        onClick={() =>
          openLink(process.env.REACT_APP_EDENRED_CKU_HELP_LINK_CARD)
        }
        style={{ textAlign: 'left' }}
      >
        <ExportSquare size='16' color={systemColors.infoRegular} />
        {intl.formatMessage({
          id: 'spider.convertPoints.home.cku.help.cta',
        })}
      </Link>

      <Typography
        variant='body1'
        style={{ textAlign: 'left', fontWeight: '700' }}
      >
        {intl.formatMessage({
          id: 'spider.convertPoints.home.cku.help.how_it_work',
        })}
      </Typography>

      <Typography variant='body1' style={{ textAlign: 'left' }}>
        {intl.formatMessage({
          id: 'spider.convertPoints.home.cku.help.how_it_work_without_card',
        })}
      </Typography>

      {todos.map((detail, index) => (
        <IconDetails key={index} Icon={detail.icon} message={detail.message} />
      ))}

      <Typography variant='body1' style={{ textAlign: 'left' }}>
        {intl.formatMessage({
          id: 'spider.convertPoints.home.cku.help.existing_card.title',
        })}
      </Typography>

      <IconDetails
        Icon={CardAdd}
        message={intl.formatMessage({
          id: 'spider.convertPoints.home.cku.help.existing_card.content',
        })}
      />

      <Link
        className={'bold small icon'}
        onClick={() =>
          openLink(process.env.REACT_APP_EDENRED_CKU_HELP_LINK_CARD_RELOAD)
        }
        style={{ textAlign: 'left' }}
      >
        <ExportSquare size='16' color={systemColors.infoRegular} />
        {intl.formatMessage({
          id: 'spider.convertPoints.home.cku.help.cta_2',
        })}
      </Link>
    </ConvertHelpWrapper>
  );
};

export { CKUHelper };
