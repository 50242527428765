import { useState } from 'react';

/**
 * @param {any} contextValue
 * @param {Object} initialFormData
 */
export const useFormDataHelper = (contextValue, initialFormData) => {
  const [formData, setFormData] = useState(
    contextValue ? contextValue : initialFormData,
  );

  /**
   * @param {Object} formFieldEvent
   */
  const handleFormChange = formFieldEvent => {
    const { name, value, checked, type } = formFieldEvent.target;
    const nameParts = name.split('.');

    const targetValue = type === 'checkbox' ? checked : value;

    setFormData(prevState => {
      if (nameParts.length > 1) {
        return {
          ...prevState,
          [nameParts[0]]: {
            ...prevState[nameParts[0]],
            [nameParts[1]]: targetValue,
          },
        };
      }

      return {
        ...prevState,
        [name]: targetValue,
      };
    });
  };

  return { formData, handleFormChange };
};
