import { useMemo, useState } from 'react';
import { useDecimalHelper } from '@src/Spider/hooks/useDecimalHelper';

export const useDecimalValue = (initialValue, numberOfDigits) => {
  const { convertDecimalToText, convertTextToDecimal, convertTextWithComma } =
    useDecimalHelper();

  const initialValueFormatted = convertDecimalToText(initialValue);
  const [value, setValue] = useState(initialValueFormatted);

  /**
   * @param {string} targetValue - The value to format
   */
  const setValueFormatted = targetValue => {
    const newValue = convertTextWithComma(
      targetValue,
      numberOfDigits,
      value !== '' ? value : initialValueFormatted,
    );
    setValue(newValue);
  };

  const savableValue = useMemo(() => {
    return convertTextToDecimal(value);
  }, [value]);

  return [value, savableValue, setValueFormatted];
};
