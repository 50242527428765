import { useAuth } from '@src/auth';
import { ParticipantType } from '../enums/participantType';
import { useMemo } from 'react';

const UseLinkToUserType = ({ moraleObj, physicObj }) => {
  const { hierarchyNodeUser } = useAuth();

  const linkObject = useMemo(() => {
    let object;

    switch (hierarchyNodeUser.type) {
      case ParticipantType.MORALE:
        object = moraleObj;
        break;
      case ParticipantType.PHYSIC:
        object = physicObj;
        break;
      default:
        object = physicObj;
        break;
    }
    return object;
  }, [hierarchyNodeUser, moraleObj, physicObj]);

  return { linkObject };
};

export default UseLinkToUserType;
