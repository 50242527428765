import instance from '../instance';
import '../../../helpers/DateHelper';
import { baseApi } from '@src/store/api-query';
import {
  deleteImage,
  update,
  updateImage,
} from '@async-calls/rewardAllocations';
import { tagTypes } from '@src/store/api-tags';

/**
 * @param {string} id ID du noeud hiérarchique en base de données
 */
const baseUrl = id => `rewards/${id}/`;

const baseUrlOld = 'rewards/';

const rewards = {
  /** @param {RewardListParams} queryParams */
  list({ hierarchyNodeId, distributor, allocatedOnly }) {
    const params = new URLSearchParams();
    if (distributor) {
      params.append('distributor', distributor);
    }
    if (allocatedOnly) {
      params.append('allocated_only', 'true');
    }
    params.append('page_size', '4');
    const url = `hierarchy-nodes/${hierarchyNodeId}/${baseUrlOld}`;
    return instance.get(url, { params });
  },
  /** @param {OrderRewardParams} queryParams */
  rewardOrder({ hierarchyNodeUserId, rewardOrder }) {
    const data = {
      hierarchy_node_user: hierarchyNodeUserId,
      items: rewardOrder.items,
    };

    if (rewardOrder.form) {
      data.form = rewardOrder.form;
    }
    if (rewardOrder.lfss_form) {
      data.lfss_form = rewardOrder.lfss_form;
    }
    if (rewardOrder.terms_accepted) {
      data.terms_accepted = rewardOrder.terms_accepted;
    }

    const url = `reward-orders/`;
    return instance.post(url, data, { params: { check: rewardOrder.check } });
  },
  /** @param {OrderRewardDetailsParams} queryParams */
  details({ uuid, include_items, include_form }) {
    const params = new URLSearchParams();
    if (include_items) params.append('include_items', include_items);
    if (include_form) params.append('include_form', include_form);

    return instance.get(`reward-orders/${uuid}`, { params });
  },
  /** @param {UpdateOrderRewardStatusParams} queryParams */
  updateStatus({ uuid, status }) {
    return instance.patch(`reward-orders/${uuid}/`, { status });
  },
};

const rewardsQueries = baseApi.injectEndpoints({
  tagTypes: [tagTypes.reward],
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<RewardListParams, typeof builder.query, 'api-query', {}>}*/
    getRewardsList: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => rewards.list(params));
      },
      providesTags: result =>
        result.results
          ? result.results.map(reward => ({
              type: tagTypes.reward,
              id: reward?._context?.allocation?.uuid,
            }))
          : [tagTypes.reward],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<{}, typeof builder.mutation, 'api-query', {}>}*/
    updateRewardAllocation: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => update(params));
      },
      invalidatesTags: (result, error, payload) => [
        { type: tagTypes.reward, id: payload.id },
      ],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<RewardAllocationUpdate, typeof builder.mutation, 'api-query', {}>}*/
    updateRewardAllocationImage: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => updateImage(params));
      },
      invalidatesTags: (result, error, payload) => [
        { type: tagTypes.reward, id: payload.id },
      ],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<RewardAllocationUpdate, typeof builder.mutation, 'api-query', {}>}*/
    deleteRewardAllocationImage: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => deleteImage(params));
      },
      invalidatesTags: (result, error, payload) => [
        { type: tagTypes.reward, id: payload.id },
      ],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<OrderRewardParams, typeof builder.mutation, 'api-query', {}>}*/
    orderReward: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => rewards.rewardOrder(params));
      },
    }),
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<OrderRewardDetailsParams, typeof builder.query, 'api-query', OrderRewardBody>}*/
    getOrderDetails: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => rewards.details(params));
      },
      providesTags: result =>
        result.results
          ? result.results.map(reward => ({
              type: tagTypes.reward,
              id: reward?.uuid,
            }))
          : [tagTypes.reward],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<UpdateOrderRewardStatusParams, typeof builder.mutation, 'api-query', {}>}*/
    updateOrderStatus: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => rewards.updateStatus(params));
      },
      invalidatesTags: [tagTypes.reward, tagTypes.points],
    }),
  }),
});

export const {
  useGetRewardsListQuery,
  useUpdateRewardAllocationMutation,
  useUpdateRewardAllocationImageMutation,
  useDeleteRewardAllocationImageMutation,
  useOrderRewardMutation,
  useGetOrderDetailsQuery,
  useLazyGetOrderDetailsQuery,
  useUpdateOrderStatusMutation,
} = rewardsQueries;

// Types
/**
 * @typedef {Object} RewardListParams
 * @property {string} [hierarchyNodeId] Id du nœud hiérarchique
 * @property {string} [distributor] Distributeur à utiliser
 * @property {boolean} [allocatedOnly] Configuration individuelle de la reward allocation
 */

/**
 * @typedef {Object} RewardOrder
 * @property {string} [form] Les données de l'utilisateur pour commander
 * @property {Array} [items] Les items à commander
 * @property {Object} [lfss_form] Données du formulaire LFSS
 * @property {boolean} [terms_accepted] Acceptation des termes
 * @property {boolean} [check] Si true retourne les erreurs s'il y en a, si false il tentera de créer la commande
 */

/**
 * @typedef {Object} OrderRewardParams
 * @property {string} [hierarchyNodeUserId] Id du hierarchy node user qui fait la commande
 * @property {RewardOrder} rewardOrder Les données de la commande
 */

/**
 * @typedef {import('@src/Spider/enums/orderStatuses').OrderStatusesType} OrderStatusesType
 * @typedef {{uuid: string, include_items?: boolean, include_forms?: boolean}} OrderRewardDetailsParams
 * @typedef {{uuid: string, status: OrderStatusesType}} UpdateOrderRewardStatusParams
 * @typedef {{uuid: string, include_items?: boolean, include_form?: boolean}} OrderRewardDetailsParams
 */

const rewards_old = {
  active(name, categoryId) {
    const hasName = name != null && name !== '';
    var url = `${baseUrlOld}active/`;
    if (hasName || categoryId) url += '?';
    if (hasName) url = `${url}name=${name}`;
    if (hasName && categoryId) url += '&';
    if (categoryId) url += `category=${categoryId}`;
    return instance.get(url);
  },
  create(reward) {
    return instance.post(baseUrlOld, reward);
  },
  exportOrders(
    categoryId,
    teamId,
    collaboratorId,
    periodId,
    validationStart,
    validationEnd,
  ) {
    var url = `${baseUrlOld}export-orders/`;
    var hasParam = false;
    if (
      categoryId ||
      teamId ||
      collaboratorId ||
      periodId ||
      validationStart ||
      validationEnd
    )
      url += '?';
    if (categoryId) {
      url += `category=${categoryId}`;
      hasParam = true;
    }
    if (teamId) {
      if (hasParam) url += '&';
      url += `team=${teamId}`;
      hasParam = true;
    }
    if (collaboratorId) {
      if (hasParam) url += '&';
      url += `collaborator=${collaboratorId}`;
      hasParam = true;
    }
    if (periodId) {
      if (hasParam) url += '&';
      url += `period=${periodId}`;
      hasParam = true;
    }
    if (validationStart) {
      if (hasParam) url += '&';
      url += `validationStart=${validationStart.toUTCJSON2()}`;
      hasParam = true;
    }
    if (validationEnd) {
      if (hasParam) url += '&';
      url += `validationEnd=${validationEnd.toUTCJSON2()}`;
      hasParam = true;
    }
    return instance.get(url);
  },
  get(id) {
    return instance.get(baseUrl(id));
  },
  replaceImage(id, rewardImage) {
    return instance.post(`${baseUrl(id)}image/`, rewardImage);
  },
  update(id, reward) {
    return instance.put(baseUrl(id), reward);
  },
  updateActivation(id, isActive) {
    return instance.patch(baseUrl(id), { isActive });
  },
  list(teamId, distributor, allocatedOnly) {
    const params = new URLSearchParams();
    if (distributor) {
      params.append('distributor', distributor);
    }
    if (allocatedOnly) {
      params.append('allocated_only', 'true');
    }
    params.append('page_size', '4');
    const url = `hierarchy-nodes/${teamId}/${baseUrlOld}`;
    return instance.get(url, { params });
  },
  rewardOrder(hierarchyNodeUserId, form, items, lfss_form) {
    const data = {
      hierarchy_node_user: hierarchyNodeUserId,
      items,
    };

    if (form) {
      data.form = form;
    }
    if (lfss_form) {
      data.lfss_form = lfss_form;
    }

    const url = `reward-orders/`;
    return instance.post(url, data);
  },
};

export default rewards_old;

/**
 * @typedef {import('@async-calls/helper/types').Address} Address
 * @typedef {import('@async-calls/types').OrderItem} OrderItem
 * @typedef {import('@async-calls/hierarchyNodeUsers').UserBody} UserBody
 */
/**
 * @typedef {{
 *   uuid: string;
 *   points: number;
 *   items: OrderItem[];
 *   number: string;
 *   status: string;
 *   monetary_value: number;
 *   creation_date: number;
 *   owner: UserBody;
 *   form?: {
 *     firstname: string;
 *     lastname: string;
 *     email: string;
 *     phone_number: string;
 *     birth_date: string;
 *     personal_address?: Address;
 *     delivery_address?: Address;
 *   };
 *   tracking_url: string | null;
 *   custom_id: string | null;
 * }} OrderRewardBody
 */
