import React from 'react';
import { Button as MuiButton, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -10,
    color: 'inherit',
  },
  childrenWrapper: {
    display: 'flex',
    visibility: props => (props.load ? 'hidden' : 'visible'),
  },
});

/**
 * @param {import("@material-ui/core").ButtonProps & {load: boolean}} props
 */
const Button = ({ load = false, disabled, children, className, ...props }) => {
  const classes = useStyles({ load });

  return (
    <div className={`${classes.wrapper} ${className}`}>
      <MuiButton
        disabled={load || disabled}
        disableRipple
        className={className}
        {...props}
      >
        <span className={classes.childrenWrapper}>{children}</span>
      </MuiButton>
      {load && (
        <CircularProgress size={16} className={classes.buttonProgress} />
      )}
    </div>
  );
};

export default Button;
