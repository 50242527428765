import React from 'react';
import { Paper, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { neutralColors } from '@src/Spider/themes';

const useStyles = makeStyles({
  dialogPaperContent: {
    borderRadius: '1rem',
    marginRight: 'auto',
    marginLeft: 'auto',
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
    padding: '1rem',
    width: '75%',
    [createTheme().breakpoints.down('md')]: {
      backgroundColor: neutralColors.neutralBg,
      padding: '0rem',
      width: '100%',
    },
  },
});

const TabbedContent = ({ tabValue, setTabValue, tabs, onValidate }) => {
  const classes = useStyles();

  const handleChange = (event, newTab) => {
    setTabValue(newTab);
  };

  return (
    <Paper className={classes.dialogPaperContent}>
      <TabContext value={tabValue}>
        <TabList
          onChange={handleChange}
          TabIndicatorProps={{ children: <div /> }}
          className={'switch'}
        >
          {tabs.map(tab => (
            <Tab
              key={tab.index}
              label={tab.label}
              value={tab.index}
              disableFocusRipple
            />
          ))}
        </TabList>
        {tabs.map(tab => (
          <TabPanel key={tab.index} value={tab.index}>
            {tab.content(onValidate)}
          </TabPanel>
        ))}
      </TabContext>
    </Paper>
  );
};

export default TabbedContent;
