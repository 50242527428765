export const ImportLogsTypes = Object.freeze({
  USERS: 'USERS',
  GOALS: 'GOALS',
  GOAL_POINTS: 'GOAL_POINTS',
  POINTS: 'POINTS',
  LEGAL_ENTITIES: 'LEGAL_ENTITIES',
  INDIVIDUALS: 'INDIVIDUALS',
  PARTICIPANTS: 'PARTICIPANTS',
});

/**
 *  @typedef { ImportLogsTypes[keyof ImportLogsTypes] } ImportLogsTypesType - Types for an import log, should use the enum in `ImportLogsTypes.jsx`
 * @exports { ImportLogsTypesType };
 */
