import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { AdministrationUrl } from '../../enums/administrationUrl';
import { TitleContainer } from '../../components/TitleContainer';
import { RegulationsTab } from '@src/Spider/scenes/Admin/components/RegulationsTab';
import { PersonalizationTab } from '@src/Spider/scenes/Admin/components/PersonalizationTab';
import { ParametersTabs } from '@src/Spider/scenes/Admin/components/ParametersTab';
import { useRouteMatch } from 'react-router';
import { MaintenanceSwitch } from '@spider:components/MaintenanceSwitch';
import { TabRoutes } from '@spider:components/TabRoutes';
import { UserRoles } from '@src/Spider/enums';

const useStyles = makeStyles(() => ({
  adminBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    paddingRight: '0.5rem',
    marginRight: '0.5rem',
  },
  adminHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
}));

const Admin = () => {
  const intl = useIntl();
  const match = useRouteMatch();
  const classes = useStyles();

  const routes = [
    {
      path: AdministrationUrl.REGULATIONS,
      component: <RegulationsTab />,
      authorizedRoles: [UserRoles.ADMINISTRATOR, UserRoles.SUPER_ADMINISTRATOR],
    },
    {
      path: AdministrationUrl.PERSONALIZATION,
      component: <PersonalizationTab />,
      authorizedRoles: [UserRoles.ADMINISTRATOR, UserRoles.SUPER_ADMINISTRATOR],
    },
    {
      path: AdministrationUrl.PARAMETERS,
      component: <ParametersTabs />,
      authorizedRoles: [UserRoles.SUPER_ADMINISTRATOR],
    },
  ];

  const tabPaths = Object.values(AdministrationUrl);

  return (
    <div className={classes.adminBody}>
      <TitleContainer>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.title',
          })}
        </Typography>
        <MaintenanceSwitch />
      </TitleContainer>
      <Box
        style={{
          backgroundColor: 'white',
          borderRadius: '1.5rem',
        }}
      >
        <TabRoutes
          basePath={match.path}
          baseUrl={match.url}
          tabPaths={tabPaths}
          tabNamespace='spider.hierarchy_node.platform_customization.adminTabLabel'
          routes={routes}
          defaultTab={AdministrationUrl.REGULATIONS}
        />
      </Box>
    </div>
  );
};

export default Admin;
