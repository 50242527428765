import React, { useState } from 'react';
import { Box, Typography, withStyles, Tooltip, Chip } from '@material-ui/core';
import { gradients, neutralColors, systemColors } from '@src/Spider';
import { Button, HierarchyNodeUserStatus } from '@src/Spider/components';
import { useIntl } from 'react-intl';
import { ChangeStatusDialog } from './ChangeStatusDialog';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { AccountStatus } from '@src/Spider/components/AccountStatus';
import { InfoCircle, Sms } from 'iconsax-react';
import { useUpdateHierarchyNodeUserStatusMutation } from '@src/data/api/endpoints/hierarchyNodeUsers';
import { toast } from 'react-toastify';
import { ImportDialog } from '@src/Spider/components/ImportDialog';

const styles = {
  root: {
    backgroundColor: neutralColors.neutralBg,
    borderRadius: '0.5rem',
    padding: '1rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  image: {
    width: '2.25rem',
    height: '2.25rem',
  },
  matricule: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  btn: {
    padding: '0.1rem 0.75rem',
    borderRadius: '0.5rem',
  },
  resendEmailBtn: {
    color: systemColors.infoRegular,
  },
  points: {
    fontSize: '2rem',
    fontWeight: 'bold',
    background: gradients.gradientLightRedRose,
    '-webkit-background-clip': 'text',
    'background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    color: 'black', // Fallback for browsers that don't support background-clip: text
  },
  sub: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
  },
  emailBox: {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.25rem',
  },
};

/**
 * @param {{user: import("@async-calls/hierarchyNodeUsers").UserBodyDetailed, className: string}} props
 */
const Component = ({ user, className, classes }) => {
  const { formatMessage } = useIntl();
  const { open, onClose, onOpen } = useDisclosure();
  const isUserActive = user?.status === 'ACTIVE';
  const userHasAccount = user.has_user_account;
  const [sendActivationEmail] = useUpdateHierarchyNodeUserStatusMutation();
  const [canSendActivationEmail, setCanSendActivationEmail] = useState(true);

  const handleSendActivationEmail = async () => {
    try {
      await sendActivationEmail({
        user_id: user?.uuid,
      }).unwrap();
      toast.success(
        formatMessage({
          id: 'spider.hierarchy_node_user_status.email.resend.success',
        }),
      );
      setCanSendActivationEmail(false);
    } catch (error) {
      console.error('Failed to update status:', error);
      toast.error(
        formatMessage({
          id: 'spider.hierarchy_node_user_status.email.resend.error',
        }),
      );
    }
  };

  return (
    <>
      <Box className={`${classes.root} ${className}`}>
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <HierarchyNodeUserStatus status={user.status} />
          <Button
            variant='outlinedPrimary'
            onClick={onOpen}
            className={classes.btn}
          >
            {formatMessage({
              id: isUserActive
                ? 'spider.common.unsubscribe'
                : 'spider.common.subscribe',
            })}
          </Button>
        </Box>
        <AccountStatus hasUserAccount={user.has_user_account} />
        {!userHasAccount ? (
          <Typography>
            <Typography component='span'>
              {formatMessage({
                id: 'spider.hierarchy_node_user_status.email.activation_problem',
              })}
            </Typography>
            <Box
              sx={{
                display: 'inline-flex',
                gap: '0.5rem',
                alignItems: 'center',
              }}
              component='span'
            >
              <Button
                variant='text'
                onClick={handleSendActivationEmail}
                disabled={!canSendActivationEmail}
                className={classes.resendEmailBtn}
              >
                {formatMessage({
                  id: 'spider.hierarchy_node_user_status.email.resend_email',
                })}
              </Button>
              <Tooltip
                title={formatMessage({
                  id: 'spider.hierarchy_node_user_status.email.tooltip',
                })}
                placement='top'
                sx={{ marginLeft: '0.3rem' }}
              >
                <InfoCircle fill={systemColors.infoRegular} />
              </Tooltip>
            </Box>
          </Typography>
        ) : (
          <Box className={classes.emailBox}>
            <Typography>
              {formatMessage({
                id: 'spider.hierarchy_node_user_status.email.linked_email',
              })}
            </Typography>
            <Chip
              className='big width-fit'
              label={user.email}
              avatar={<Sms />}
              borderColor={systemColors.infoRegular}
              color={systemColors.infoRegular}
            />
          </Box>
        )}
      </Box>
      <ChangeStatusDialog open={open} handleClose={onClose} user={user} />
    </>
  );
};

const StatusCard = withStyles(styles)(Component);

export { StatusCard };
