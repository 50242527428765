import { baseApi } from '@src/store/api-query';
import { buildEndpointUrlWithParams } from '@src/data/api/utils';
import instance from '@src/data/api/instance';

const baseUrl = 'countries/';

const countries = {
  /**
   * @param {string} search
   */
  list({ search }) {
    const params = new URLSearchParams();
    if (search) params.append('str', search);
    const url = buildEndpointUrlWithParams(baseUrl, '', params);
    return instance.get(url);
  },
};

const countryQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<TransactionParams, typeof builder.query, 'api-query', {}>}*/
    getCountriesList: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => countries.list(params));
      },
    }),
  }),
});

export const { useGetCountriesListQuery } = countryQueries;
