import React from 'react';
import { PointCommandIds } from '@src/Spider/enums';
import { CKUHelper } from '@spider:components/ConvertHelp/CKUHelp';
import { CKSHelper } from '@spider:components/ConvertHelp/CKSHelp';
import { KSHelper } from '@spider:components/ConvertHelp/KSHelp';
import { KSCHelper } from '@spider:components/ConvertHelp/KSCHelp';

export const useRewardHelper = reward => {
  if (!reward?.uuid) {
    console.warn('useRewardHelper: missing reward or reward uuid');
    return <></>;
  }

  const rewardHelper = () => {
    switch (reward.uuid) {
      case PointCommandIds.CKS:
        return CKSHelper;
      case PointCommandIds.CKU:
        return CKUHelper;
      case PointCommandIds.KS:
        return KSHelper;
      case PointCommandIds.KSC:
        return KSCHelper;
      default:
        console.warn(
          `useRewardHelper: reward type non managed: ${reward.uuid}`,
        );
        return <></>;
    }
  };

  return rewardHelper();
};
