import React, { useState } from 'react';
import { ColorButton } from '@spider:components/ColorButton';
import { ColorButtonGroup } from '@spider:components/ColorButtonGroup';
import { Button } from '@src/Spider/components';
import { makeStyles, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { TextFieldFormsy } from '@spider:components/FormsyComponents/TextFieldFormsy';
import Formsy from 'formsy-react';
import useDirtyPristine from '@src/Spider/hooks/UseDirtyPristine';
import { useAppConfiguration } from '@src/Spider/hooks/useAppConfiguration';
import { useUpdateConfigMutation } from '@async-calls/configs';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
  mainColorBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ColorPicker = () => {
  const intl = useIntl();
  const { dirtyChange, resetPristinePage } = useDirtyPristine();
  const classes = useStyles();

  const [updateConfig, updateConfigResult] = useUpdateConfigMutation();
  const { config, setConfig } = useAppConfiguration();

  const colors = [
    '#ee225cff',
    '#1e4bed',
    '#4f95e8',
    '#40a54a',
    '#edde00',
    '#fda013',
    '#fd4c00',
    '#fd1919',
    '#f987f4',
    '#763ada',
    '#1a1a1a',
  ];

  const configurationMainColor = { ...config.mainColor };

  const [mainColor, setMainColor] = useState(
    configurationMainColor.value ?? '',
  );

  const mainColorFieldChange = e => {
    mainColorChange(e.target.value);
  };

  const mainColorChange = color => {
    dirtyChange();
    setMainColor(color);
    setConfig(prev => ({
      ...prev,
      mainColor: {
        ...prev.mainColor,
        value: color,
      },
    }));
  };

  const saveAppMainColor = async () => {
    resetPristinePage();

    try {
      const updatedConfig = await updateConfig({
        id: configurationMainColor.id,
        value: mainColor,
      }).unwrap();
      setConfig(prevConfig => ({
        ...prevConfig,
        mainColor: updatedConfig,
        mainDefaultColor: updatedConfig,
      }));
      toast.success(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.mainColor.update.success',
        }),
      );
    } catch (error) {
      console.error('Failed to update config:', error);
      toast.error(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.mainColor.update.error',
        }),
      );
    }
  };

  return (
    <Formsy
      name='main-color-admin'
      onValidSubmit={saveAppMainColor}
      className={classes.mainColorBlock}
    >
      <Typography variant='h3' component='h3'>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.mainColor.title',
        })}
      </Typography>
      <Typography variant='body1'>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.mainColor.subtitle',
        })}
      </Typography>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1rem',
          marginBottom: '1rem',
          alignItems: 'end',
        }}
      >
        <TextFieldFormsy
          name='mainColor'
          value={mainColor}
          label={intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.mainColor.form.color',
          })}
          variant='outlined'
          size='small'
          style={{ width: '20rem' }}
          required
          validationErrors={{
            isDefaultRequiredValue: intl.formatMessage({
              id: 'common.form.required_error',
            }),
          }}
          onChange={mainColorFieldChange}
        />

        <ColorButton
          hexadecimalColor={mainColor}
          selected={
            mainColor &&
            mainColor.length > 0 &&
            colors.find(color => color === mainColor) === undefined
          }
        />
      </div>

      <div>
        <ColorButtonGroup
          initialColor={mainColor}
          colors={colors}
          onSelectedColorChange={mainColorChange}
        />
      </div>

      <div className={classes.actions}>
        <Button
          variant='outlinedPrimary'
          size='small'
          type='submit'
          load={updateConfigResult.isLoading}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.mainColor.save_method.label',
          })}
        </Button>
      </div>
    </Formsy>
  );
};

export { ColorPicker };
