import React, { useEffect, useState } from 'react';
import Formsy from 'formsy-react';
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  Switch,
  Typography,
} from '@material-ui/core';
import Button from '@spider:components/Button';
import useDirtyPristine from '@src/Spider/hooks/UseDirtyPristine';
import { useIntl } from 'react-intl';
import { LfssStatus } from '@src/Spider/enums/lfssStatus';
import { useParams } from 'react-router-dom';
import { Loader } from '@src/components';
import { ConfigCodes } from '@src/Spider/enums/configCodes';
import { useGetConfigsListQuery } from '@async-calls/configs';
import {
  useGetContractDetailQuery,
  usePatchContractDetailMutation,
} from '@async-calls/contractDetails';
import { toast } from 'react-toastify';
import { formatNumber } from '@src/helpers/NumberHelper';
import { LfssFormMode } from '@src/Spider/enums/lfssFormMode';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  titleGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
  },
  subtitleGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'column',
  },
  contentDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.25rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const LFSSParameters = () => {
  const intl = useIntl();
  const { contract } = useParams();
  const { dirtyChange, resetPristinePage } = useDirtyPristine();
  const classes = useStyles();

  const configs = useGetConfigsListQuery({
    hierarchyNodeId: contract,
    codes: [ConfigCodes.MINIMUM_WAGE],
  });

  const { data, isFetching } = useGetContractDetailQuery({
    hierarchyNodeId: contract,
  });

  const [patchContractDetails, mutateResult] = usePatchContractDetailMutation();

  const [lfssEnabled, setLfssEnabled] = useState(
    data ? data.lfss_form_status === LfssStatus.ACTIVE : false,
  );

  const [lfssModeEnabled, setLfssModeEnabled] = useState(
    data ? data.lfss_form_mode === LfssFormMode.COMPLETE : false,
  );

  const checkLfss = event => {
    dirtyChange();
    setLfssEnabled(event.target.checked);
  };

  const checkLfssMode = event => {
    dirtyChange();
    setLfssModeEnabled(event.target.checked);
  };

  const savePlatformContractDetails = async () => {
    resetPristinePage();
    try {
      await patchContractDetails({
        hierarchyNodeId: contract,
        contractDetail: {
          lfss_form_status: lfssEnabled
            ? LfssStatus.ACTIVE
            : LfssStatus.INACTIVE,
          lfss_form_mode: lfssEnabled
            ? lfssModeEnabled
              ? LfssFormMode.COMPLETE
              : LfssFormMode.PROGRESSIVE
            : LfssFormMode.PROGRESSIVE,
        },
      }).unwrap();
      toast.success(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.update.success',
        }),
      );
    } catch (error) {
      console.error('Failed to update status:', error);
      toast.error(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.update.error',
        }),
      );
    }
  };

  useEffect(() => {
    setLfssEnabled(data ? data.lfss_form_status === LfssStatus.ACTIVE : false);
    setLfssModeEnabled(
      data ? data.lfss_form_mode === LfssFormMode.COMPLETE : false,
    );
  }, [data]);

  return isFetching || mutateResult.isFetching ? (
    <Loader centered />
  ) : (
    <Formsy
      name={'parameter-contract-details'}
      onValidSubmit={savePlatformContractDetails}
      className={classes.root}
    >
      <div className={classes.titleGroup}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.title',
          })}
        </Typography>
      </div>

      {configs.data?.length > 0 && (
        <div className={classes.subtitleGroup}>
          <Typography style={{ fontWeight: 'bold' }}>
            {formatNumber(configs.data[0].value)}
          </Typography>
          <Typography>
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.minimum_wage',
            })}
          </Typography>
        </div>
      )}

      <div className={classes.formGroup}>
        <FormControl style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            checked={lfssEnabled}
            control={<Switch color={'primary'} />}
            labelPlacement='start'
            label={
              lfssEnabled
                ? intl.formatMessage({
                    id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_enabled_label',
                  })
                : intl.formatMessage({
                    id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_disabled_label',
                  })
            }
            style={{ margin: 0 }}
            onChange={checkLfss}
          />
        </FormControl>
        <div className={classes.contentDetails}>
          <div className={classes.contentDetailsRow}>
            <Typography style={{ fontWeight: 'bold' }}>
              {intl.formatMessage({
                id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_disabled_description_label',
              })}
            </Typography>
            <Typography>
              {intl.formatMessage({
                id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_disabled_description',
              })}
            </Typography>
          </div>
          <div>
            <Typography style={{ fontWeight: 'bold' }}>
              {intl.formatMessage({
                id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_enable_description_label',
              })}
            </Typography>
            <ul style={{ margin: '0px' }}>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_enable_description.first',
                  })}
                </Typography>
              </li>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_enable_description.second',
                  })}
                </Typography>
              </li>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_enable_description.third',
                  })}
                </Typography>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {lfssEnabled && (
        <div className={classes.formGroup}>
          <FormControl style={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              checked={lfssModeEnabled}
              control={<Switch color={'primary'} />}
              labelPlacement='start'
              label={
                lfssEnabled
                  ? intl.formatMessage({
                      id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_mode_enabled_label',
                    })
                  : intl.formatMessage({
                      id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_mode_disabled_label',
                    })
              }
              style={{ margin: 0 }}
              onChange={checkLfssMode}
            />
          </FormControl>

          <div className={classes.contentDetails}>
            <div className={classes.contentDetailsRow}>
              <Typography style={{ fontWeight: 'bold' }}>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_mode_disabled_description_label',
                })}
              </Typography>
              <Typography>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_mode_disabled_description',
                })}
              </Typography>
            </div>
            <div className={classes.contentDetailsRow}>
              <Typography style={{ fontWeight: 'bold' }}>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_mode_enable_description_label',
                })}
              </Typography>
              <Typography>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.lfss_mode_enable_description',
                })}
              </Typography>
            </div>
          </div>
        </div>
      )}

      <div className={classes.actions}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'small'}
          type={'submit'}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.save_button_label',
          })}
        </Button>
      </div>
    </Formsy>
  );
};

export { LFSSParameters };
