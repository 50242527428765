import React from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { ArrowLeft } from 'iconsax-react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '2rem',
  },
  stepper: {
    margin: 'auto',
    width: '65%',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

/**
 * @param {{
 *   steps: { label: string, icon: React.ReactNode }[],
 *   activeStep: number,
 *   previousStep: () => void,
 * }} props
 */
const ConvertPointsStepper = ({ steps, activeStep, previousStep }) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.rootTitle}>
        <Button
          variant={'contained'}
          color={'primary'}
          size={'small'}
          className={'icon light'}
          onClick={() => previousStep()}
        >
          <ArrowLeft />
        </Button>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({ id: 'spider.convertPoints.title' })}
        </Typography>
      </div>

      <Stepper
        alternativeLabel
        activeStep={activeStep}
        className={classes.stepper}
      >
        {steps.map(step => (
          <Step key={step.label}>
            <StepLabel icon={step.icon}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export { ConvertPointsStepper };
