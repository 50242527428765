import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import { neutralColors } from '@spider:src/themes';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { ReactComponent as Equals } from '@spider:src/assets/img/homeParticipantsConvert/equals.svg';
import { ReactComponent as InfoCircle } from '@spider:src/assets/img/homeParticipantsConvert/info-circle.svg';
import { withStyles } from '@mui/styles';
import { formatNumber } from '@src/helpers/NumberHelper';

const styles = {
  contentPriceConvert: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    alignItems: 'center',
    margin: 'auto',
    width: 'max-content',
  },
  contentPrice: {
    display: 'flex',
    padding: '0.5rem 1rem',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '0.5rem',
    borderRadius: '1rem',
    background: '#FAAF0C',
    [createTheme().breakpoints.down('md')]: {
      padding: '0.25rem 0.5rem',
      borderRadius: '0.5rem',
    },
  },
};

const PointPriceRatio = ({
  points = 450,
  pointsPrice = 410,
  showInfo = true,
  ...props
}) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('md'));

  return (
    <div className={props.classes.contentPriceConvert}>
      <Typography
        variant={isMobile ? 'h3' : 'h2'}
        component={isMobile ? 'h3' : 'h2'}
        style={{ fontWeight: 'bold' }}
      >
        {formatNumber(points, false)}
      </Typography>
      <Typography
        variant={isMobile ? 'h3' : 'h2'}
        component={isMobile ? 'h3' : 'h2'}
        style={{ fontWeight: 'normal' }}
      >
        {intl.formatMessage({ id: 'spider.convertPoints.home.points' })}
      </Typography>

      <Equals fill={neutralColors.neutral900} />

      <div className={props.classes.contentPrice} style={{ width: `'auto'` }}>
        <Typography
          variant={isMobile ? 'h3' : 'h2'}
          component={isMobile ? 'h3' : 'h2'}
          style={{ fontWeight: 'bold', margin: 'auto' }}
        >
          {formatNumber(pointsPrice)}
        </Typography>

        {showInfo && (
          <Tooltip
            title={intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.includeFeesInfoTooltip',
            })}
            placement='top'
            arrow
          >
            <InfoCircle fill={neutralColors.neutral900} />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(PointPriceRatio);
