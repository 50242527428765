import React, { useEffect } from 'react';
import { UpdateMobileAppModal } from '../';
import { connect } from 'react-redux';
import { useLocalStorage } from '@rehooks/local-storage';
import { UPDATE_REQUIRED } from '@src/data/local/localTypes';
import local from '@src/data/local/local';
import Intercom from '@intercom/messenger-js-sdk';
import { useConfigureDistribution } from '@src/Spider/hooks/useConfigureDistribution';

/**
 * @param {{distributorConfig: import('@src/distributors/distributorConfig').DistributorConfig, children: React.ReactNode, accountDetail: any}} props
 */
const MainEvents = ({ distributorConfig, children, accountDetail }) => {
  const { account } = accountDetail;
  const [updateRequired, setUpdateRequired] = useLocalStorage(UPDATE_REQUIRED);

  // the type for updateRequired may be string or boolean, use very defensive casting
  // eslint-disable-next-line eqeqeq
  const updateRequiredBool = String(updateRequired).toLowerCase() == 'true';

  useEffect(() => {
    // reset update required when initializing app
    setUpdateRequired('false');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useConfigureDistribution(distributorConfig);

  const environmentName = local.getClientEnvironment();

  useEffect(() => {
    if (account) {
      const intercomSettings = {
        app_id: process.env.REACT_APP_INTERCOM_ID,
        user_id: account.identifier,
        name: account.fullname,
        email: account.email,
        company: {
          id: environmentName,
          name: environmentName,
        },
        account_role: account.role.name,
      };

      Intercom(intercomSettings);
    }
  }, [account, environmentName]);

  return (
    <>
      {updateRequiredBool && <UpdateMobileAppModal />}
      {children}
    </>
  );
};

const mapStateToProps = ({ accountDetail }) => ({
  accountDetail,
});

export default connect(mapStateToProps)(MainEvents);
