import React, { useMemo } from 'react';
import { useAuth } from '@src/auth';
import { ParticipantType } from '@src/Spider/enums/participantType';
import { useDefaultRewardImage } from '@src/Spider/hooks/useDefaultRewardImage';

const RewardCardImage = ({
  reward,
  cardAlt,
  classImage,
  forceDefault = false,
}) => {
  const { hierarchyNodeUser } = useAuth();
  const defaultCard = useDefaultRewardImage(reward);
  const cardSource = useMemo(() => {
    if (forceDefault) return defaultCard;
    if (
      hierarchyNodeUser.type === ParticipantType.PHYSIC &&
      reward?._context?.allocation?.individuals_custom_image
    ) {
      return reward?._context?.allocation?.individuals_custom_image.src;
    }
    if (
      hierarchyNodeUser.type === ParticipantType.MORALE &&
      reward?._context?.allocation?.legal_entities_custom_image
    ) {
      return reward?._context?.allocation?.legal_entities_custom_image.src;
    }
    return defaultCard;
  }, [reward, hierarchyNodeUser]);
  return <img src={cardSource} alt={cardAlt} className={classImage} />;
};

export { RewardCardImage };
