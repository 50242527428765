import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid, IconButton } from '@material-ui/core';
import {
  Card,
  DefaultText,
  ErrorText,
  InfoText,
  Select,
  TextField,
  HiddenInput,
  DefaultTitle,
} from '../../../../../../../../components';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const LevelStep = ({
  initial,
  levels,
  remainingPoints,
  initialParticipantType,
}) => {
  const participantTypeIsManager = initialParticipantType === 'manager';
  const [badgeLevels, setLevels] = useState(
    participantTypeIsManager
      ? [
          {
            rank: null,
            target: 0,
            points: 0,
            level: levels[0]?.id,
            percentage: 0,
            isNew: true,
          },
        ]
      : initial || [],
  );
  const intl = useIntl();

  const addLevel = () => {

    setLevels([
      ...badgeLevels,
      {
        id: _.uniqueId('level_'),
        rank: null,
        target: null,
        points: null,
        level: null,
        percentage: 0,
        isNew: true,
      },
    ]);
  };

  const removeLevel = index => {
    setLevels(badgeLevels.filter((level, i) => i !== index));
  };

  const currentRemainingPoints =
    remainingPoints - _.sum(badgeLevels.map(level => level.points));

  return (
    <React.Fragment>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12}>
          <Card>
            <Grid container>
              <Grid item>
                <InfoText>
                  {intl.formatMessage({ id: 'badge.creation.levels_title' })}
                </InfoText>
                <DefaultText>{currentRemainingPoints} PTS</DefaultText>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DefaultTitle>
                {intl.formatMessage({
                  id: 'admin.badge_config.remaining_points_to_award',
                })}
              </DefaultTitle>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {badgeLevels.map((level, index) => {
                  const number = index + 1;
                  const disabled = level.percentage > 0;
                  const removeButtonVisibility = disabled
                    ? 'collapse'
                    : 'visible';

                  return (
                    <Grid
                      key={level.id}
                      item
                      container
                      xs={6}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <DefaultTitle>
                          {intl
                            .formatMessage({
                              id: 'admin.badge_config.step_point_label',
                            })
                            .format(number)}
                        </DefaultTitle>
                      </Grid>
                      <Grid item xs={12}>
                        <Card>
                          <Grid container spacing={2}>
                            <Grid item xs>
                              <TextField
                                type='number'
                                name={`target[${index}]`}
                                label={intl.formatMessage({
                                  id: 'admin.badge_config.goal_label',
                                })}
                                lowercase
                                initial={level.target}
                                disabled={disabled}
                                fullWidth
                                required
                                validations='isMoreThanOrEquals:0'
                                validationErrors={{
                                  isDefaultRequiredValue: intl.formatMessage({
                                    id: 'common.form.required_error',
                                  }),
                                  isMoreThanOrEquals: intl.formatMessage({
                                    id: 'common.form.is_more_than_or_equals_0_error',
                                  }),
                                }}
                              />
                            </Grid>
                            <Grid item xs>
                              <Select
                                name={`level[${index}]`}
                                label={intl.formatMessage({
                                  id: 'admin.badge_config.condition_label',
                                })}
                                options={levels}
                                optionValueName='id'
                                optionTextName='number'
                                optionTextPrefix='Lvl '
                                initial={
                                  participantTypeIsManager
                                    ? levels[0]?.id
                                    : level.level
                                }
                                disabled={
                                  !participantTypeIsManager ? disabled : true
                                }
                                fullWidth
                                required
                                validationErrors={{
                                  isDefaultRequiredValue: intl.formatMessage({
                                    id: 'common.form.required_error',
                                  }),
                                }}
                              />
                            </Grid>
                            <Grid item xs>
                              <TextField
                                type='number'
                                name={`points[${index}]`}
                                label={intl.formatMessage({
                                  id: 'admin.badge_config.points_label',
                                })}
                                initial={level.points}
                                lowercase
                                disabled={disabled}
                                fullWidth
                                required
                                onChange={value => {
                                  const parsedValue = parseInt(value) || 0;
                                  setLevels(
                                    badgeLevels.map((level, currentIndex) => {
                                      if (index === currentIndex) {
                                        return {
                                          ...level,
                                          points: parsedValue,
                                        };
                                      }
                                      return level;
                                    }),
                                  );
                                }}
                                validations='isMoreThanOrEquals:0'
                                validationErrors={{
                                  isDefaultRequiredValue: intl.formatMessage({
                                    id: 'common.form.required_error',
                                  }),
                                  isMoreThanOrEquals:
                                    'common.form.is_more_than_or_equals_0_error',
                                }}
                              />
                            </Grid>
                            {index === badgeLevels.length - 1 && (
                              <Grid
                                item
                                style={{ visibility: removeButtonVisibility }}
                              >
                                <IconButton
                                  size='small'
                                  style={{ marginTop: 16 }}
                                  onClick={() => removeLevel(index)}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  );
                })}

                <Grid item xs={6} container spacing={2}>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                    <div onClick={addLevel} style={{ cursor: 'pointer' }}>
                      <Card>
                        <DefaultText
                          style={{
                            textAlign: 'center',
                            fontSize: 18,
                            color: '#00e094',
                          }}
                          lowercase
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ color: '#00e094', textAlign: 'center' }}
                          />
                        </DefaultText>
                      </Card>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ErrorText lowercase>
            <HiddenInput
              name='maxPoints'
              validations='isMoreThanOrEquals:0'
              validationErrors={{
                isMoreThanOrEquals: intl.formatMessage({
                  id: 'admin.badge_config.points_condition',
                }),
              }}
              value={currentRemainingPoints}
            />
          </ErrorText>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LevelStep;
