import { Button, Paper, Typography, Box } from '@material-ui/core';
import { TitleContainer } from '@src/Spider/components/TitleContainer';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import cardKadeosUniversel from '../../assets/img/home/universel-kadeos.png';
import cardKadeos from '../../assets/img/home/card-kadeos.png';
import cardShopKadeos from '../../assets/img/home/shop-kadeos.png';
import cardChequesKadeos from '../../assets/img/home/chequ-kadeos.png';
import { neutralColors } from '@spider:src/themes';
import { useGetContactsQuery } from '@src/data/api/endpoints/contacts';

const styles = {
  pointsBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingRight: '0.5rem',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1.5rem 1rem',
    borderRadius: '2rem',
  },

  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'space-between',
  },
  card: {
    flex: '1 1 calc(50% - 1rem)',
    minWidth: '250px',
    minHeight: '104px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '1rem',
    backgroundColor: neutralColors.neutralColdWhite,
    padding: '1rem',
    boxShadow: 'none',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  image: {
    width: '4.5rem',
    height: 'auto',
    borderRadius: '0.5rem',
    objectFit: 'contain',
  },
  textContent: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardTitle: {
    fontWeight: 700,
    marginBottom: '0.5rem',
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  linkGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
  },
  link: {
    display: 'inline-block',
    backgroundColor: neutralColors.neutralWhite,
    color: neutralColors.neutral900,
    borderRadius: '0.5rem',
    padding: '4px 8px',
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  loading: {
    width: '100%',
    minWidth: '10rem',
    height: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
  },
};

const Help = ({ classes }) => {
  const intl = useIntl();
  const { contract } = useParams();

  const { data: contacts, isFetching: contactsFetching } = useGetContactsQuery({
    hierarchyNodeId: contract,
  });
  const contractEmail = contacts ? contacts[0]?.email : null;

  const staticCards = [
    {
      name: intl.formatMessage({
        id: 'spider.help.cards_content.universal_card_title',
      }),
      image: cardKadeosUniversel,
      links: [
        {
          title: intl.formatMessage({
            id: 'spider.help.cards_content.universal_card_link1',
          }),
          url: 'https://www.edenred.fr/faq/utilisateur/kadeos/universel',
        },
        {
          title: intl.formatMessage({
            id: 'spider.help.cards_content.universal_card_link2',
          }),
          url: 'https://click.edenred.fr/documents_espace_client/Kadeos/Carte/Renouvellement_Universel.pdf',
        },
        {
          title: intl.formatMessage({
            id: 'spider.help.cards_content.universal_card_link3',
          }),
          url: 'https://click.edenred.fr/documents_espace_client/Kadeos/Carte/CU_Universel.pdf',
        },
      ],
    },
    {
      name: intl.formatMessage({
        id: 'spider.help.cards_content.card_kadeos_title',
      }),
      image: cardKadeos,
      links: [
        {
          title: intl.formatMessage({
            id: 'spider.help.cards_content.card_kadeos_link1',
          }),
          url: 'https://www.edenred.fr/faq/utilisateur/kadeos/zenith',
        },

        {
          title: intl.formatMessage({
            id: 'spider.help.cards_content.card_kadeos_link2',
          }),
          url: 'https://click.edenred.fr/documents_espace_client/Kadeos/Carte/CU_Zenith_Culture.pdf',
        },

        {
          title: intl.formatMessage({
            id: 'spider.help.cards_content.card_kadeos_link3',
          }),
          url: 'https://www.edenred.fr/qrcode/localiser-kadeos-zenith',
        },
      ],
    },
    {
      name: intl.formatMessage({
        id: 'spider.help.cards_content.card_kadeos_connect_title',
      }),
      image: cardShopKadeos,
      links: [
        {
          title: intl.formatMessage({
            id: 'spider.help.cards_content.card_kadeos_connect_link1',
          }),
          url: 'https://www.edenred.fr/faq/utilisateur/kadeos/connect',
        },

        {
          title: intl.formatMessage({
            id: 'spider.help.cards_content.card_kadeos_connect_link2',
          }),
          url: 'https://click.edenred.fr/documents_espace_client/Kadeos/Demat/CU_Connect.pdf',
        },
      ],
    },
    {
      name: intl.formatMessage({
        id: 'spider.help.cards_content.card_kadeos_check_title',
      }),
      image: cardChequesKadeos,
      links: [
        {
          title: intl.formatMessage({
            id: 'spider.help.cards_content.card_kadeos_check_link1',
          }),
          url: 'https://www.edenred.fr/faq/utilisateur/kadeos/infini',
        },

        {
          title: intl.formatMessage({
            id: 'spider.help.cards_content.card_kadeos_check_link2',
          }),
          url: 'https://click.edenred.fr/documents_espace_client/Kadeos/Cheque/CU.pdf',
        },

        {
          title: intl.formatMessage({
            id: 'spider.help.cards_content.card_kadeos_check_link3',
          }),
          url: 'https://www.edenred.fr/qrcode/localiser-kadeos-infini',
        },
      ],
    },
  ];

  return (
    <div className={classes.pointsBody}>
      <TitleContainer>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({ id: 'spider.help.title' })}
        </Typography>
      </TitleContainer>
      <Paper elevation={0} style={{ borderRadius: '1.5rem' }}>
        <div className={classes.paper}>
          <TitleContainer>
            <Typography variant={'h3'} component={'h3'}>
              {intl.formatMessage({ id: 'spider.help.sub_title' })}
            </Typography>
          </TitleContainer>

          <div className={classes.cardContainer}>
            {staticCards?.map((card, index) => (
              <div key={index} className={classes.card}>
                <div className={classes.cardContent}>
                  <img
                    src={card.image}
                    alt={card.name}
                    className={classes.image}
                  />
                  <div className={classes.textContent}>
                    <Typography className={classes.cardTitle}>
                      {card.name}
                    </Typography>
                    <div className={classes.linkGroup}>
                      {card.links.map((link, i) => (
                        <a
                          key={i}
                          href={link.url}
                          target='_blank'
                          rel='noopener noreferrer'
                          className={classes.link}
                        >
                          {link.title}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.buttonContainer}>
            <Button
              color={'primary'}
              variant={'contained'}
              size={'large'}
              href={`mailto:${contractEmail}?subject=Need Help&body=Please describe your issue...`}
            >
              {' '}
              {intl.formatMessage({
                id: 'spider.help.contact_help_center',
              })}
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(Help);
