import React, { useMemo } from 'react';
import Formsy from 'formsy-react';
import { neutralColors, systemColors } from '@spider:src/themes';
import { makeStyles, Typography } from '@material-ui/core';
import { ReactComponent as PointUser } from '@spider:src/assets/img/points-user.svg';
import { useIntl } from 'react-intl';
import { TextFieldFormsy } from '@spider:components/FormsyComponents/TextFieldFormsy';
import { Button } from '@spider:components/index';
import { useAuth } from '@src/auth';
import { ParticipantType } from '@spider:src/enums/participantType';
import { Building, ProfileCircle } from 'iconsax-react';
import { PointCommandIds } from '@spider:src/enums';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { useFormHelper } from '@src/Spider/hooks/useFormHelper';
import { FormAddressInformation } from '@src/Spider/scenes/ConvertPoints/components/components/InformationComponents/FormAddressInformation';
import { useBaseFormsyValidation } from '@src/Spider/hooks/useBaseFormsyValidation';
import { useFormDataHelper } from '@src/Spider/hooks/useFormDataHelper';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  formFields: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  block: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    width: '100%',
  },
  userInformation: {
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.5rem',
    alignSelf: 'stretch',
    borderRadius: '0.5rem',
    background: neutralColors.neutralColdWhite,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

/**
 * @param {{
 *   nextStep: ({ ignoreProps: string[] }) => void,
 *   previousStep: () => void,
 *   stepKeys: string[]
 *   nextStepLoading: boolean
 * }} props
 */
const InformationStep = ({
  nextStep,
  nextStepLoading,
  previousStep,
  stepKeys,
}) => {
  const { hierarchyNodeUser } = useAuth();
  const intl = useIntl();
  const convertPointsContext = useConvertPoints();
  const classes = useStyles();

  const { baseFormsyValidationErrors, baseFormsyValidations } =
    useBaseFormsyValidation();

  const phoneMaxLength = 17,
    maxDate = new Date();

  const defaultFormData = useMemo(() => {
    const form = {};
    if (convertPointsContext.reward?.uuid === PointCommandIds.CKU) {
      form.phone_number = '';
      form.birth_date = '';
    }

    if (convertPointsContext.reward?.uuid !== PointCommandIds.CKU) {
      form.phone_number = '';
    }

    if (
      convertPointsContext.reward?.uuid === PointCommandIds.CKU &&
      hierarchyNodeUser.type === ParticipantType.MORALE
    ) {
      form.personal_address = {
        line1: '',
        line2: '',
        postal_code: '',
        city: '',
      };
    }

    if (
      convertPointsContext.reward?.uuid !== PointCommandIds.KSC &&
      hierarchyNodeUser.type === ParticipantType.PHYSIC
    ) {
      form.delivery_address = {
        line1: '',
        line2: '',
        postal_code: '',
        city: '',
      };
    }

    return form;
  }, [
    convertPointsContext.reward,
    hierarchyNodeUser.cku,
    hierarchyNodeUser.type,
  ]);

  const { formData, handleFormChange } = useFormDataHelper(
    convertPointsContext.informationStepData,
    defaultFormData,
  );

  const {
    formRef,
    isSubmitButtonDisabled,
    onInvalid,
    onValid,
    onSubmit,
    formChange,
  } = useFormHelper({
    onSubmitCallback: () => nextStep({ ignoreProps: stepKeys }),
    onInvalidCallback: () =>
      convertPointsContext.removeInformationStepData(true),
    onValidCallback: formData => {
      convertPointsContext.setInformationStepData({
        value: formData,
        setInSessionStorage: true,
      });
    },
    onFormChange: convertPointsContext.setErrorOnFormChange,
    errorHook: useConvertPoints,
  });

  return (
    <Formsy
      ref={formRef}
      name={'information-form'}
      className={classes.root}
      onValidSubmit={onSubmit}
      onValid={() => onValid(formData)}
      onInvalid={() => onInvalid(formData)}
      onChange={event => formChange(event, defaultFormData, stepKeys)}
    >
      <div className={classes.rootTitle}>
        <PointUser
          fill={neutralColors.neutral900}
          style={{ width: '1.5rem', height: '1.5rem' }}
        />
        <Typography variant={'h3'} component={'h3'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.informationStep.title',
          })}
        </Typography>
      </div>
      <Typography variant={'body1'} style={{ fontStyle: 'italic' }}>
        {intl.formatMessage({
          id: 'spider.convertPoints.stepper.informationStep.subtitle',
        })}
      </Typography>
      <div className={classes.userInformation}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
            alignItems: 'center',
          }}
        >
          {hierarchyNodeUser.type === ParticipantType.PHYSIC ? (
            <ProfileCircle color={systemColors.errorRegular} />
          ) : (
            <Building color={systemColors.errorRegular} />
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
              {`${hierarchyNodeUser.firstname} ${hierarchyNodeUser.lastname}`}
            </Typography>
            <Typography variant={'body2'}>
              {`${hierarchyNodeUser.email} - ${hierarchyNodeUser.first_identifier}`}
            </Typography>
          </div>
        </div>
        <Typography variant={'body2'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.informationStep.mailInformation',
          })}
        </Typography>
      </div>
      <Typography variant={'h4'} component={'h3'}>
        {intl.formatMessage({
          id: 'spider.convertPoints.stepper.informationStep.personalInformation.title',
        })}
      </Typography>
      <div className={classes.formFields}>
        {formData.birth_date !== undefined && (
          <TextFieldFormsy
            name={'birth_date'}
            value={formData.birth_date}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.informationStep.personalInformation.dateOfBirthField',
            })}
            variant={'outlined'}
            type='date'
            required
            InputLabelProps={{
              shrink: true,
            }}
            validations={{
              ...baseFormsyValidations({
                customErrorMessage:
                  convertPointsContext.error?.form?.birth_date,
              }),
              isDateLessThan: maxDate,
            }}
            validationErrors={{
              ...baseFormsyValidationErrors({
                isRequired: true,
                customErrorMessage:
                  convertPointsContext.error?.form?.birth_date,
              }),
              isDateLessThan: intl
                .formatMessage({
                  id: 'common.form.is_date_less_than',
                })
                .format(
                  maxDate.toLocaleString('fr-FR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }),
                ),
            }}
            onChange={handleFormChange}
          />
        )}
        <TextFieldFormsy
          name={'phone_number'}
          value={formData.phone_number}
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.informationStep.personalInformation.phoneField',
          })}
          variant={'outlined'}
          required
          validations={{
            ...baseFormsyValidations({
              maxLength: phoneMaxLength,
              customErrorMessage:
                convertPointsContext.error?.form?.phone_number,
            }),
            isPhoneNumber: formData.phone_number,
          }}
          validationErrors={{
            ...baseFormsyValidationErrors({
              isRequired: true,
              maxLength: phoneMaxLength,
              customErrorMessage:
                convertPointsContext.error?.form?.phone_number,
            }),
            isPhoneNumber: intl.formatMessage({
              id: 'common.form.is_phone_number',
            }),
          }}
          onChange={handleFormChange}
        />
      </div>
      {convertPointsContext.reward?.uuid !== PointCommandIds.KSC && (
        <FormAddressInformation
          convertPointsContext={convertPointsContext}
          formData={formData}
          classes={classes}
          handleFormChange={handleFormChange}
        />
      )}
      <div className={classes.actions}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'large'}
          onClick={previousStep}
          load={nextStepLoading}
        >
          {intl.formatMessage({ id: 'spider.common.back' })}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'large'}
          type={'submit'}
          disabled={isSubmitButtonDisabled}
          load={nextStepLoading}
        >
          {intl.formatMessage({ id: 'spider.common.submit' })}
        </Button>
      </div>
    </Formsy>
  );
};

export { InformationStep };
