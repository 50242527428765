import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import neutralColors from '@src/Spider/themes/colors/neutralColors';
import { useAppConfiguration } from '@src/Spider/hooks/useAppConfiguration';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5rem 0rem',
    gap: '0.5rem',
    alignItems: 'flex-start',
  },
  iconCircle: {
    display: 'flex',
    padding: '4px',
    borderRadius: '100%',
    backgroundColor: ({ mainColor }) => mainColor,
  },
});

/**
 * @param {{
 *   icon: React.ReactNode,
 *   message: string,
 * }} props
 */
const IconDetails = ({ Icon, message }) => {
  const { config } = useAppConfiguration();

  const classes = useStyles({
    mainColor: config.mainColor?.value,
  });
  return (
    <div className={classes.root}>
      <div className={classes.iconCircle}>
        <Icon
          size={16}
          fill={neutralColors.neutralWhite}
          color={neutralColors.neutralWhite}
        />
      </div>
      <Typography style={{ textAlign: 'left' }}>{message}</Typography>
    </div>
  );
};

export { IconDetails };
