import React from 'react';
import { UserRoles } from '@spider:src/enums';
import { AdminOrders } from './components/AdminOrders';
import { UserOrders } from './components/UserOrders';
import { AuthorizedRolesWrapper } from '@spider:src/components/AuthorizedRolesWrapper';

const Orders = () => {
  return (
    <>
      <AuthorizedRolesWrapper
        roles={[UserRoles.ADMINISTRATOR, UserRoles.SUPER_ADMINISTRATOR]}
      >
        <AdminOrders />
      </AuthorizedRolesWrapper>
      <AuthorizedRolesWrapper
        roles={[UserRoles.COLLABORATOR, UserRoles.MANAGER]}
      >
        <UserOrders />
      </AuthorizedRolesWrapper>
    </>
  );
};

export default Orders;
