import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createTheme,
  makeStyles,
} from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import Pagination from '@material-ui/lab/Pagination';
import { RowsPerPageSelector } from './RowsPerPageSelector';
import { systemColors } from '@spider:src/themes';
import { useIntl } from 'react-intl';
import { Loader } from '@src/components';

const useStyles = makeStyles({
  stickyHeader: {
    position: 'sticky',
    right: 0,
    zIndex: 3,
    [createTheme().breakpoints.down('md')]: {
      boxShadow: '0px 8px 14px 3px #0A1F5E1A',
    },
  },
  stickyColumn: {
    position: 'sticky',
    right: 0,
    background: 'white',
    zIndex: 3,
    [createTheme().breakpoints.down('md')]: {
      boxShadow: '0px 8px 14px 3px #0A1F5E1A',
    },
  },
  infos: {
    display: 'flex',
    justifyContent: 'space-between',
    [createTheme().breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '0.5rem',
    },
  },
  errorBox: {
    backgroundColor: systemColors.errorBg,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 0px',
    borderRadius: '0.5rem',
  },
  errorText: {
    color: systemColors.errorRegular,
  },
});

/**
 * @typedef {Object} CommonListProps
 * @property {Array<{field: string, label: string}>} columns - Column definitions
 * @property {Array} data - Data to display
 * @property {number} count - count of results
 * @property {React.ComponentType<{item: any, classes: any}>} RowComponent - Component to render each row
 * @property {boolean} isFetching - Loading state
 * @property {boolean} isLoading - Initial Loading state
 * @property {Object} error - Error object
 * @property {Object} pagination - Pagination object
 * @property {Function} handleChangePage - Page change handler
 * @property {Function} handleChangeRowsPerPage - Rows per page change handler
 * @property {number} paginationCount - Total number of pages
 * @property {boolean} hasAction - Specify if there is an action in the list
 * @property {React.ReactNode} [emptyState] - Component to show when no data
 */

/**
 * @param {CommonListProps} props
 */
const DataList = ({
  count,
  columns,
  data,
  RowComponent,
  isFetching,
  isLoading,
  error,
  pagination,
  handleChangePage,
  handleChangeRowsPerPage,
  paginationCount,
  emptyState,
  hasAction = false,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const showEmptyState = !isLoading && data?.length === 0 && emptyState;
  return (
    <Box display='flex' flexDirection='column'>
      <Box className={classes.infos}>
        <Box textAlign='end'>
          {error && !isFetching && (
            <Typography style={{ color: systemColors.errorRegular }}>
              {error?.message}
            </Typography>
          )}
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Box>
          {data && !isLoading && (
            <Typography>
              {intl.formatMessage(
                { id: 'spider.common.tableResults' },
                { count },
              )}
            </Typography>
          )}
          {isFetching && !isLoading && (
            <CircularProgress color='primary' size='1.2rem' />
          )}
        </Box>
        <RowsPerPageSelector
          rowsPerPage={pagination.rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
      <TableContainer component={Paper} style={{ maxWidth: '100%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.field}
                  style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}
                >
                  {column.label}
                </TableCell>
              ))}
              {hasAction && <TableCell className={classes.stickyHeader} />}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Loader centered />
                </TableCell>
              </TableRow>
            )}
            {data?.length > 0 &&
              !isLoading &&
              data.map(item => (
                <RowComponent key={item.uuid} item={item} classes={classes} />
              ))}
            {error && !isLoading && (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  <Box className={classes.errorBox}>
                    <Typography className={classes.errorText}>
                      {error?.data.detail}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {showEmptyState && (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>{emptyState}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={paginationCount}
        onChange={handleChangePage}
        variant='outlined'
      />
    </Box>
  );
};

export { DataList };
