import React from 'react';
import {
  createTheme,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { gradients, neutralColors } from '@src/Spider';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { useIntl } from 'react-intl';
import { formatNumber } from '@src/helpers/NumberHelper';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'center',
  },
  priceConverted: {
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '0.25rem',
    margin: 'auto',
    background: gradients.gradientLightRedRoseBackground,
  },
  price: {
    fontSize: '3.5rem',
    fontWeight: 'bold',
    color: neutralColors.neutralWhite,
  },
}));

const PointsSummary = () => {
  const convertPointsContext = useConvertPoints();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('md'));
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{ paddingTop: isMobile ? '0rem' : '1.5rem' }}
    >
      {convertPointsContext.pointStepData && (
        <Typography
          variant={isMobile ? 'h4' : 'h2'}
          component={isMobile ? 'h4' : 'h2'}
          style={{ fontWeight: 'normal', textAlign: 'center' }}
        >
          {intl
            .formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.points.convertInfo',
            })
            .format(
                formatNumber(parseInt(
                    convertPointsContext.pointStepData.points,
                ), false)
              ,
            )}
        </Typography>
      )}

      <div className={classes.priceConverted}>
        <Typography variant={'h1'} component={'h1'} className={classes.price}>
          {intl
            .formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.price',
            })
            .format(
                formatNumber(convertPointsContext.simulation?.order_suggested?.monetary_value ??
                    0)
              ,
            )}
        </Typography>
      </div>

      {convertPointsContext.reward && (
        <Typography
          variant={isMobile ? 'h4' : 'h2'}
          component={isMobile ? 'h4' : 'h2'}
          style={{ fontWeight: 'normal', textAlign: 'center' }}
        >
          {intl
            .formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.inConvert',
            })
            .format(convertPointsContext.reward?.name)}
        </Typography>
      )}
    </div>
  );
};

export { PointsSummary };
