import React from 'react';
import { useClearCache } from 'react-clear-cache';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';

import { AppBar, Footer } from './components';
import { cssBaselineStyles } from './styles';
import { ErrorFallback } from '@spider:src/scenes';
import { useTheme } from '@src/distributors';
import { gradients } from '@src/Spider';

const GuestLayout = ({ component: Component }) => {
  const { emptyCacheStorage } = useClearCache();
  const history = useHistory();

  const theme = useTheme(cssBaselineStyles, {
    mainColor: { value: gradients.gradientLeftToRight100 },
    mainDefaultColor: { value: gradients.gradientLeftToRight100 },
  });

  const handleError = (error, info) => {
    console.error('Error caught by ErrorBoundary: ', error, info);
  };

  const handleReset = async () => {
    await emptyCacheStorage();
    history.push('/');
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <AppBar />

        <div className='ft-wrapper'>
          <main>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={handleReset}
              onError={handleError}
            >
              <Component />
            </ErrorBoundary>
          </main>

          <Footer oneTrustKey={process.env.REACT_APP_ONE_TRUST_KEY} />
        </div>
      </ThemeProvider>
    </>
  );
};

export default GuestLayout;
