import React, { useState, useEffect } from 'react';
import { CardMedia, Grid, Card } from '@material-ui/core';
import {
  DefaultTitle,
  FileInput,
  HiddenInput,
} from '../../../../../../components';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as configListActions from '../../../../../../services/Configs/ConfigList/actions';
import * as configListUpdateActions from '../../../../../../services/Configs/ConfigListUpdate/actions';
import _ from 'lodash';
import { useGetHierarchyNodeQuery } from '@src/data/api/endpoints/teamGroups';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useUpdateHierarchyNodeSystemImageMutation } from '@src/data/api/endpoints/systemImages';
import { useIntl } from 'react-intl';

const styles = {
  logo: {
    height: 100,
    width: 200,
    backgroundSize: 'contain',
  },
};

const LogoSettings = ({ classes }) => {
  const [logo, setLogo] = useState('/assets/img/system/logo.png');
  const intl = useIntl();

  const [updateSystemImage] = useUpdateHierarchyNodeSystemImageMutation();

  const { hierarchyNodeUser } = useSelector(
    state => state.activeHierarchyNodeUser,
  );

  const { data: nodeData } = useGetHierarchyNodeQuery({
    hierarchyNodeId: hierarchyNodeUser?._context.contract.code ?? null,
  });

  useEffect(() => {
    if (nodeData?._context?.logo?.src) {
      setLogo(nodeData._context.logo.src);
    }
  }, [nodeData]);

  const handleLogoUpdate = async file => {
    if (!file) return;

    const hierarchyNodeId = hierarchyNodeUser?._context?.contract?.code;

    const formData = new FormData();
    formData.append('src', file);
    formData.append('code', 'LOGO');
    await updateSystemImage({
      hierarchyNodeId,
      fileFormData: formData,
    }).unwrap();
    toast.success(
      intl.formatMessage({ id: 'admin.logo_and_colors.success_message_logo' }),
    );
  };

  return (
    <>
      <DefaultTitle isContrast>Logo</DefaultTitle>
      <Card>
        <Grid container spacing={2} style={{ margin: 20 }}>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item>
              <CardMedia image={logo} className={classes.logo} />
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <Grid item>
              <FileInput
                name='logo'
                accept='image/*'
                onChange={handleLogoUpdate}
              />
            </Grid>
          </Grid>
        </Grid>
        <HiddenInput name='uuid' value={_.get(logo, 'uuid')} />
      </Card>
    </>
  );
};

const mapStateToProps = ({ configList, configListUpdate }) => ({
  configList,
  configListUpdate,
});

const mapDispatchToProps = dispatch => ({
  configListActions: bindActionCreators(configListActions, dispatch),
  configListUpdateActions: bindActionCreators(
    configListUpdateActions,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(LogoSettings));
