import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@material-ui/core/styles';
import { RewardCardImage } from '@spider:components/RewardCardImage';
import { PointCommandIds } from '@src/Spider/enums';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '16.25rem',
    padding: '2rem 1rem',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '1rem',
    background: '#FFFAEE',
    position: 'relative',
    overflow: 'clip',
  },
  sphere: {},
  card: {
    display: 'flex',
    margin: 'auto',
  },
  cardImage: {
    height: '149px',
    zIndex: 2,
    [createTheme().breakpoints.down('sm')]: {
      height: '99px',
    },
  },
});

const createSphereStyle = position =>
  makeStyles({
    sphere: {
      width: '27.25rem',
      height: '26.0625rem',
      position: 'absolute',
      borderRadius: '27.25rem',
      opacity: 0.3,
      filter: 'blur(100px)',
      background: 'linear-gradient(135deg, #FAAF0C 0%, #FFDC60 100%)',
      ...position,
    },
  });
const useCksStyle = createSphereStyle({
  right: '-10.25rem',
  top: '-7.3125rem',
});
const useKsStyle = createSphereStyle({
  right: '-3.4375rem',
  bottom: '-13rem',
});
const useCkuStyle = createSphereStyle({
  left: '-8rem',
  top: '-7.3125rem',
});

const DecorationCard = ({ title = '', reward }) => {
  const isMobile = useMediaQuery(createTheme().breakpoints.down('md'));
  const classes = useStyles();
  const cksClasses = useCksStyle();
  const ckuClasses = useCkuStyle();
  const ksClasses = useKsStyle();

  const hardClasses = () => {
    switch (reward.uuid) {
      case PointCommandIds.CKS:
        return cksClasses;
      case PointCommandIds.CKU:
        return ckuClasses;
      case PointCommandIds.KS:
        return ksClasses;
      default:
        return classes;
    }
  };

  return (
    <div className={classes.root}>
      <Typography
        variant={isMobile ? 'h3' : 'h2'}
        component={isMobile ? 'h3' : 'h2'}
        style={{ zIndex: 4 }}
      >
        {title}
      </Typography>

      <div style={{ zIndex: 1 }} className={hardClasses().sphere} />

      <div className={classes.card}>
        <RewardCardImage reward={reward} classImage={classes.cardImage} />
      </div>
    </div>
  );
};

export { DecorationCard };
