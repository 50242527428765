import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Card, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ContractCard } from './components';
import { HierarchyNodeTypes, UserRoles } from '@spider:src/enums';
import { HierarchyNodeStatuses } from '@src/Spider/enums/hierarchyNodeStatuses';
import { useGetHierarchyNodeListQuery } from '@async-calls/hierarchyNodes';

const useStyles = makeStyles(theme => ({
  root: {
    gap: '24px',
    padding: '32px',
    maxWidth: '992px',
    minHeight: '100%',
    width: '100%',
  },
}));

const ContractSelection = () => {
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyles();

  const useGetHierarchyNodeListResult = useGetHierarchyNodeListQuery({
    type: HierarchyNodeTypes.CONTRACT,
  });

  const hasNodes = useGetHierarchyNodeListResult?.data?.results?.length > 0;

  const goToContract = useCallback(
    contract => {
      history.push(`/nodes/${contract.code}/home`);
    },
    [history],
  );

  const NodeList = () => {
    return (
      <Grid container spacing={3}>
        {useGetHierarchyNodeListResult?.data?.results.map(contract => {
          const isInMaintenance =
            contract.status === HierarchyNodeStatuses.MAINTENANCE;
          const maintenanceAccess =
            contract._context.highest_hierarchy_node_user.role ===
            UserRoles.SUPER_ADMINISTRATOR;
          return (
            <Grid
              key={contract.code}
              item
              xs={12}
              sm={6}
              md={4}
              onClick={
                isInMaintenance && !maintenanceAccess
                  ? undefined
                  : () => goToContract(contract)
              }
            >
              <ContractCard
                isInMaintenance={isInMaintenance}
                maintenanceAccess={maintenanceAccess}
                cover={
                  contract._context && contract._context.banner
                    ? contract._context.banner.src
                    : null
                }
                customer={contract._context.parent.name}
                logo={
                  contract._context && contract._context.logo
                    ? contract._context.logo.src
                    : null
                }
                mainColor={
                  contract._context && contract._context.custom_color
                    ? contract._context.custom_color.value
                    : null
                }
                name={contract.name}
                uuid={contract.uuid}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  useEffect(() => {
    if (
      hasNodes &&
      useGetHierarchyNodeListResult?.data?.results?.length === 1
    ) {
      goToContract(useGetHierarchyNodeListResult?.data?.results[0]);
    }
  }, [useGetHierarchyNodeListResult, goToContract, hasNodes]);

  return (
    <>
      <Card className={`${classes.root} no-elevation`}>
        <Typography variant={'h1'} component={'h1'}>
          {intl.formatMessage({ id: 'spider.org.contract_selection.title' })}
        </Typography>
        {useGetHierarchyNodeListResult.isFetching && <CircularProgress />}
        {hasNodes && <NodeList />}
      </Card>
    </>
  );
};

export default ContractSelection;
