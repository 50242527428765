import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EditableImage } from './EditableImage';
import { useIntl } from 'react-intl';
import { makeStyles, Typography } from '@material-ui/core';
import UseHelperLimitedLength from '@src/Spider/hooks/UseHelperLimitedLength';
import { TextFieldFormsy } from '@spider:components/FormsyComponents/TextFieldFormsy';
import Formsy from 'formsy-react';
import Button from '@spider:components/Button';
import useDirtyPristine from '@src/Spider/hooks/UseDirtyPristine';
import { neutralColors } from '@src/Spider/themes';
import { SystemFileCodes } from '@src/Spider/enums';
import {
  useGetHierarchyNodeQuery,
  usePatchHierarchyNodeMutation,
} from '@async-calls/teamGroups';
import { Loader } from '@src/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetHierarchyNodeSystemImagesListQuery } from '@async-calls/systemImages';
import { SystemFileStatuses } from '@src/Spider/enums/systemFileStatuses';
import { useAppConfiguration } from '@src/Spider/hooks/useAppConfiguration';

const useStyles = makeStyles(theme => ({
  personalizedBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  editableBlock: {
    display: 'flex',
    flexDirection: 'column',
    border: '#D4DCE6FF solid 2px',
    borderRadius: '1rem',
    overflow: 'clip',
  },
  editableFields: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '1rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  banner: {
    minHeight: '10rem',
    height: '10rem',
  },
  logoSide: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flex: 12,
    },
  },
  logo: {
    margin: 'auto',
    width: '178px',
    height: '172px',
    borderRadius: '12px',
    borderColor: neutralColors.neutral450,
    border: '2px solid',
    overflow: 'clip',
  },
  textEdit: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    flex: 11,
    [theme.breakpoints.down('md')]: {
      flex: 12,
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const PersonalisationPanel = () => {
  const intl = useIntl();
  const { dirtyChange, resetPristinePage } = useDirtyPristine();
  const { contract } = useParams();
  const classes = useStyles();
  const { config } = useAppConfiguration();

  const useHelperLimitedLength = UseHelperLimitedLength();
  const maxTitleLength = 110;
  const maxDescriptionLength = 350;
  const CODE_LOGO = SystemFileCodes.LOGO;
  const CODE_BANNER = SystemFileCodes.BANNER;

  const hierarchyNodeResult = useGetHierarchyNodeQuery({
    hierarchyNodeId: contract,
  });

  const systemFilesResult = useGetHierarchyNodeSystemImagesListQuery({
    hierarchyNodeId: contract,
    codes: [CODE_LOGO, CODE_BANNER],
    statuses: [SystemFileStatuses.ACTIVE, SystemFileStatuses.INACTIVE],
    pagination: { page: 1, rowsPerPage: 2 },
  });

  const [patchHierarchyNode, { isLoading }] = usePatchHierarchyNodeMutation();

  const [titleValue, setTitleValue] = useState(
    hierarchyNodeResult?.data?.custom_title ?? '',
  );
  const [descriptionValue, setDescriptionValue] = useState(
    hierarchyNodeResult?.data?.custom_text ?? '',
  );

  const titleFieldChange = e => {
    dirtyChange();
    setTitleValue(e.target.value);
  };

  const descriptionFieldChange = e => {
    dirtyChange();
    setDescriptionValue(e.target.value);
  };

  const saveAppPersonalization = async () => {
    resetPristinePage();

    try {
      await patchHierarchyNode({
        hierarchyNode: {
          baseCode: contract,
          custom_title: titleValue,
          custom_text: descriptionValue,
        },
      }).unwrap();
      toast.success(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.personalization.update.success',
        }),
      );
    } catch (error) {
      console.error('Failed to contract detail:', error);
      toast.error(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.personalization.update.error',
        }),
      );
    }
  };

  useEffect(() => {
    setTitleValue(hierarchyNodeResult?.data?.custom_title ?? '');
    setDescriptionValue(hierarchyNodeResult?.data?.custom_text ?? '');
  }, [hierarchyNodeResult]);

  return systemFilesResult.isFetching || hierarchyNodeResult.isFetching ? (
    <Loader centered />
  ) : (
    <Formsy
      name={'personalization-panel-admin'}
      onValidSubmit={saveAppPersonalization}
      className={classes.personalizedBlock}
    >
      <Typography variant={'h3'} component={'h3'}>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.personalization.title',
        })}
      </Typography>

      <Typography variant={'body1'}>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.personalization.subtitle',
        })}
      </Typography>

      <div className={classes.editableBlock}>
        <div className={classes.banner}>
          <EditableImage
            imageData={systemFilesResult.data?.results?.find(
              file => file.code === CODE_BANNER,
            )}
            code={CODE_BANNER}
            title={intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.personalization.rightDialogUploadTitleBanner',
            })}
            defaultColor={config.mainColor.value}
            altImage={intl.formatMessage({ id: 'spider.imageAlt.banner' })}
            imageHeight={''}
            imageWidth={'100%'}
          />
        </div>
        <div className={classes.editableFields}>
          <div className={classes.logoSide}>
            <div className={classes.logo}>
              <EditableImage
                imageData={systemFilesResult.data?.results?.find(
                  file => file.code === CODE_LOGO,
                )}
                code={CODE_LOGO}
                title={intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.personalization.rightDialogUploadTitleLogo',
                })}
                altImage={intl.formatMessage({ id: 'spider.imageAlt.logo' })}
                imageWidth={'100%'}
                imageHeight={'100%'}
              />
            </div>
          </div>
          <div className={classes.textEdit}>
            <TextFieldFormsy
              name={'title'}
              value={titleValue}
              label={intl.formatMessage({
                id: 'spider.hierarchy_node.platform_customization.personalization.form.title',
              })}
              variant={'outlined'}
              helperText={useHelperLimitedLength.getHelper(
                titleValue,
                maxTitleLength,
              )}
              required
              validations={{
                maxLength: maxTitleLength,
              }}
              validationErrors={{
                isDefaultRequiredValue: intl.formatMessage({
                  id: 'common.form.required_error',
                }),
                maxLength: intl
                  .formatMessage({
                    id: 'common.form.max_length_custom_error',
                  })
                  .format(maxTitleLength),
              }}
              onChange={titleFieldChange}
            />
            <TextFieldFormsy
              name={'description'}
              value={descriptionValue}
              label={intl.formatMessage({
                id: 'spider.hierarchy_node.platform_customization.personalization.form.description',
              })}
              variant={'outlined'}
              helperText={useHelperLimitedLength.getHelper(
                descriptionValue,
                maxDescriptionLength,
              )}
              multiline
              minRows={8}
              required
              validations={{
                maxLength: maxDescriptionLength,
              }}
              validationErrors={{
                isDefaultRequiredValue: intl.formatMessage({
                  id: 'common.form.required_error',
                }),
                maxLength: intl
                  .formatMessage({
                    id: 'common.form.max_length_custom_error',
                  })
                  .format(maxDescriptionLength),
              }}
              onChange={descriptionFieldChange}
            />
          </div>
        </div>
      </div>

      <div className={classes.actions}>
        <Button
          variant={'outlinedPrimary'}
          size={'small'}
          type='submit'
          load={isLoading}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.personalization.save_method.label',
          })}
        </Button>
      </div>
    </Formsy>
  );
};

export { PersonalisationPanel };
