import React from 'react';
import { Route } from 'react-router-dom';

import { AuthProvider, MaintenanceProvider } from '@src/auth';
import { UserLayout } from './index';
import { GuestLayout } from '../GuestLayout';
import useAuthorizedUser from '../../../hooks/UseAuthorizedUser';
import { AppConfigurationProvider } from '@src/Spider/hooks/useAppConfiguration';

const UserRoutes = ({
  component: Component,
  useGuestLayout = false,
  hierarchyNodeUserRequired = true,
  authorizedRoles = [],
  ...rest
}) => {
  useAuthorizedUser(authorizedRoles);
  const Layout = useGuestLayout ? GuestLayout : UserLayout;
  return (
    <Route
      {...rest}
      render={props => (
        <AuthProvider hierarchyNodeUserRequired={hierarchyNodeUserRequired}>
          <AppConfigurationProvider>
            <MaintenanceProvider>
              <Layout
                component={Component}
                authorizedRoles={authorizedRoles}
                {...props}
              />
            </MaintenanceProvider>
          </AppConfigurationProvider>
        </AuthProvider>
      )}
    />
  );
};

export default UserRoutes;
