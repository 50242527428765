import { PointCommandIds } from '@src/Spider/enums';
import { useIntl } from 'react-intl';

export const useRewardHelperTitle = reward => {
  const intl = useIntl();
  const rewardHelperTitle = () => {
    switch (reward.uuid) {
      case PointCommandIds.CKS:
        return intl.formatMessage({
          id: 'spider.convertPoints.home.cks.title',
        });
      case PointCommandIds.CKU:
        return intl.formatMessage({
          id: 'spider.convertPoints.home.cku.title',
        });
      case PointCommandIds.KS:
        return intl.formatMessage({
          id: 'spider.convertPoints.home.ks.title',
        });
      case PointCommandIds.KSC:
        return intl.formatMessage({
          id: 'spider.convertPoints.home.ksc.title',
        });
      default:
        return '';
    }
  };

  return rewardHelperTitle();
};
