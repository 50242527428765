import instance from '../instance';
import PaginationHelper from './helper/paginationHelper';
import { baseApi } from '@src/store/api-query';
import { tagTypes } from '@src/store/api-tags';

const baseUrl = 'system-files/';

/**
 * @typedef {Object} Pagination
 * @property {number} [page] La page a récupérer
 * @property {number} [rowsPerPage] Le nombre d'objet par page
 */

/**
 * @typedef {Object} HierarchyNodeImageListParams
 * @property {string} [hierarchyNodeId] Id du nœud hiérarchique
 * @property {Array} [codes] Les images utilisant ces codes seront retournées cumulé
 * @property {Array} [statuses] Les images utilisant ces status seront retournées
 * @property {Pagination} [pagination] Objet de pagination
 */

/**
 * @typedef {Object} UpdateHierarchyNodeImageParams
 * @property {string} [hierarchyNodeId] Id du nœud hiérarchique
 * @property {string} [code] Le code du fichier à associé au fichier
 * @property {Object} [fileFormData] Form data du fichier à uploader
 */

/**
 * @typedef {Object} DeleteHierarchyNodeImageParams
 * @property {string} [fileId] Id du fichier à supprimer
 */

const systemImages = {
  /** @param {HierarchyNodeImageListParams} queryParams */
  hierarchyNodeList({
    hierarchyNodeId,
    codes = [],
    statuses = [],
    pagination,
  }) {
    const params = new URLSearchParams();
    if (codes && codes.length > 0) {
      codes.forEach(code => {
        params.append('codes', code);
      });
    }
    if (statuses && statuses.length > 0) {
      statuses.forEach(status => {
        params.append('status', status);
      });
    }

    return instance.get(`hierarchy-nodes/${hierarchyNodeId}/${baseUrl}`, {
      params: PaginationHelper(pagination, params),
    });
  },
  /** @param {UpdateHierarchyNodeImageParams} queryParams */
  hierarchyNodeUpdate({ hierarchyNodeId, code, fileFormData }) {
    const url = `hierarchy-nodes/${hierarchyNodeId}/${baseUrl}`;

    return instance.post(url, fileFormData, {
      params: {
        code,
      },
    });
  },
  /** @param {DeleteHierarchyNodeImageParams} queryParams */
  delete({ fileId }) {
    const url = `${baseUrl}${fileId}`;
    return instance.delete(url);
  },
};

const systemImagesQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<HierarchyNodeImageListParams, typeof builder.query, 'api-query', {}>}*/
    getHierarchyNodeSystemImagesList: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => systemImages.hierarchyNodeList(params));
      },
      providesTags: result => [
        tagTypes.systemFile,
        ...(result.results
          ? result.results.map(item => ({
              type: tagTypes.systemFile,
              id: item?.uuid,
            }))
          : []),
      ],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<UpdateHierarchyNodeImageParams, typeof builder.mutation, 'api-query', {}>}*/
    updateHierarchyNodeSystemImage: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => systemImages.hierarchyNodeUpdate(params));
      },
      invalidatesTags: [
        tagTypes.systemFile,
        tagTypes.hierarchyNodeUser,
        tagTypes.hierarchyNode,
      ],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<DeleteHierarchyNodeImageParams, typeof builder.mutation, 'api-query', {}>}*/
    deleteSystemImage: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => systemImages.delete(params));
      },
      invalidatesTags: (result, error, payload) => [
        { type: tagTypes.systemFile, id: payload.id },
        tagTypes.hierarchyNodeUser,
        tagTypes.hierarchyNode,
      ],
    }),
  }),
});

export const {
  useLazyGetHierarchyNodeSystemImagesListQuery,
  useGetHierarchyNodeSystemImagesListQuery,
  useUpdateHierarchyNodeSystemImageMutation,
  useDeleteSystemImageMutation,
} = systemImagesQueries;
