import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import useFileDownload from '@src/Spider/hooks/UseFileDownload';

const useSystemFile = ({
  fixedCacheKey,
  onFileUpdated = () => {},
  onFileDeleted = () => {},
  onFileDownload = () => {},
  deleteSystemFileMutation = () => [props => {}, {}],
  updateSystemFileMutation = () => [props => {}, {}],
  downloadSystemFileQuery = () => [props => {}, {}],
}) => {
  const intl = useIntl();

  const [deleteSystemFileMutate] = deleteSystemFileMutation();
  const [updateSystemFileMutate] = updateSystemFileMutation();
  const [downloadSystemFile] = downloadSystemFileQuery();

  const { downloadBlob } = useFileDownload();

  const deleteSystemFile = async (fileId, additionalProps) => {
    if (!fileId) {
      toast.error(
        intl.formatMessage({
          id: 'spider.systemFile.delete.invalidData',
        }),
      );
      return;
    }
    try {
      await deleteSystemFileMutate({
        fixedCacheKey,
        fileId,
        ...additionalProps,
      }).unwrap();
      toast.success(
        intl.formatMessage({
          id: 'spider.systemFile.delete.success',
        }),
      );
      onFileDeleted();
    } catch (e) {
      toast.error(
        intl.formatMessage({
          id: 'spider.systemFile.delete.error',
        }),
      );
      console.error('File deletion error:', e);
    }
  };

  /**
   * Gère la mise à jour d'un fichier unique dans le système
   * @param {File} fileFormData - Le fichier à télécharger
   * @param {Object} additionalProps - Propriétés supplémentaires pour la mise à jour
   */
  const updateSystemFile = async (fileFormData, additionalProps) => {
    try {
      await updateSystemFileMutate({
        fixedCacheKey,
        fileFormData,
        ...additionalProps,
      }).unwrap();
      onFileUpdated(additionalProps);
      toast.success(
        intl.formatMessage({
          id: 'spider.systemFile.update.success',
        }),
      );
    } catch (e) {
      toast.error(
        intl.formatMessage({
          id: 'spider.systemFile.update.error',
        }),
      );
    }
  };

  const downloadFile = async (fileId, additionalProps) => {
    try {
      const result = await downloadSystemFile({
        fixedCacheKey,
        fileId,
        ...additionalProps,
      }).unwrap();
      if (
        !result.headers ||
        !result.headers['content-disposition'] ||
        !result.headers['content-type']
      ) {
        toast.error(
          intl.formatMessage({
            id: 'spider.systemFile.download.error',
          }),
        );
        console.error(
          'Headers manquants ou `content-disposition`/`content-type` non présents',
        );
        return;
      }
      const contentDisposition = result.headers['content-disposition'];
      const contentType = result.headers['content-type'];
      const filenameMatch = contentDisposition.match(/filename="(.+?)"/);
      if (!filenameMatch || !filenameMatch[1]) {
        toast.error(
          intl.formatMessage({
            id: 'spider.systemFile.download.error',
          }),
        );
        console.error(
          "Nom de fichier introuvable dans l'en-tête `content-disposition`",
        );
        return;
      }
      const filename = filenameMatch[1];
      const file = new Blob([result.data], {
        type: contentType,
      });
      downloadBlob(file, filename);
      onFileDownload();
      toast.success(
        intl.formatMessage({
          id: 'spider.systemFile.download.success',
        }),
      );
    } catch (e) {
      toast.error(
        intl.formatMessage({
          id: 'spider.systemFile.download.error',
        }),
      );
    }
  };

  return { deleteSystemFile, updateSystemFile, downloadFile };
};

export default useSystemFile;
