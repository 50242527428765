import { useEffect, useState } from 'react';
import { SystemFileCodes, UserRoles } from '../enums';
import { SystemFileStatuses } from '../enums/systemFileStatuses';
import { useAuth } from '@src/auth';
import { useGetHierarchyNodeSystemImagesListQuery } from '@async-calls/systemImages';
import { useParams } from 'react-router-dom';

const UseApprovalHelper = systemFileCode => {
  const { hierarchyNodeUser } = useAuth();
  const { contract } = useParams();
  const [documentToApprove, setDocumentToApprove] = useState(null);
  const [showMainContent, setShowMainContent] = useState(false);
  const [approveDocumentDialog, setApproveDocumentDialog] = useState(false);

  const systemFilesResult = useGetHierarchyNodeSystemImagesListQuery({
    hierarchyNodeId: contract,
    codes: [SystemFileCodes.REGLEMENT],
    statuses: [SystemFileStatuses.ACTIVE],
  });

  useEffect(() => {
    if (
      ![UserRoles.COLLABORATOR, UserRoles.MANAGER].includes(
        hierarchyNodeUser.role,
      )
    ) {
      setShowMainContent(true);
      return false;
    }
    if (
      systemFilesResult?.isFetching ||
      systemFilesResult?.data?.results.length === 0
    ) {
      setShowMainContent(true);
      return false;
    }
    const documentToApprove = systemFilesResult.data.results.find(
      document => document.code === systemFileCode,
    );
    if (!documentToApprove) {
      return false;
    }

    if (!hierarchyNodeUser.approval) {
      setShowMainContent(false);
      setDocumentToApprove(documentToApprove);
      setApproveDocumentDialog(true);
    } else {
      setShowMainContent(true);
    }
  }, [hierarchyNodeUser, systemFileCode, systemFilesResult]);

  const closeDocumentApproval = () => {
    setApproveDocumentDialog(false);
    setDocumentToApprove(null);
    setShowMainContent(true);
  };

  return {
    closeDocumentApproval,
    documentToApprove,
    showMainContent,
    approveDocumentDialog,
  };
};

export default UseApprovalHelper;
