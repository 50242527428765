import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Building, ProfileCircle } from 'iconsax-react';
import { systemColors } from '@src/Spider/themes';
import {
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import useDirtyPristine from '@src/Spider/hooks/UseDirtyPristine';
import Formsy from 'formsy-react';
import Button from '@spider:components/Button';
import { createTheme } from '@material-ui/core/styles';
import { RewardAllocationConfigurationImageCodes } from '@src/Spider/enums/rewardAllocationConfigurationImageCodes';
import { ConfigurationField } from '../ConfigurationField';
import { Loader } from '@src/components';
import { useUpdateRewardAllocationMutation } from '@async-calls/rewards';
import { ConfigurationImage } from 'src/Spider/scenes/Admin/components/ParametersTab/components/ProductsParameters/components/Reward/components/ConfigurationImage';
import { ParticipantType } from '@src/Spider/enums/participantType';
import { toast } from 'react-toastify';
import { RewardAllocationConfigurationType } from '@src/Spider/enums/rewardAllocationConfigurationType';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { ActionModal } from '@spider:components/ActionModal';
import { useFormHelper } from '@src/Spider/hooks/useFormHelper';
import { useDecimalHelper } from '@src/Spider/hooks/useDecimalHelper';

const styles = {
  configurationLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  configurationTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  configurationTitleLabel: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  configurationContentInputs: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(4, 1fr)',
    [createTheme().breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [createTheme().breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  configurationContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  configurationContentLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '0rem 2.5rem !important',
    gap: '1rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogConfirmationActions: {
    flexDirection: 'row !important',
    justifyContent: 'center !important',
    '&.MuiDialogActions-root > button': {
      margin: '0 !important',
    },
    gap: '0.5rem',
    [createTheme().breakpoints.down('md')]: {
      flexDirection: 'column !important',
      justifyContent: 'center !important',
      '&.MuiDialogActions-root > button': {
        margin: 'initial',
      },
    },
  },
};

const RewardAllocationsConfiguration = ({
  rewardLabel,
  rewardAllocation,
  configurations,
  type = ParticipantType.MORALE,
  ...props
}) => {
  const intl = useIntl();

  const [updateRewardAllocation, updateRewardAllocationResult] =
    useUpdateRewardAllocationMutation();

  const activeConfigurations =
    (type === ParticipantType.MORALE
      ? rewardAllocation?.legal_entities_config
      : rewardAllocation?.individuals_config) ?? [];

  const { open, onClose, onOpen } = useDisclosure();
  const [enableConfiguration, setEnableConfiguration] = useState(
    activeConfigurations.length > 0,
  );
  const [configurationValues, setConfigurationValues] =
    useState(activeConfigurations);

  const { convertDecimalToText, convertTextToDecimal } = useDecimalHelper();

  const configurationsToObject = () => {
    const originalConfiguration = {};

    if (activeConfigurations) {
      activeConfigurations.forEach(configuration => {
        const requiredConfig = configurations.find(
          requiredConfiguration =>
            requiredConfiguration.code === configuration.code,
        );
        originalConfiguration[configuration.code?.toLowerCase()] =
          requiredConfig.type === RewardAllocationConfigurationType.DECIMAL
            ? convertDecimalToText(configuration.value)
            : configuration.value;
      });
    }

    return { ...originalConfiguration };
  };

  const mappedConfig = configuration => {
    const requiredConfig = configurations.find(
      requiredConfiguration =>
        requiredConfiguration.code === configuration.code,
    );
    if (!requiredConfig) return configuration;
    else {
      return {
        ...configuration,
        value:
          requiredConfig.type === RewardAllocationConfigurationType.DECIMAL
            ? convertTextToDecimal(configuration.value)
            : configuration.value,
      };
    }
  };

  const { dirtyChange, resetPristinePage } = useDirtyPristine();

  const onConfigurationChange = configuration => {
    dirtyChange();
    const configurationValuesChanged = [...configurationValues];
    const configurationIndex = configurationValues.findIndex(
      configurationValue => configurationValue.code === configuration.code,
    );
    if (configurationIndex !== -1) {
      configurationValuesChanged.splice(configurationIndex, 1, configuration);
    } else {
      configurationValuesChanged.push(configuration);
    }
    setConfigurationValues(configurationValuesChanged);
  };

  const checkConfiguration = async event => {
    dirtyChange();
    setEnableConfiguration(event.target.checked);
    const config =
      type === ParticipantType.MORALE
        ? rewardAllocation.legal_entities_config
        : rewardAllocation.individuals_config;
    if (!event.target.checked && !!config) {
      onOpen();
    } else {
      setConfigurationValues([]);
    }
  };

  const onConfirmationClose = () => {
    onClose();
    setEnableConfiguration(true);
  };

  const disableRewardAllocation = async () => {
    onClose();
    const rewardAllocationDto = {};
    if (type === ParticipantType.MORALE) {
      rewardAllocationDto.legal_entities_config = null;
    } else {
      rewardAllocationDto.individuals_config = null;
    }

    setEnableConfiguration(true);
    await saveRewardAllocation({
      fixedCacheKey: `unique${rewardAllocation.uuid}`,
      id: rewardAllocation.uuid,
      rewardAllocation: rewardAllocationDto,
    });
  };

  const saveConfiguration = async () => {
    const rewardAllocationDto = {};
    const configs = enableConfiguration
      ? [...configurationValues.map(mappedConfig)]
      : null;

    if (type === ParticipantType.MORALE) {
      rewardAllocationDto.legal_entities_config = configs;
    } else {
      rewardAllocationDto.individuals_config = configs;
    }

    await saveRewardAllocation({
      fixedCacheKey: `unique${rewardAllocation.uuid}`,
      id: rewardAllocation.uuid,
      rewardAllocation: rewardAllocationDto,
    });
  };

  const saveRewardAllocation = async updateDto => {
    try {
      await updateRewardAllocation(updateDto).unwrap();
      toast.success(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.rewards.update.success',
        }),
      );
    } catch (e) {
      toast.error(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.rewards.update.error',
        }),
      );
    }
  };

  const {
    formRef,
    isSubmitButtonDisabled,
    onInvalid,
    onValid,
    onSubmit,
    formChange,
  } = useFormHelper({
    isDirtySubmit: true,
    onSubmitCallback: () => saveConfiguration(),
  });

  return updateRewardAllocationResult.isFetching ? (
    <Loader centered />
  ) : (
    <Formsy
      name='parameter-contract-details'
      ref={formRef}
      onValidSubmit={onSubmit}
      onValid={() => onValid(configurationValues)}
      onInvalid={onInvalid}
      onChange={event => formChange(event, configurationsToObject(), [])}
      className={props.classes.configurationLayout}
    >
      <div className={props.classes.configurationContentLayout}>
        <div className={props.classes.configurationTitle}>
          <div className={props.classes.configurationTitleLabel}>
            {type === ParticipantType.MORALE ? (
              <Building color={systemColors.errorRegular} />
            ) : (
              <ProfileCircle color={systemColors.errorRegular} />
            )}

            <Typography variant={'subtitle1'} style={{ fontWeight: 'bold' }}>
              {type === ParticipantType.MORALE
                ? intl.formatMessage({
                    id: 'spider.hierarchy_node.platform_customization.parameters.rewards.moralePerson',
                  })
                : intl.formatMessage({
                    id: 'spider.hierarchy_node.platform_customization.parameters.rewards.physicPerson',
                  })}
            </Typography>
          </div>

          <FormControl style={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              checked={enableConfiguration}
              control={<Switch color={'primary'} />}
              labelPlacement='start'
              label={
                enableConfiguration
                  ? intl.formatMessage({
                      id: 'spider.hierarchy_node.platform_customization.parameters.rewards.enabledPerson',
                    })
                  : intl.formatMessage({
                      id: 'spider.hierarchy_node.platform_customization.parameters.rewards.disabledPerson',
                    })
              }
              style={{ margin: 0 }}
              onChange={checkConfiguration}
            />
          </FormControl>
        </div>

        {enableConfiguration && (
          <div className={props.classes.configurationContent}>
            <div className={props.classes.configurationContentInputs}>
              {configurations?.map((configuration, index) => (
                <ConfigurationField
                  key={index}
                  configuration={configuration}
                  configurationDefaultValue={
                    configurationValues.find(
                      configurationValue =>
                        configurationValue.code === configuration.code,
                    )?.value ?? ''
                  }
                  onConfigurationChange={e => onConfigurationChange(e)}
                />
              ))}
            </div>

            <ConfigurationImage
              rewardAllocationId={rewardAllocation.uuid}
              rewardLabel={rewardLabel}
              code={
                type === ParticipantType.MORALE
                  ? RewardAllocationConfigurationImageCodes.LEGAL_ENTITIES_REWARD_ALLOCATION_IMAGE
                  : RewardAllocationConfigurationImageCodes.INDIVIDUALS_REWARD_ALLOCATION_IMAGE
              }
              rewardAllocationImage={
                type === ParticipantType.MORALE
                  ? rewardAllocation.legal_entities_custom_image
                  : rewardAllocation.individuals_custom_image
              }
            />
          </div>
        )}
      </div>

      {enableConfiguration && (
        <div className={props.classes.actions}>
          <Button
            color='primary'
            variant='outlined'
            size='small'
            type='submit'
            disabled={isSubmitButtonDisabled}
          >
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.parameters.rewards.saveButtonLabel',
            })}
          </Button>
        </div>
      )}

      <ActionModal
        open={open}
        handleClose={onConfirmationClose}
        title='spider.hierarchy_node.platform_customization.parameters.rewards.disableRewardConfirmation.title'
        description='spider.hierarchy_node.platform_customization.parameters.rewards.disableRewardConfirmation.message'
        confirmButtonText='spider.hierarchy_node.platform_customization.parameters.rewards.disableRewardConfirmation.actions.yes'
        cancelButtonText='spider.hierarchy_node.platform_customization.parameters.rewards.disableRewardConfirmation.actions.no'
        onConfirm={disableRewardAllocation}
        isLoading={updateRewardAllocationResult.isFetching}
      />
    </Formsy>
  );
};

export default withStyles(styles)(RewardAllocationsConfiguration);
