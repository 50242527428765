export class SessionStorageManager {
  static setItem(key, value) {
    try {
      const item = {
        value,
      };

      sessionStorage.setItem(key, JSON.stringify(item));
    } catch (error) {
      console.error('Error setting session storage item:', error);
    }
  }

  static getItem(key) {
    try {
      const itemStr = sessionStorage.getItem(key);
      if (!itemStr) return null;

      const item = JSON.parse(itemStr);

      return item.value;
    } catch (error) {
      console.error('Error getting session storage item:', error);
      return null;
    }
  }

  static removeItem(key) {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing session storage item:', error);
    }
  }

  static clear() {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.error('Error clearing session storage:', error);
    }
  }

  static hasItem(key) {
    return this.getItem(key) !== null;
  }
}
