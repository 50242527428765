import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import Sagas from '../services/Sagas';
import local from '../data/local/local';
import { baseApi } from './api-query';

const sagaMiddleware = createSagaMiddleware();

/**
 * @param {import('@src/distributors/distributorConfig').DistributorConfig} distributorConfig
 */
export const configureAppStore = distributorConfig => {
  const currentVersion = '0.9.3';
  const lastVersion = local.getVersion();

  if (
    !distributorConfig.persistMiddleware ||
    !distributorConfig.loadPersistedState ||
    !distributorConfig.clearPersistedState
  ) {
    throw new Error(
      'distributorConfig must implement persistMiddleware, loadPersistedState and clearPersistedState',
    );
  }

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: distributorConfig.loadPersistedState(),
    middleware: getDefaultMiddleware => [
      sagaMiddleware,
      ...getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
      distributorConfig.persistMiddleware,
      baseApi.middleware,
    ],
    devTools: process.env.NODE_ENV !== 'production',
  });

  if (currentVersion !== lastVersion) {
    distributorConfig.clearPersistedState();
    local.setVersion(currentVersion);
  }

  sagaMiddleware.run(Sagas);

  return store;
};
