import React, { useMemo } from 'react';
import { InformationContentBlock } from '@src/Spider/scenes/ConvertPoints/components/components/SummaryComponents/InformationContentBlock';
import { useIntl } from 'react-intl';
import { InformationDetail } from '@src/Spider/scenes/ConvertPoints/components/components/SummaryComponents/InformationDetail';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { useGetDepartmentsListQuery } from '@async-calls/departments';
import { useGetCountriesListQuery } from '@async-calls/countries';

const DeclarationSummary = () => {
  const intl = useIntl();
  const convertPointsContext = useConvertPoints();

  const departmentsListResult = useGetDepartmentsListQuery({});

  const countriesListResult = useGetCountriesListQuery({});

  const findByUuid = (list, uuid) => {
    if (!uuid || !list || list?.length === 0) return null;
    return list.find(item => item.uuid === uuid);
  };

  const birth_department = useMemo(
    () =>
      findByUuid(
        departmentsListResult?.data,
        convertPointsContext.declarationStepData?.personal_infos
          ?.birth_department,
      ),
    [departmentsListResult, convertPointsContext],
  );

  return (
    <>
      <InformationContentBlock
        title={intl.formatMessage({
          id: 'spider.convertPoints.stepper.summaryStep.declaration.identification.title',
        })}
        first={true}
        showDivider={
          convertPointsContext.declarationStepData?.personal_address ||
          convertPointsContext.declarationStepData?.employer_address ||
          convertPointsContext.declarationStepData?.personal_infos
        }
      >
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.identification.last_name',
          })}
          isError={Boolean(convertPointsContext.error?.lfss_form?.last_name)}
          value={convertPointsContext.declarationStepData?.last_name}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.identification.first_name',
          })}
          isError={Boolean(convertPointsContext.error?.lfss_form?.first_name)}
          value={convertPointsContext.declarationStepData?.first_name}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.identification.birth_name',
          })}
          isError={Boolean(convertPointsContext.error?.lfss_form?.birth_name)}
          value={convertPointsContext.declarationStepData?.birth_name}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.identification.email',
          })}
          isError={Boolean(convertPointsContext.error?.lfss_form?.email)}
          value={convertPointsContext.declarationStepData?.email}
        />
      </InformationContentBlock>
      {convertPointsContext.declarationStepData?.personal_address && (
        <InformationContentBlock
          title={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_address.title',
          })}
          showDivider={
            convertPointsContext.declarationStepData?.employer_address ||
            convertPointsContext.declarationStepData?.personal_infos
          }
        >
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_address.line1',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.personal_address?.line1,
            )}
            value={
              convertPointsContext.declarationStepData?.personal_address?.line1
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_address.line2',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.personal_address?.line2,
            )}
            value={
              convertPointsContext.declarationStepData?.personal_address?.line2
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_address.postal_code',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.personal_address
                ?.postal_code,
            )}
            value={
              convertPointsContext.declarationStepData?.personal_address
                ?.postal_code
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_address.city',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.personal_address?.city,
            )}
            value={
              convertPointsContext.declarationStepData?.personal_address?.city
            }
          />
        </InformationContentBlock>
      )}
      {convertPointsContext.declarationStepData?.employer_address && (
        <InformationContentBlock
          title={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.employer_address.title',
          })}
          showDivider={convertPointsContext.declarationStepData?.personal_infos}
        >
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.employer_address.company_name',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.employer_address
                ?.company_name,
            )}
            value={
              convertPointsContext.declarationStepData.employer_address
                ?.company_name
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.employer_address.line1',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.employer_address?.line1,
            )}
            value={
              convertPointsContext.declarationStepData.employer_address?.line1
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.employer_address.line2',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.employer_address?.line2,
            )}
            value={
              convertPointsContext.declarationStepData.employer_address?.line2
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.employer_address.postal_code',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.employer_address
                ?.postal_code,
            )}
            value={
              convertPointsContext.declarationStepData.employer_address
                ?.postal_code
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.employer_address.city',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.employer_address?.city,
            )}
            value={
              convertPointsContext.declarationStepData?.employer_address?.city
            }
          />
        </InformationContentBlock>
      )}
      {convertPointsContext.declarationStepData?.personal_infos && (
        <InformationContentBlock
          title={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.title',
          })}
        >
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.birth_date',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.personal_infos?.birth_date,
            )}
            value={
              convertPointsContext.declarationStepData?.personal_infos
                ?.birth_date
            }
          />
          <InformationDetail
            loading={departmentsListResult.isFetching}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.birth_department',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.personal_infos
                ?.birth_department,
            )}
            value={
              birth_department
                ? `${birth_department?.number} - ${birth_department?.name}`
                : ''
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.birth_city',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.personal_infos?.birth_city,
            )}
            value={
              convertPointsContext.declarationStepData?.personal_infos
                ?.birth_city
            }
          />
          <InformationDetail
            loading={countriesListResult.isFetching}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.birth_country',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.personal_infos
                ?.birth_country,
            )}
            value={
              findByUuid(
                countriesListResult?.data,
                convertPointsContext.declarationStepData?.personal_infos
                  ?.birth_country,
              )?.name
            }
          />
          <InformationDetail
            loading={countriesListResult.isFetching}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.nationality_country',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.personal_infos
                ?.nationality_country,
            )}
            value={
              findByUuid(
                countriesListResult?.data,
                convertPointsContext.declarationStepData?.personal_infos
                  ?.nationality_country,
              )?.name
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.social_security_number',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.personal_infos
                ?.social_security_number,
            )}
            value={
              convertPointsContext.declarationStepData?.personal_infos
                ?.social_security_number
            }
          />
        </InformationContentBlock>
      )}
    </>
  );
};

export { DeclarationSummary };
