import React, { useEffect, useMemo, useState } from 'react';
import {
  PointCommandIds,
  RewardAllocationConfigurationCodes,
} from '@src/Spider/enums';
import { useAuth } from '@src/auth';
import { useRewardConfigAccess } from '@src/Spider/hooks/useRewardConfigAccess';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { isArray } from 'lodash';

export const useConvertPointsHelper = () => {
  const { hierarchyNodeUser } = useAuth();
  const convertPointsContext = useConvertPoints();

  const { isRewardAllocationExist, getConfigByCode, getConfigByCallback } =
    useRewardConfigAccess({
      reward: convertPointsContext.reward,
    });

  const [minimumPoints, setMinimumPoints] = useState(null);
  const [maximumPoints, setMaximumPoints] = useState(
    convertPointsContext.simulation?.max_points_usable
      ? convertPointsContext.simulation?.max_points_usable
      : hierarchyNodeUser.point_balance
        ? hierarchyNodeUser.point_balance.available
        : 0,
  );
  const [currentBalanceTooLow, setCurrentBalanceTooLow] = React.useState(false);

  const isMultipleCard = useMemo(() => {
    return (
      isArray(convertPointsContext.simulation?.order_suggested?.items) &&
      convertPointsContext.simulation?.order_suggested?.items?.length > 1
    );
  }, [convertPointsContext.simulation]);

  const maximumPerCard = useMemo(() => {
    const configuration = getConfigByCode(
      RewardAllocationConfigurationCodes.MAXIMUM_AMOUNT,
    );
    return configuration ? configuration.value : null;
  }, [convertPointsContext.reward]);

  const calculateMinimumPoints = () => {
    let minimumPointsChanged = 0;
    const ckuCallback = config =>
      convertPointsContext.reward?.uuid === PointCommandIds.CKU &&
      !hierarchyNodeUser.cku
        ? config.code ===
          RewardAllocationConfigurationCodes.MINIMUM_CREATION_AMOUNT
        : config.code ===
          RewardAllocationConfigurationCodes.MINIMUM_LOADING_AMOUNT;

    if (getConfigByCallback(ckuCallback, true)) {
      minimumPointsChanged += getConfigByCallback(ckuCallback).value;
    }

    if (
      getConfigByCode(RewardAllocationConfigurationCodes.MINIMUM_AMOUNT, true)
    ) {
      minimumPointsChanged += getConfigByCode(
        RewardAllocationConfigurationCodes.MINIMUM_AMOUNT,
      ).value;
    }
    setMinimumPoints(minimumPointsChanged);
  };

  useEffect(() => {
    if (!isRewardAllocationExist || !convertPointsContext.simulation) return;
    calculateMinimumPoints();
    setMaximumPoints(convertPointsContext.simulation.max_points_usable);
  }, [convertPointsContext.reward, hierarchyNodeUser, convertPointsContext]);

  useEffect(() => {
    if (!isRewardAllocationExist) return;

    if (
      !hierarchyNodeUser.point_balance ||
      hierarchyNodeUser.point_balance.available < minimumPoints
    ) {
      setCurrentBalanceTooLow(true);
      return;
    }

    setCurrentBalanceTooLow(false);
  }, [hierarchyNodeUser, minimumPoints]);

  return {
    minimumPoints,
    maximumPoints,
    isMultipleCard,
    maximumPerCard,
    currentBalanceTooLow,
    isRewardAllocationExist,
  };
};
