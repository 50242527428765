import React, { useMemo } from 'react';
import { ReactComponent as PointCup } from '@src/Spider/assets/img/points-cup.svg';
import { ReactComponent as InfoCircle } from '@src/Spider/assets/img/homeParticipantsConvert/info-circle.svg';
import { ReactComponent as WarningIcon } from '@src/Spider/assets/img/warning-icon.svg';
import { ReactComponent as LampOnIcon } from '@src/Spider/assets/img/lamp-on-icon.svg';
import BackgroundPriceConverter from '@src/Spider/assets/img/homeParticipantsConvert/background-price-converter.png';
import { neutralColors, systemColors } from '@src/Spider/themes';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import Formsy from 'formsy-react';
import { Button } from '@src/Spider/components';
import { TextFieldFormsy } from '@spider:components/FormsyComponents/TextFieldFormsy';
import { useAuth } from '@src/auth';
import { PointCommandIds } from '@src/Spider/enums';
import { useConvertPointsHelper } from '@src/Spider/hooks/useConvertPointsHelper';
import { Alert } from '@spider:components/Alert';
import { SystemCode } from '@src/Spider/enums/systemCode';
import { CloseButtonIcon } from '@spider:components/CloseButtonIcon';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { Loader } from '@src/components';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { useFormHelper } from '@src/Spider/hooks/useFormHelper';
import { useBaseFormsyValidation } from '@src/Spider/hooks/useBaseFormsyValidation';
import { RewardCardImage } from '@spider:components/RewardCardImage';
import { useFormDataHelper } from '@src/Spider/hooks/useFormDataHelper';
import { usePointStepHelper } from '@src/Spider/hooks/usePointStepHelper';
import { formatNumber } from '@src/helpers/NumberHelper';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  convertZone: {
    display: 'flex',
    padding: '2.5rem 4rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    alignSelf: 'stretch',
    borderRadius: '1rem',
    background: neutralColors.neutralColdWhite,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  informationChip: {
    display: 'flex',
    padding: '0.25rem 0.5rem',
    alignItems: 'center',
    gap: '0.5rem',
    borderRadius: '3rem',
    border: `1px solid ${systemColors.infoRegular}`,
    color: systemColors.infoRegular,
  },
  priceResultZone: {
    display: 'flex',
    padding: '2rem 1rem',
    marginTop: '-1rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
    alignSelf: 'stretch',
    borderRadius: '1rem',
    background: `url(${BackgroundPriceConverter})`,
    backgroundSize: 'cover',
    width: '95%',
    margin: 'auto',
  },
  priceDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    alignSelf: 'stretch',
  },
  rewardImage: {
    height: '170px',
    [theme.breakpoints.down('sm')]: {
      height: '128px',
    },
  },
}));

/**
 * @param {{
 *   nextStep: ({ ignoreProps: string[] }) => void,
 *   classes: any,
 *   stepKeys: string[]
 *   steps: any[]
 *   nextStepLoading: boolean
 * }} props
 */
const PointsStep = ({ nextStep, nextStepLoading, steps, stepKeys }) => {
  const { hierarchyNodeUser } = useAuth();
  const intl = useIntl();
  const classes = useStyles();

  const { baseFormsyValidationErrors, baseFormsyValidations } =
    useBaseFormsyValidation();

  const convertPointsContext = useConvertPoints();

  const { minimumPoints, maximumPoints, maximumPerCard, isMultipleCard } =
    useConvertPointsHelper();

  const { formData, handleFormChange } = useFormDataHelper(
    convertPointsContext.pointStepData,
    { points: maximumPoints },
  );

  const { onPointFieldChange, pointsError, simulationResult } =
    usePointStepHelper({
      maximumPoints,
      steps,
    });

  const { open, onOpen, onClose } = useDisclosure();

  const {
    formRef,
    isSubmitButtonDisabled,
    onInvalid,
    onValid,
    onSubmit,
    formChange,
  } = useFormHelper({
    useDebounceOnValid: true,
    onSubmitCallback: () => nextStep({ ignoreProps: stepKeys }),
    onInvalidCallback: () => convertPointsContext.removePointStepData(true),
    onValidCallback: formData => {
      onPointFieldChange(formData);
      convertPointsContext.setPointStepData({
        value: formData,
        setInSessionStorage: true,
      });
    },
    onFormChange: convertPointsContext.setErrorOnFormChange,
    errorHook: useConvertPoints,
  });

  const helperTextValue = useMemo(
    () =>
      intl
        .formatMessage({
          id: 'spider.convertPoints.stepper.pointStep.helperTextFieldSpe',
        })
        .format(
          formatNumber(parseInt(minimumPoints), false),
          formatNumber(parseInt(maximumPoints), false),
        ),
    [
      convertPointsContext.reward,
      minimumPoints,
      maximumPoints,
      convertPointsContext,
    ],
  );

  return (
    <Formsy
      ref={formRef}
      name={'point-converter-form'}
      className={classes.root}
      onValidSubmit={onSubmit}
      onValid={() => onValid(formData)}
      onInvalid={() => onInvalid(formData)}
      onChange={event => formChange(event, formData, stepKeys)}
    >
      <div className={classes.rootTitle}>
        <PointCup
          fill={neutralColors.neutral900}
          style={{ width: '1.5rem', height: '1.5rem' }}
        />
        <Typography variant={'h3'} component={'h3'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.pointStep.title',
          })}
        </Typography>
      </div>

      {convertPointsContext.reward?.uuid === PointCommandIds.CKU &&
        hierarchyNodeUser.cku && (
          <Alert
            type={SystemCode.WARNING}
            Icon={() => <WarningIcon />}
            title={intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.rechargeAlertTitle',
            })}
            titleVariant={'subtitle1'}
            titleComponent={'span'}
            onClick={onOpen}
          >
            <div>
              <Typography variant={'body2'} style={{ lineHeight: '1.5rem' }}>
                {intl.formatMessage({
                  id: 'spider.convertPoints.stepper.pointStep.rechargeAlertMessageOne',
                })}
              </Typography>
              <Typography variant={'body2'} style={{ lineHeight: '1.5rem' }}>
                {intl.formatMessage({
                  id: 'spider.convertPoints.stepper.pointStep.rechargeAlertMessageTwo',
                })}
              </Typography>
            </div>
          </Alert>
        )}

      <div>
        <div className={classes.convertZone}>
          <Typography variant={'subtitle1'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.convertTitle',
            })}
          </Typography>
          <TextFieldFormsy
            name={'points'}
            type={'number'}
            value={formData.points}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.textFieldLabel',
            })}
            variant={'outlined'}
            required
            style={{ width: '60%' }}
            helperText={helperTextValue}
            validations={{
              ...baseFormsyValidations({
                isInt: true,
                customErrorMessage: pointsError,
              }),
              isMoreThanOrEquals: minimumPoints,
              isLessThanOrEquals: maximumPoints,
            }}
            validationErrors={{
              ...baseFormsyValidationErrors({
                isRequired: true,
                isInt: true,
                customErrorMessage: pointsError,
              }),
              isMoreThanOrEquals: helperTextValue,
              isLessThanOrEquals: helperTextValue,
            }}
            onChange={handleFormChange}
          />
          <div className={classes.informationChip}>
            <InfoCircle fill={systemColors.infoRegular} />
            <Typography
              variant={'subtitle1'}
              style={{ fontSize: '0.625rem', fontWeight: 'bold' }}
            >
              {intl
                .formatMessage({
                  id: 'spider.convertPoints.stepper.pointStep.informationDetails',
                })
                .format(
                  hierarchyNodeUser.point_balance.available - formData.points,
                )}
            </Typography>
          </div>
        </div>
        <div className={classes.priceResultZone}>
          <div className={classes.priceDetails}>
            {convertPointsContext.reward &&
              isMultipleCard &&
              maximumPerCard && (
                <Alert
                  Icon={() => <LampOnIcon />}
                  style={{
                    backgroundColor: `${neutralColors.neutralWhite}66`,
                    color: neutralColors.neutral900,
                  }}
                  title={intl
                    .formatMessage({
                      id: 'spider.convertPoints.stepper.pointStep.multipleCardAlertTitle',
                    })
                    .format(
                      convertPointsContext.reward?.name,
                      formatNumber(maximumPerCard),
                    )}
                  titleVariant={'body2'}
                  titleComponent={'span'}
                  titleStyle={{
                    fontWeight: 'normal',
                    color: neutralColors.neutral900,
                  }}
                />
              )}
            <Typography variant={'body1'}>
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.pointStep.priceReceivedLabel',
              })}
            </Typography>
            {isMultipleCard && simulationResult.isFetching ? (
              <Loader centered />
            ) : (
              isMultipleCard &&
              !simulationResult.isFetching && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <div>
                    {convertPointsContext.simulation?.order_suggested?.items?.map(
                      (detail, index) => (
                        <span key={index}>
                          <Typography variant={'body2'} component={'span'}>
                            {intl
                              .formatMessage({
                                id: 'spider.convertPoints.stepper.pointStep.priceDetailsCard',
                              })
                              .format(detail.quantity)}
                          </Typography>
                          <Typography
                            variant={'body2'}
                            component={'span'}
                            style={{ fontWeight: 'bold' }}
                          >
                            {intl
                              .formatMessage({
                                id: 'spider.convertPoints.stepper.pointStep.priceDetailsMonetaryValue',
                              })
                              .format(formatNumber(detail.monetary_value))}
                          </Typography>
                          {convertPointsContext.simulation.order_suggested
                            .items[index + 1] && (
                            <Typography variant={'body2'} component={'span'}>
                              {intl.formatMessage({
                                id: 'spider.convertPoints.stepper.pointStep.priceDetailsPlus',
                              })}
                            </Typography>
                          )}
                        </span>
                      ),
                    )}
                  </div>
                  <Typography variant={'body1'}>
                    {intl.formatMessage({
                      id: 'spider.convertPoints.stepper.pointStep.priceReceivedLabelTotal',
                    })}
                  </Typography>
                </div>
              )
            )}
            <div>
              {simulationResult.isFetching ? (
                <Loader centered />
              ) : (
                <Typography
                  variant={'h1'}
                  component={'h1'}
                  style={{ fontSize: '3.5rem', fontWeight: 'bold' }}
                >
                  {intl
                    .formatMessage({
                      id: 'spider.convertPoints.stepper.pointStep.price',
                    })
                    .format(
                      formatNumber(
                        convertPointsContext.simulation?.order_suggested
                          ?.monetary_value ?? 0,
                      ),
                    )}
                </Typography>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0.25rem',
                }}
              >
                <Typography variant={'body1'} style={{ fontSize: '0.75rem' }}>
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.includeFeesInfo',
                  })}
                </Typography>
                <Tooltip
                  title={intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.includeFeesInfoTooltip',
                  })}
                  placement='top'
                  arrow
                >
                  <InfoCircle fill={neutralColors.neutral900} />
                </Tooltip>
              </div>
            </div>
            <RewardCardImage
              reward={convertPointsContext.reward}
              classImage={classes.rewardImage}
            />
          </div>
        </div>
      </div>

      <Dialog open={open} onClose={onClose}>
        <DialogTitle disableTypography style={{ alignItems: 'baseline' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <CloseButtonIcon onClick={onClose} />
          </div>
        </DialogTitle>

        <DialogContent
          style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
        >
          <div>
            <Typography
              variant={'h1'}
              component={'h1'}
              className={'underline-center'}
              style={{ width: '100%', textAlign: 'center' }}
            >
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.pointStep.lostCardDialog.title',
              })}
            </Typography>
          </div>

          <div>
            <Typography variant={'subtitle1'} style={{ textAlign: 'center' }}>
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.pointStep.lostCardDialog.blockMessage',
              })}
            </Typography>
            <ul style={{ margin: '0' }}>
              <li>
                <Typography
                  variant={'subtitle1'}
                  style={{ textAlign: 'center' }}
                >
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.lostCardDialog.blockFromClientSpace',
                  })}
                </Typography>
              </li>
              <li>
                <Typography
                  variant={'subtitle1'}
                  style={{ textAlign: 'center' }}
                >
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.lostCardDialog.blockByPhone',
                  })}
                </Typography>
              </li>
            </ul>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            size={'small'}
            color={'primary'}
            variant={'contained'}
            onClick={onClose}
          >
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointBalanceDialog.closeAction',
            })}
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classes.actions}>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'small'}
          type={'submit'}
          disabled={isSubmitButtonDisabled}
          load={nextStepLoading || simulationResult.isFetching}
        >
          {intl.formatMessage({ id: 'spider.common.submit' })}
        </Button>
      </div>
    </Formsy>
  );
};

export { PointsStep };
