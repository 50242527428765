import { Link, Typography } from '@material-ui/core';
import { IconDetails } from '@spider:components/IconDetails';
import { WaypointsStepper } from '@spider:components/WaypointsStepper';
import { ExportSquare, VideoVertical, Weight, Gift } from 'iconsax-react';
import { ReactComponent as RollerCoaster } from '@spider:src/assets/svg/home-convert-help/roller-coaster.svg';
import { systemColors } from '@src/Spider';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLinkOpener } from '@src/Spider/hooks/useLinkOpener';
import { ConvertHelpWrapper } from '@spider:components/ConvertHelp/ConvertHelpWrapper';
import KscHelpImage from '@src/Spider/assets/img/ksc-help.png';

const KSCHelper = () => {
  const intl = useIntl();
  const { openLink } = useLinkOpener();

  const details = [
    {
      icon: VideoVertical,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.ksc.help.features.movies',
      }),
    },
    {
      icon: Weight,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.ksc.help.features.sport',
      }),
    },
    {
      icon: RollerCoaster,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.ksc.help.features.parcs',
      }),
    },
    {
      icon: Gift,
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.ksc.help.features.gift',
      }),
    },
  ];

  const waypoints = [
    {
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.ksc.help.steps.one',
      }),
    },
    {
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.ksc.help.steps.two',
      }),
    },
    {
      message: intl.formatMessage({
        id: 'spider.convertPoints.home.ksc.help.steps.three',
      }),
    },
  ];

  return (
    <ConvertHelpWrapper
      title={intl.formatMessage({
        id: 'spider.convertPoints.home.ksc.help.title',
      })}
      imageSrc={KscHelpImage}
      imageAlt='ksc-help'
    >
      {details.map((detail, index) => (
        <IconDetails key={index} Icon={detail.icon} message={detail.message} />
      ))}

      <Typography
        variant='body1'
        style={{ textAlign: 'left', fontWeight: '700' }}
      >
        {intl.formatMessage({
          id: 'spider.convertPoints.home.ksc.help.how_it_work',
        })}
      </Typography>

      <WaypointsStepper waypoints={waypoints} />

      <Typography variant='body1' style={{ textAlign: 'left' }}>
        {intl.formatMessage({
          id: 'spider.convertPoints.home.ksc.help.how_it_work_result',
        })}
      </Typography>

      <Typography
        variant='body2'
        style={{ textAlign: 'left', fontStyle: 'italic' }}
      >
        {intl.formatMessage({
          id: 'spider.convertPoints.home.ksc.help.disclaimer',
        })}
      </Typography>

      <Link
        className={'bold small icon'}
        onClick={() => openLink(process.env.REACT_APP_EDENRED_KSC_HELP_LINK)}
        style={{ textAlign: 'left' }}
      >
        <ExportSquare size='16' color={systemColors.infoRegular} />
        {intl.formatMessage({
          id: 'spider.convertPoints.home.ksc.help.call_to_action',
        })}
      </Link>
    </ConvertHelpWrapper>
  );
};

export { KSCHelper };
