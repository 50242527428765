import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { formatDate } from '@src/helpers/DateHelper';

/** @param {{date: Date}} props */
export const TimeAndDay = ({ date }) => {
  if (!date) return '--';
  return (
    <Box>
      <Typography>{formatDate(date).replaceAll('/', '.')}</Typography>
      <Typography variant='h6'>
        {formatDate(date, {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </Typography>
    </Box>
  );
};
