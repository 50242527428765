import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { ConvertPointsStepper } from './components/ConvertPointsStepper';
import { Paper } from '@material-ui/core';
import { PointsStep } from './components/PointsStep';
import { InformationStep } from './components/InformationStep';
import { DeclarationStep } from './components/DeclarationStep';
import { SummaryStep } from './components/SummaryStep';
import useConvertPointsSteps from '@spider:src/hooks/UseConvertPointsSteps';
import { useHistory } from 'react-router-dom';
import { useConvertPointsHelper } from '@src/Spider/hooks/useConvertPointsHelper';
import { Loader } from '@src/components';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { SuccessOrderDialog } from '@src/Spider/scenes/ConvertPoints/components/components/SuccessOrderDialog';
import { ErrorBalanceOrderDialog } from '@src/Spider/scenes/ConvertPoints/components/components/ErrorBalanceOrderDialog';
import { useStepperSimulation } from '@src/Spider/hooks/useStepperSimulation';
import { useStepperValidity } from '@src/Spider/hooks/useStepperValidity';
import { useStepperDialogs } from '@src/Spider/hooks/useStepperDialogs';
import { ConversionStepsUrls } from '@src/Spider/enums/conversionStepsUrls';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    marginRight: '1rem',
  },
  rootContent: {
    '&.MuiPaper-root': {
      boxShadow: 'none',
      padding: '1.5rem',
      borderRadius: '1.5rem',
    },
  },
};

const Component = ({ ...props }) => {
  const history = useHistory();
  const convertPointsContext = useConvertPoints();

  const [isInitialized, setIsInitialized] = useState(false);
  const { steps, baseUrl } = useConvertPointsSteps();

  const goHome = () => {
    history.push(`${baseUrl}home`);
  };

  const { currentBalanceTooLow, minimumPoints, maximumPoints } =
    useConvertPointsHelper();

  const {
    openSuccessOrderDialog,
    onOpenSuccessOrderDialog,
    closeSuccessOrderDialog,
    openErrorBalanceDialog,
    closeErrorBalanceDialog,
    pointBalanceRest,
  } = useStepperDialogs({ goHome, currentBalanceTooLow });

  const { triggerSimulation } = useStepperSimulation();

  const {
    previousStep,
    activeStep,
    checkStepValidity,
    goStep,
    orderRewardResult,
  } = useStepperValidity({ steps, goHome, onOpenSuccessOrderDialog });

  useEffect(() => {
    const initializeStepper = async () => {
      let pointToConvert = convertPointsContext.pointStepData?.points;

      if (!pointToConvert) {
        convertPointsContext.setPointStepData({ points: maximumPoints });
        pointToConvert = maximumPoints;
      }

      const simulation = await triggerSimulation({
        pointToConvert,
        reward: convertPointsContext.reward,
      });

      await checkStepValidity({
        ignoreProps: [],
        forceCheck: true,
        forcePointCheck: true,
        items: simulation.order_suggested.items,
        showError: false,
        autoNextStep: false,
      });

      await checkStepValidity({
        ignoreProps: [],
        forceCheck: true,
        items: simulation.order_suggested.items,
        showError: false,
        autoNextStep: false,
      });
      setIsInitialized(true);
    };
    initializeStepper();
  }, []);

  const nextStepLoading = orderRewardResult.isLoading;

  return (
    <div className={props.classes.root}>
      <ConvertPointsStepper
        steps={steps}
        activeStep={activeStep}
        previousStep={previousStep}
      />

      {isInitialized ? (
        <Paper className={props.classes.rootContent}>
          {steps[activeStep].src ===
            `${baseUrl}${ConversionStepsUrls.POINTS}` && (
            <PointsStep
              nextStep={checkStepValidity}
              steps={steps}
              stepKeys={steps[activeStep].ignoreProps}
              nextStepLoading={nextStepLoading}
            />
          )}
          {steps[activeStep].src ===
            `${baseUrl}${ConversionStepsUrls.INFORMATION}` && (
            <InformationStep
              nextStep={checkStepValidity}
              previousStep={previousStep}
              stepKeys={steps[activeStep].ignoreProps}
              nextStepLoading={nextStepLoading}
            />
          )}
          {steps[activeStep].src ===
            `${baseUrl}${ConversionStepsUrls.DECLARATION}` && (
            <DeclarationStep
              nextStep={checkStepValidity}
              previousStep={previousStep}
              stepKeys={steps[activeStep].ignoreProps}
              nextStepLoading={nextStepLoading}
            />
          )}
          {steps[activeStep].src ===
            `${baseUrl}${ConversionStepsUrls.SUMMARY}` && (
            <SummaryStep
              nextStep={checkStepValidity}
              previousStep={previousStep}
              goStep={goStep}
              stepKeys={steps[activeStep].ignoreProps}
              nextStepLoading={nextStepLoading}
            />
          )}
        </Paper>
      ) : (
        <Loader centered />
      )}
      <ErrorBalanceOrderDialog
        open={openErrorBalanceDialog}
        onClose={closeErrorBalanceDialog}
        minimumPoints={minimumPoints}
        rewardName={convertPointsContext.reward?.name}
      />
      <SuccessOrderDialog
        open={openSuccessOrderDialog}
        onClose={closeSuccessOrderDialog}
        points={convertPointsContext.pointStepData?.points}
        rewardName={convertPointsContext.reward?.name}
        pointBalanceRest={pointBalanceRest}
      />
    </div>
  );
};

const ConvertPoints = withStyles(styles)(Component);

export { ConvertPoints };
