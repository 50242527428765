import { toast } from 'react-toastify';
import { useOrderRewardMutation } from '@async-calls/rewards';
import { setHierarchyNodeUser, useAuth } from '@src/auth';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { useEffect } from 'react';
import { allHierarchyNodeUsersFetchingStart } from '@src/Spider/features/base/allPagesFetchingFromApi/slices';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSessionStorage } from '@src/data/session/useSessionStorage';

/**
 * @param {{
 *   steps: Array,
 *   goHome: () => void,
 *   onOpenSuccessOrderDialog: () => void,
 * }} props
 */
export const useStepperValidity = ({
  steps,
  goHome = () => {},
  onOpenSuccessOrderDialog = () => {},
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { contract } = useParams();
  const { hierarchyNodeUser } = useAuth();
  const convertPointsContext = useConvertPoints();
  const dispatch = useDispatch();

  const allHierarchyNodeUsersFetchingState = useSelector(
    state => state.allHierarchyNodeUsersFetching,
  );

  const [activeStep, setActiveStep, removeActiveStep] = useSessionStorage(
    'activeStep',
    0,
  );

  const [orderReward, orderRewardResult] = useOrderRewardMutation();

  const optionalField = field => {
    if (!field || field?.length === 0) return null;
    return field;
  };

  const setStep = stepIndex => {
    setActiveStep({ value: stepIndex, setInSessionStorage: true });
    history.push(steps[stepIndex].src, { shallow: true });
  };

  const nextStep = () => {
    if (activeStep === steps.length - 1) {
      dispatch(allHierarchyNodeUsersFetchingStart([contract, true]));
      onOpenSuccessOrderDialog();
      toast.success(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.personalization.update.success',
        }),
      );
      return;
    }
    setStep(activeStep + 1);
  };

  const previousStep = () => {
    if (activeStep === 0) {
      goHome();
      return;
    }
    setStep(activeStep - 1);
  };

  const findStepIndexBySrc = src => {
    return steps.findIndex(step => step.src === src);
  };

  /** @param {import('@spider:src/enums/conversionStepsUrls').ConversionStepsUrls} stepName */
  const goStep = stepName => {
    const src = `/nodes/${contract}/${stepName}`;
    history.push(src, {
      shallow: true,
    });
    setActiveStep({
      value: findStepIndexBySrc(src),
      setInSessionStorage: true,
    });
  };

  const getOrderRewardParams = ({
    forceCheck = false,
    forcePointCheck = false,
    items = null,
  }) => {
    const orderRewardItems = items
      ? items
      : convertPointsContext.simulation?.order_suggested?.items;

    const check = forceCheck ? forceCheck : activeStep !== steps.length - 1;
    const hierarchyNodeUserId = hierarchyNodeUser.uuid;

    const orderRewardParams = {
      hierarchyNodeUserId,
      rewardOrder: {
        items: orderRewardItems,
        check,
      },
    };

    if (forcePointCheck) {
      return orderRewardParams;
    }

    orderRewardParams.rewardOrder.form =
      convertPointsContext.informationStepData
        ? {
            ...convertPointsContext.informationStepData,
            delivery_address: convertPointsContext.informationStepData
              .delivery_address
              ? {
                  ...convertPointsContext.informationStepData.delivery_address,
                  line2: optionalField(
                    convertPointsContext.informationStepData.delivery_address
                      .line2,
                  ),
                }
              : {},
            personal_address: convertPointsContext.informationStepData
              .personal_address
              ? {
                  ...convertPointsContext.informationStepData.personal_address,
                  line2: optionalField(
                    convertPointsContext.informationStepData.personal_address
                      .line2,
                  ),
                }
              : {},
          }
        : null;

    orderRewardParams.rewardOrder.lfss_form =
      convertPointsContext.declarationStepData
        ? {
            ...convertPointsContext.declarationStepData,
            birth_name: optionalField(
              convertPointsContext.declarationStepData.birth_name,
            ),
            personal_address: convertPointsContext.declarationStepData
              .personal_address
              ? {
                  ...convertPointsContext.declarationStepData.personal_address,
                  line2: optionalField(
                    convertPointsContext.declarationStepData.personal_address
                      .line2,
                  ),
                }
              : {},
            employer_address: convertPointsContext.declarationStepData
              .employer_address
              ? {
                  ...convertPointsContext.declarationStepData.employer_address,
                  line2: optionalField(
                    convertPointsContext.declarationStepData.employer_address
                      .line2,
                  ),
                }
              : {},
          }
        : null;

    if (
      orderRewardParams.rewardOrder.form?.delivery_address &&
      !convertPointsContext.informationStepData?.delivery_address
    ) {
      const { delivery_address, ...restForm } =
        orderRewardParams.rewardOrder.form;
      orderRewardParams.rewardOrder.form = restForm;
    }
    if (
      orderRewardParams.rewardOrder.form?.personal_address &&
      !convertPointsContext.informationStepData?.personal_address
    ) {
      const { personal_address, ...restForm } =
        orderRewardParams.rewardOrder.form;
      orderRewardParams.rewardOrder.form = restForm;
    }

    if (
      orderRewardParams.rewardOrder.lfss_form?.personal_address &&
      !convertPointsContext.declarationStepData?.personal_address
    ) {
      const { personal_address, ...restForm } =
        orderRewardParams.rewardOrder.lfss_form;
      orderRewardParams.rewardOrder.lfss_form = restForm;
    }
    if (
      orderRewardParams.rewardOrder.lfss_form?.employer_address &&
      !convertPointsContext.declarationStepData?.employer_address
    ) {
      const { employer_address, ...restForm } =
        orderRewardParams.rewardOrder.lfss_form;
      orderRewardParams.rewardOrder.lfss_form = restForm;
    }
    if (
      orderRewardParams.rewardOrder.lfss_form?.personal_infos &&
      !convertPointsContext.declarationStepData?.personal_infos
    ) {
      const { personal_infos, ...restForm } =
        orderRewardParams.rewardOrder.lfss_form;
      orderRewardParams.rewardOrder.lfss_form = restForm;
    }

    if (convertPointsContext.summaryStepData) {
      orderRewardParams.rewardOrder.terms_accepted =
        convertPointsContext.summaryStepData.terms_accepted;
    }

    return orderRewardParams;
  };

  const checkStepValidity = async ({
    ignoreProps,
    errorMessageId = 'common.error_page_title',
    forceCheck = false,
    forcePointCheck = false,
    items = null,
    showError = true,
    autoNextStep = true,
  }) => {
    try {
      const orderRewardParams = getOrderRewardParams({
        forceCheck,
        forcePointCheck,
        items,
      });
      await orderReward(orderRewardParams).unwrap();
      if (forcePointCheck) {
        convertPointsContext.setRequestFlags(null);
      }
      convertPointsContext.setError({});
      if (autoNextStep) {
        nextStep();
      }
    } catch (error) {
      console.error(error);
      if (forcePointCheck) {
        convertPointsContext.setRequestFlags({ ...error?.data });
      }
      if (
        ignoreProps?.length > 0 &&
        error?.data &&
        Object.keys(error?.data)?.every(key => {
          return !ignoreProps.includes(key);
        }) &&
        autoNextStep &&
        activeStep !== steps.length - 1
      ) {
        nextStep();
        return;
      }
      convertPointsContext.setError({ ...error?.data, preventDelete: true });
      if (showError) {
        toast.error(
          intl.formatMessage({
            id: errorMessageId,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (!allHierarchyNodeUsersFetchingState.data) return;
    const currentHierarchyNodeUser =
      allHierarchyNodeUsersFetchingState.data.find(
        user => user.uuid === hierarchyNodeUser.uuid,
      );
    if (!currentHierarchyNodeUser) return;
    dispatch(setHierarchyNodeUser(currentHierarchyNodeUser));
  }, [allHierarchyNodeUsersFetchingState]);

  useEffect(() => {
    return () => {
      removeActiveStep();
    };
  }, []);

  return {
    previousStep,
    checkStepValidity,
    goStep,
    activeStep,
    orderRewardResult,
  };
};
