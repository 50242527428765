import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as PointCup } from '@spider:src/assets/img/points-cup.svg';
import { ReactComponent as PointSummary } from '@spider:src/assets/img/points-summary.svg';
import { ReactComponent as PointUser } from '@spider:src/assets/img/points-user.svg';
import { ReactComponent as PointDeclaration } from '@spider:src/assets/img/points-declaration.svg';
import { neutralColors } from '../themes';
import { useAuth } from '@src/auth';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { ConversionStepsUrls } from '@src/Spider/enums/conversionStepsUrls';

const UseConvertPointsSteps = () => {
  const { hierarchyNodeUser } = useAuth();
  const { contract } = useParams();
  const convertPointsContext = useConvertPoints();
  const baseUrl = useMemo(() => `/nodes/${contract}/`, [contract]);

  const pointsStep = {
    label: 'Points',
    src: `${baseUrl}${ConversionStepsUrls.POINTS}`,
    icon: <PointCup fill={neutralColors.neutralWhite} />,
    ignoreProps: ['items'],
  };
  const informationStep = {
    label: 'Informations',
    src: `${baseUrl}${ConversionStepsUrls.INFORMATION}`,
    icon: <PointUser fill={neutralColors.neutralWhite} />,
    ignoreProps: ['form'],
  };
  const declarationStep = {
    label: 'Declaration',
    src: `${baseUrl}${ConversionStepsUrls.DECLARATION}`,
    icon: <PointDeclaration fill={neutralColors.neutralWhite} />,
    ignoreProps: ['lfss_form'],
  };
  const summaryStep = {
    label: 'Récapitulatif',
    src: `${baseUrl}${ConversionStepsUrls.SUMMARY}`,
    icon: <PointSummary fill={neutralColors.neutralWhite} />,
    ignoreProps: ['terms_accepted'],
  };

  const steps = useMemo(() => {
    const steps = [pointsStep, informationStep];

    if (convertPointsContext.requestFlags?.lfss_form) {
      steps.push(declarationStep);
    }

    steps.push(summaryStep);

    return steps;
  }, [
    hierarchyNodeUser,
    convertPointsContext.reward,
    convertPointsContext.requestFlags,
  ]);

  return {
    steps,
    sources: steps.map(step => step.src),
    pointsStep,
    informationStep,
    declarationStep,
    summaryStep,
    baseUrl,
  };
};

export default UseConvertPointsSteps;
