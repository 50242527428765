import React from 'react';
import {
  Box,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { ArrowDown2 } from 'iconsax-react';
import { useIntl } from 'react-intl';
import { neutralColors, themeColors } from '@src/Spider';

const useStyle = makeStyles({
  select: {
    '& > .MuiSelect-selectMenu': {
      minWidth: 70,
      height: 32,
      margin: '0 8px',
      border: `2px solid ${neutralColors.neutral450}`,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      boxSizing: 'border-box',
      minHeight: 19,
      fontSize: 12,
    },
    '& > .MuiSelect-select': {
      '&:focus': {
        borderRadius: 8,
        backgroundColor: neutralColors.neutralWhite,
      },
    },
    '& > .MuiSelect-icon': {
      color: themeColors.secondaryRegular,
      top: `calc(50% - 13px)`,
      width: 26,
      height: 26,
      right: 15,
    },
  },
});

const RowsPerPageSelector = ({
  rowsPerPage,
  handleChangeRowsPerPage,
  rowsPerPageOptions = [5, 10, 25],
  className,
}) => {
  const intl = useIntl();
  const classes = useStyle();

  return (
    <Box
      display='flex'
      justifyContent='flex-end'
      alignItems='center'
      className={className}
    >
      <Typography>
        {intl.formatMessage({
          id: 'spider.data_table.number_of_lines_displayed',
        })}
      </Typography>
      <FormControl>
        <Select
          labelId='rows-per-page-label'
          value={rowsPerPage}
          className={classes.select}
          onChange={handleChangeRowsPerPage}
          disableUnderline
          IconComponent={ArrowDown2}
        >
          {rowsPerPageOptions.map((option, index) => {
            return (
              <MenuItem value={option} key={index}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RowsPerPageSelector;
