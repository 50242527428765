import React from 'react';
import { LFSSParameters } from './components/LFSSParameters';
import { PointsParameters } from './components/PointsParameters';
import { ProductsParameters } from './components/ProductsParameters';
import { PlatformParameters } from '@spider:src/scenes/Admin/components/ParametersTab/components/PlatformParameters';
import { ContactsParameters } from './components/ContactsParameters';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
}));

const ParametersTabs = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PlatformParameters />
      <LFSSParameters />
      <PointsParameters />
      <ContactsParameters />
      <ProductsParameters />
    </div>
  );
};

export default ParametersTabs;
