import React, { useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import { gradients } from '@src/Spider';
import { useParams } from 'react-router-dom';
import { useLazyGetConfigsListQuery } from '@async-calls/configs';
import { ConfigCodes } from '@src/Spider/enums/configCodes';

const DefaultConfig = {
  mainColor: { value: gradients.gradientLeftToRight100 },
  mainDefaultColor: { value: gradients.gradientLeftToRight100 },
};

const AppConfigurationContext = createContext({
  config: DefaultConfig,
  setConfig: () => {},
});

export const AppConfigurationProvider = ({ children }) => {
  const [config, setConfig] = useState(DefaultConfig);

  const { contract } = useParams();

  const [fetchConfigsList, configListResult] = useLazyGetConfigsListQuery();

  useEffect(() => {
    if (!contract) return;
    const initializeAppConfiguration = async () => {
      try {
        const configs = await fetchConfigsList({
          hierarchyNodeId: contract,
        }).unwrap();

        const mainColor = configs?.find(
          config => config.code === ConfigCodes.MAIN_COLOR,
        );
        if (mainColor) {
          setConfig({
            mainColor: { ...mainColor },
            mainDefaultColor: { ...mainColor },
          });
        }
      } catch (error) {
        console.error(
          "Une erreur s'est produite lors de la récupération de la configuration",
          error,
        );
      }
    };

    initializeAppConfiguration();
  }, [contract]);

  useEffect(() => {
    return () => {
      setConfig(DefaultConfig);
    };
  }, []);

  return (
    <AppConfigurationContext.Provider
      value={{
        config,
        setConfig,
      }}
    >
      {children}
    </AppConfigurationContext.Provider>
  );
};

export const useAppConfiguration = () => {
  const context = useContext(AppConfigurationContext);

  if (!context) {
    throw new Error(
      'AppConfiguration context provider needs to be declared to be used',
    );
  }

  return context;
};
