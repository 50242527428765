Number.prototype.toFullPercentage = function (digits = 0) {
  return Number(Number(this * 100).toFixed(digits));
};

Number.prototype.toPercentage = function (digits = 0) {
  return `${Number(this * 100).toFixed(digits)}%`;
};

Number.prototype.toDate = function () {
  try {
    const date = new Date(this * 1000);
    if (date.toString() === 'Invalid Date') {
      return null;
    }
    return date;
  } catch {
    return null;
  }
};

Number.prototype.toDate2 = function () {
  const date = new Date(this * 1000);
  const timezoneOffset = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() + timezoneOffset);
  return date;
};

/**
 * @param {number|null} number - Number to format
 * @param {boolean} [useCurrency] - Currency option
 * @returns {string|null}
 */
export const formatNumber = (number, useCurrency = true) => {
  if (isNaN(number)) return null;
  return new Intl.NumberFormat('fr-FR', {
    style: useCurrency ? 'currency' : undefined,
    currency: useCurrency ? 'EUR' : undefined,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};
