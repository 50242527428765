import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { toast } from 'react-toastify';
import { useAuth } from '@src/auth';
import { useLazySimulateRewardAllocationQuery } from '@async-calls/rewardAllocations';
import { useIntl } from 'react-intl';

export const useStepperSimulation = () => {
  const intl = useIntl();
  const convertPointsContext = useConvertPoints();
  const { hierarchyNodeUser } = useAuth();

  const [simulate, simulationResult] = useLazySimulateRewardAllocationQuery();

  /**
   * @param {number} pointToConvert
   * @param {Object} reward
   */
  const triggerSimulation = async ({ pointToConvert, reward }) => {
    if (!reward && !convertPointsContext.reward) {
      console.error('Reward not set');
      return;
    }

    try {
      const simulation = await simulate({
        id: reward
          ? reward._context.allocation.uuid
          : convertPointsContext.reward._context.allocation.uuid,
        points: pointToConvert,
        hierarchyNodeUserId: hierarchyNodeUser.uuid,
      }).unwrap();
      convertPointsContext.setSimulation(simulation);
      return simulation;
    } catch (error) {
      console.error('Failed to simulate:', error);
      toast.error(
        intl.formatMessage({
          id: 'spider.convertPoints.stepper.simulate.error',
        }),
      );
      convertPointsContext.setSimulation(null);
    }
  };

  return { triggerSimulation, simulationResult };
};
