import { useMemo } from 'react';
import { useStepperSimulation } from '@src/Spider/hooks/useStepperSimulation';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { useStepperValidity } from '@src/Spider/hooks/useStepperValidity';

export const usePointStepHelper = ({ maximumPoints, steps }) => {
  const { triggerSimulation, simulationResult } = useStepperSimulation();
  const convertPointsContext = useConvertPoints();
  const { checkStepValidity } = useStepperValidity({ steps });

  const pointsError = useMemo(() => {
    if (!convertPointsContext.error?.items?.[0]?.points?.[0]) return null;
    return convertPointsContext.error.items[0].points[0];
  }, [convertPointsContext.error]);

  const handleSimulationReset = context => {
    if (!context || !parseInt(context.points)) {
      convertPointsContext.setSimulation(null);
      return true;
    }
    return false;
  };

  const getPointToConvert = context => {
    if (context) {
      return parseInt(context.points);
    } else {
      return convertPointsContext.simulation
        ? convertPointsContext.simulation.max_points_usable
        : maximumPoints;
    }
  };

  const onPointFieldChange = async formData => {
    if (handleSimulationReset(formData)) return;

    const pointToConvert = getPointToConvert(formData);

    const simulation = await triggerSimulation({ pointToConvert });

    await checkStepValidity({
      ignoreProps: [],
      forceCheck: true,
      forcePointCheck: true,
      items: simulation.order_suggested.items,
      showError: false,
    });
  };

  return { pointsError, onPointFieldChange, simulationResult };
};
