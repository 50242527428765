import React from 'react';
import Formsy from 'formsy-react';
import { neutralColors, systemColors } from '@spider:src/themes';
import { Typography, withStyles, createTheme } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { ReactComponent as PointSummary } from '@spider:src/assets/img/points-summary.svg';
import { ReactComponent as MailIcon } from '@spider:src/assets/img/homeParticipantsConvert/mail-icon.svg';
import { ReactComponent as InfoCircle } from '@spider:src/assets/img/homeParticipantsConvert/info-circle.svg';
import { Button } from '@spider:components/index';
import { useAuth } from '@src/auth';
import { PointCommandIds } from '@spider:src/enums';
import { Alert } from '@spider:components/Alert';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { useFormHelper } from '@src/Spider/hooks/useFormHelper';
import { RequiredCheckboxCheck } from '@spider:components/RequiredCheckboxCheck';
import { InformationWrapper } from '@src/Spider/scenes/ConvertPoints/components/components/SummaryComponents/InformationWrapper';
import { PointsSummary } from '@src/Spider/scenes/ConvertPoints/components/components/SummaryComponents/PointsSummary';
import { ConversionStepsUrls } from '@src/Spider/enums/conversionStepsUrls';
import { InformationSummary } from '@src/Spider/scenes/ConvertPoints/components/components/SummaryComponents/InformationSummary';
import { DeclarationSummary } from '@src/Spider/scenes/ConvertPoints/components/components/SummaryComponents/DeclarationSummary';
import { useFormDataHelper } from '@src/Spider/hooks/useFormDataHelper';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  block: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
  informationCard: {
    display: 'flex',
    padding: '1rem 1rem 1rem 1rem',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'center',
    borderRadius: '1rem',
    overflow: 'clip',
    position: 'relative',
    backgroundColor: neutralColors.neutralColdWhite,
  },
  topRightButtonWrapper: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    [createTheme().breakpoints.down('md')]: {
      position: 'relative',
      display: 'flex',
      top: '0rem',
      right: '0rem',
      justifyContent: 'right',
    },
  },
  mailAlert: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    [createTheme().breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  emailChip: {
    display: 'flex',
    padding: '0.25rem 0.25rem 0.25rem 0.75rem',
    alignItems: 'center',
    gap: '0.5rem',
    borderRadius: '3rem',
    border: '1px solid #006CFA',
    width: 'max-content',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

/**
 * @param {{
 *   nextStep: ({ ignoreProps: string[] }) => void,
 *   previousStep: () => void,
 *   classes: any,
 *   stepKeys: string[]
 *   goStep: (stepName: import('@spider:src/enums/conversionStepsUrls').ConversionStepsUrls) => void,
 *   nextStepLoading: boolean,
 * }} props
 */
const Component = ({
  nextStep,
  nextStepLoading,
  previousStep,
  goStep,
  classes,
  stepKeys,
}) => {
  const { hierarchyNodeUser } = useAuth();
  const intl = useIntl();
  const convertPointsContext = useConvertPoints();

  const defaultFormData = {
    terms_accepted: false,
  };

  const { formData, handleFormChange } = useFormDataHelper(
    convertPointsContext.summaryStepData,
    defaultFormData,
  );

  const {
    formRef,
    isSubmitButtonDisabled,
    onInvalid,
    onValid,
    onSubmit,
    formChange,
  } = useFormHelper({
    onSubmitCallback: () => nextStep({ ignoreProps: stepKeys }),
    onInvalidCallback: () => convertPointsContext.removeSummaryStepData(true),
    onValidCallback: formData => {
      convertPointsContext.setSummaryStepData({
        value: formData,
        setInSessionStorage: true,
      });
    },
    onFormChange: convertPointsContext.setErrorOnFormChange,
    errorHook: useConvertPoints,
  });

  return (
    <Formsy
      ref={formRef}
      name={'summary-form'}
      className={classes.root}
      onValidSubmit={onSubmit}
      onValid={() => onValid(formData)}
      onInvalid={onInvalid}
      onChange={event => formChange(event, defaultFormData, stepKeys)}
    >
      <div className={classes.rootTitle}>
        <PointSummary
          fill={neutralColors.neutral900}
          style={{ width: '1.5rem', height: '1.5rem' }}
        />
        <Typography variant={'h3'} component={'h3'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.title',
          })}
        </Typography>
      </div>

      <InformationWrapper
        isError={convertPointsContext.error?.items}
        title={intl.formatMessage({
          id: 'spider.convertPoints.stepper.summaryStep.points.title',
        })}
        relativeButton={true}
        onClick={() => goStep(ConversionStepsUrls.POINTS)}
      >
        <PointsSummary />
      </InformationWrapper>

      {convertPointsContext.informationStepData && (
        <InformationWrapper
          isError={convertPointsContext.error?.form}
          showWarningAlert={true}
          title={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.information.title',
          })}
          onClick={() => goStep(ConversionStepsUrls.INFORMATION)}
        >
          <InformationSummary />
        </InformationWrapper>
      )}

      {convertPointsContext.declarationStepData && (
        <InformationWrapper
          isError={convertPointsContext.error?.lfss_form}
          title={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.title',
          })}
          onClick={() => goStep(ConversionStepsUrls.DECLARATION)}
        >
          <DeclarationSummary />
        </InformationWrapper>
      )}

      {convertPointsContext.reward?.uuid !== PointCommandIds.KSC && (
        <Alert
          Icon={() => <InfoCircle width={'1.5rem'} height={'1.5rem'} />}
          title={intl
            .formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.deliveryAddress.alertTitle',
            })
            .format(convertPointsContext.reward.name)}
          titleVariant={'body2'}
          titleComponent={'span'}
          titleStyle={{
            fontWeight: 'normal',
            color: neutralColors.neutral600,
          }}
          style={{ marginTop: '-1rem' }}
        />
      )}

      <div className={classes.mailAlert}>
        <Typography variant={'body2'} style={{ fontWeight: 'bold' }}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.activation_card_label',
          })}
        </Typography>
        <div className={classes.emailChip}>
          <MailIcon fill={systemColors.infoRegular} />
          <Typography
            variant={'body1'}
            style={{ color: systemColors.infoRegular, fontWeight: 'bold' }}
          >
            {hierarchyNodeUser.email}
          </Typography>
        </div>
      </div>

      <RequiredCheckboxCheck
        name={'terms_accepted'}
        formData={formData.terms_accepted}
        error={convertPointsContext.error?.terms_accepted}
        handleFormChange={handleFormChange}
        label={intl
          .formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.readAndLearnAttestation',
          })
          .format(convertPointsContext.reward?.name ?? '')}
      />

      <div className={classes.actions}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'large'}
          onClick={previousStep}
          load={nextStepLoading}
        >
          {intl.formatMessage({ id: 'spider.common.back' })}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'large'}
          type={'submit'}
          disabled={isSubmitButtonDisabled}
          load={nextStepLoading}
        >
          {intl.formatMessage({ id: 'spider.common.submit' })}
        </Button>
      </div>
    </Formsy>
  );
};

const SummaryStep = withStyles(styles)(Component);

export { SummaryStep };
