import React, { useMemo } from 'react';
import { TextFieldFormsy } from '@spider:components/FormsyComponents/TextFieldFormsy';
import { useIntl } from 'react-intl';
import { makeStyles, MenuItem } from '@material-ui/core';
import { useGetDepartmentsListQuery } from '@async-calls/departments';
import { useGetCountriesListQuery } from '@async-calls/countries';
import { Loader } from '@src/components';
import { SelectFormsy } from '@spider:components/FormsyComponents/SelectFormsy';
import { neutralColors } from '@src/Spider';
import { useBaseFormsyValidation } from '@src/Spider/hooks/useBaseFormsyValidation';

const useStyles = makeStyles(() => ({
  menuItem: {
    alignItems: 'center',
    display: 'flex',
    padding: '0rem 0rem 0rem 0.25rem !important',
    margin: '0rem !important',
    backgroundColor: `transparent !important`,
    color: `${neutralColors.neutral900} !important`,
    '&:focus': {
      backgroundColor: `${neutralColors.neutral200} !important`,
      border: 'none !important',
      color: `${neutralColors.neutral900} !important`,
    },
    '&:hover': {
      backgroundColor: `${neutralColors.neutral200} !important`,
      color: `${neutralColors.neutral900} !important`,
      opacity: '1 !important',
    },
    '&:selected': {
      backgroundColor: `${neutralColors.neutral300} !important`,
      color: `${neutralColors.neutral900} !important`,
    },
    '&:active': {
      backgroundColor: `${neutralColors.neutral200} !important`,
      color: `${neutralColors.neutral900} !important`,
    },
  },
}));

/**
 * @param {{
 *   formData: Object,
 *   classes: any,
 *   error: any,
 *   handleFormChange: (formData: Object) => void,
 * }} props
 */
const Component = ({
  formData,
  baseProps = null,
  classes,
  error,
  handleFormChange,
}) => {
  const intl = useIntl();
  const localClasses = useStyles();
  const { baseFormsyValidationErrors, baseFormsyValidations } =
    useBaseFormsyValidation();

  const baseNameProps = useMemo(
    () => `${baseProps ? `${baseProps}.` : ''}`,
    [baseProps],
  );

  const departmentsListResult = useGetDepartmentsListQuery({});

  const countriesListResult = useGetCountriesListQuery({});

  const maxDate = new Date(),
    birthCommuneMaxLength = 60,
    socialSecurityNumberMaxLength = 15;

  return departmentsListResult.isFetching || countriesListResult.isFetching ? (
    <Loader centered />
  ) : (
    <div className={classes.formFields}>
      <TextFieldFormsy
        name={`${baseNameProps}birth_date`}
        value={formData.birth_date}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.informationStep.personalInformation.dateOfBirthField',
        })}
        variant={'outlined'}
        type='date'
        required
        InputLabelProps={{
          shrink: true,
        }}
        validations={{
          ...baseFormsyValidations({
            customErrorMessage: error?.birth_date,
          }),
          isDateLessThan: maxDate,
        }}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
            customErrorMessage: error?.birth_date,
          }),
          isDateLessThan: intl
            .formatMessage({
              id: 'common.form.is_date_less_than',
            })
            .format(
              maxDate.toLocaleString('fr-FR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }),
            ),
        }}
        onChange={handleFormChange}
      />
      <SelectFormsy
        id='birth_department_label'
        name={`${baseNameProps}birth_department`}
        value={formData.birth_department}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalInformation.depsOfBirth',
        })}
        variant='outlined'
        className={localClasses.select}
        onChange={handleFormChange}
        required
        styles={{ width: '100%' }}
        validations={{
          ...baseFormsyValidations({
            customErrorMessage: error?.birth_department,
          }),
        }}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
            customErrorMessage: error?.birth_department,
          }),
        }}
      >
        {departmentsListResult?.data.map(department => (
          <MenuItem
            key={department?.uuid}
            value={department?.uuid}
            className={localClasses.menuItem}
          >{`${department?.name} ${department?.number}`}</MenuItem>
        ))}
      </SelectFormsy>
      <TextFieldFormsy
        name={`${baseNameProps}birth_city`}
        value={formData.birth_city}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalInformation.communeOfBirth',
        })}
        variant={'outlined'}
        required
        validations={{
          ...baseFormsyValidations({
            maxLength: birthCommuneMaxLength,
            customErrorMessage: error?.birth_city,
          }),
        }}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
            maxLength: birthCommuneMaxLength,
            customErrorMessage: error?.birth_city,
          }),
        }}
        onChange={handleFormChange}
      />
      <SelectFormsy
        id='birth_country_label'
        name={`${baseNameProps}birth_country`}
        value={formData.birth_country}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalInformation.countryOfBirth',
        })}
        variant='outlined'
        className={localClasses.select}
        onChange={handleFormChange}
        required
        styles={{ width: '100%' }}
        validations={{
          ...baseFormsyValidations({
            customErrorMessage: error?.birth_country,
          }),
        }}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
            customErrorMessage: error?.birth_country,
          }),
        }}
      >
        {countriesListResult?.data.map(country => (
          <MenuItem
            key={country?.uuid}
            value={country?.uuid}
            className={localClasses.menuItem}
          >{`${country?.name}`}</MenuItem>
        ))}
      </SelectFormsy>
      <SelectFormsy
        id='nationality_country_label'
        name={`${baseNameProps}nationality_country`}
        value={formData.nationality_country}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalInformation.nationality',
        })}
        variant='outlined'
        className={localClasses.select}
        onChange={handleFormChange}
        required
        styles={{ width: '100%' }}
        validations={{
          ...baseFormsyValidations({
            customErrorMessage: error?.nationality_country,
          }),
        }}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
            customErrorMessage: error?.nationality_country,
          }),
        }}
      >
        {countriesListResult?.data.map(country => (
          <MenuItem
            key={country?.uuid}
            value={country?.uuid}
            className={localClasses.menuItem}
          >{`${country?.name}`}</MenuItem>
        ))}
      </SelectFormsy>
      <TextFieldFormsy
        name={`${baseNameProps}social_security_number`}
        type={'number'}
        value={formData.social_security_number}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalInformation.socialSecurityNumber',
        })}
        variant={'outlined'}
        required
        validations={{
          ...baseFormsyValidations({
            maxLength: socialSecurityNumberMaxLength,
            customErrorMessage: error?.social_security_number,
          }),
          hasMoreCharactersThan: socialSecurityNumberMaxLength,
        }}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
            maxLength: socialSecurityNumberMaxLength,
            customErrorMessage: error?.social_security_number,
          }),
          hasMoreCharactersThan: intl
            .formatMessage({
              id: 'common.form.has_more_characters_than',
            })
            .format(socialSecurityNumberMaxLength),
        }}
        onChange={handleFormChange}
      />
    </div>
  );
};

const LFSSPersonalInformationForm = Component;

export { LFSSPersonalInformationForm };
