import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { createTheme } from '@material-ui/core/styles';
import { Loader } from '@src/components';
import { RewardCard } from './components/RewardCard';
import { PointCommandIds } from '@spider:src/enums';
import { useGetRewardsListQuery } from '@async-calls/rewards';
import { Banner } from '@src/Spider/scenes/Home/components/UserHome/components/Banner';
import { useAuth } from '@src/auth';
import { ParticipantType } from '@src/Spider/enums/participantType';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1.5rem',
    marginRight: '1rem',
    paddingRight: '0.5rem',
  },
  cards: {
    display: 'grid',
    gap: '1.5rem',
    gridTemplateColumns: 'repeat(3, 1fr)',
    margin: 'auto',
    [createTheme().breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      marginRight: '1.25rem',
      marginLeft: '1.25rem',
    },
  },
  card: {
    display: 'flex',
    position: 'relative',
  },
};

const UserHome = ({ ...props }) => {
  const history = useHistory();
  const { hierarchyNodeUser } = useAuth();
  const { contract } = useParams();

  const { data, isFetching, isSuccess } = useGetRewardsListQuery({
    hierarchyNodeId: contract,
    allocatedOnly: true,
    distributor: 'Edenred',
  });

  const openConvertPointView = reward => {
    history.push(`/nodes/${contract}/conversion-points`, reward);
  };

  const isAllocationConfigurationEnable = reward => {
    if (hierarchyNodeUser?.type === ParticipantType.MORALE)
      return !!reward?._context?.allocation?.legal_entities_config;
    if (hierarchyNodeUser?.type === ParticipantType.PHYSIC) {
      return !!reward?._context?.allocation?.individuals_config;
    }
    return false;
  };

  const specialReward = useMemo(() => {
    if (!data?.results) return null;
    return (
      data.results.find(
        reward =>
          reward.uuid === PointCommandIds.KSC &&
          isAllocationConfigurationEnable(reward),
      ) ?? null
    );
  }, [data]);

  return (
    <div className={props.classes.root}>
      <Banner />
      {isSuccess && specialReward && (
        <RewardCard
          key={specialReward.uuid}
          reward={specialReward}
          convert={openConvertPointView}
        />
      )}
      {isFetching && <Loader centered />}
      {isSuccess && (
        <div className={props.classes.cards}>
          {data?.results.map(reward => {
            return reward.uuid !== PointCommandIds.KSC &&
              isAllocationConfigurationEnable(reward) ? (
              <RewardCard
                key={reward.uuid}
                reward={reward}
                convert={openConvertPointView}
              />
            ) : null;
          })}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(UserHome);
