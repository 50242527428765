import React from 'react';
import 'react-datasheet/lib/react-datasheet.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { I18nWrapper } from './components';
import {
  MainEvents,
  OneSignalInit,
} from './components/Common/components/MainLayout/components/MainLayout/components';
import * as serviceWorker from './serviceWorker';
import { configureAppStore } from './store/configureStore';
import './toastify_override.scss';
import { getDistributorConfig } from '@src/distributors/distributorManager';

const distributorConfig = getDistributorConfig();
if (!distributorConfig?.routes) {
  console.error('Failed to initialize distributor configuration:');
}
const store = configureAppStore(distributorConfig);

ReactDOM.render(
  <Provider store={store}>
    <I18nWrapper.I18nWrapper>
      <React.Fragment>
        <ToastContainer autoClose={10000} />
        <OneSignalInit />
        <MainEvents distributorConfig={distributorConfig}>
          {distributorConfig.routes}
        </MainEvents>
      </React.Fragment>
    </I18nWrapper.I18nWrapper>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
