import { useCallback, useState } from 'react';
import { SessionStorageManager } from '@src/data/session/SessionStorageManager';

export const useSessionStorage = (
  key,
  initialValue,
  { enableSessionStorage = true } = {},
) => {
  const [storedValue, setStoredValue] = useState(() => {
    const saved = enableSessionStorage
      ? SessionStorageManager.getItem(key)
      : null;
    const value = saved !== null ? saved : initialValue;
    if (value && enableSessionStorage) {
      SessionStorageManager.setItem(key, value);
    }
    return value;
  });

  /**
   * @param {{value: any, setInSessionStorage: boolean}} state
   * @param {Function | null} setStoredValueCallback
   */
  const setValue = useCallback(
    (state, setStoredValueCallback = null) => {
      try {
        setStoredValue(prevState => {
          const value =
            setStoredValueCallback instanceof Function
              ? setStoredValueCallback(state, prevState)
              : state.value;

          if (!enableSessionStorage) return;
          if (value && state.setInSessionStorage) {
            SessionStorageManager.setItem(key, value);
          } else if (state.setInSessionStorage) {
            SessionStorageManager.removeItem(key);
          }

          return value;
        });
      } catch (error) {
        console.error('Error updating session storage:', error);
      }
    },
    [enableSessionStorage, key],
  );

  /**
   * @param {boolean} sessionStorageOnly
   */
  const removeValue = useCallback(
    (sessionStorageOnly = false) => {
      if (enableSessionStorage) {
        SessionStorageManager.removeItem(key);
      }
      if (sessionStorageOnly) return;
      setStoredValue(initialValue);
    },
    [enableSessionStorage, key, initialValue],
  );

  return [storedValue, setValue, removeValue];
};
