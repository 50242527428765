import React from 'react';
import { InformationContentBlock } from '@src/Spider/scenes/ConvertPoints/components/components/SummaryComponents/InformationContentBlock';
import { useIntl } from 'react-intl';
import { InformationDetail } from '@src/Spider/scenes/ConvertPoints/components/components/SummaryComponents/InformationDetail';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { useAuth } from '@src/auth';

const InformationSummary = () => {
  const intl = useIntl();
  const convertPointsContext = useConvertPoints();
  const { hierarchyNodeUser } = useAuth();

  return (
    <>
      <InformationContentBlock
        title={intl.formatMessage({
          id: 'spider.convertPoints.stepper.summaryStep.information.identification.title',
        })}
        first={true}
        showDivider={true}
      >
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.information.identification.last_name',
          })}
          value={hierarchyNodeUser?.lastname}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.information.identification.first_name',
          })}
          value={hierarchyNodeUser?.firstname}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.information.identification.email',
          })}
          value={hierarchyNodeUser?.email}
        />
      </InformationContentBlock>
      <InformationContentBlock
        title={intl.formatMessage({
          id: 'spider.convertPoints.stepper.summaryStep.information.personal_information.title',
        })}
        showDivider={Boolean(
          convertPointsContext.informationStepData?.delivery_address ||
            hierarchyNodeUser?.legal_entity_address ||
            convertPointsContext.informationStepData?.personal_address,
        )}
      >
        {convertPointsContext.informationStepData?.birth_date && (
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.information.delivery_address.birth_date',
            })}
            isError={Boolean(convertPointsContext.error?.form?.birth_date)}
            value={convertPointsContext.informationStepData?.birth_date}
          />
        )}
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.information.delivery_address.phone_number',
          })}
          isError={Boolean(convertPointsContext.error?.form?.phone_number)}
          value={convertPointsContext.informationStepData?.phone_number}
        />
      </InformationContentBlock>
      {convertPointsContext.informationStepData?.personal_address && (
        <InformationContentBlock
          title={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.information.personal_address.title',
          })}
          showDivider={Boolean(
            convertPointsContext.informationStepData?.delivery_address ||
              hierarchyNodeUser?.legal_entity_address,
          )}
        >
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.information.personal_address.line1',
            })}
            isError={Boolean(
              convertPointsContext.error?.form?.personal_address?.line1,
            )}
            value={
              convertPointsContext.informationStepData.personal_address?.line1
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.information.personal_address.line2',
            })}
            isError={Boolean(
              convertPointsContext.error?.form?.personal_address?.line2,
            )}
            value={
              convertPointsContext.informationStepData.personal_address?.line2
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.information.personal_address.postal_code',
            })}
            isError={Boolean(
              convertPointsContext.error?.form?.personal_address?.postal_code,
            )}
            value={
              convertPointsContext.informationStepData.personal_address
                ?.postal_code
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.information.personal_address.city',
            })}
            isError={Boolean(
              convertPointsContext.error?.form?.personal_address?.city,
            )}
            value={
              convertPointsContext.informationStepData.personal_address?.city
            }
          />
        </InformationContentBlock>
      )}
      {(convertPointsContext.informationStepData?.delivery_address ||
        hierarchyNodeUser?.legal_entity_address) && (
        <InformationContentBlock
          title={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.information.delivery_address.title',
          })}
        >
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.information.personal_information.line1',
            })}
            isError={Boolean(
              convertPointsContext.error?.form?.delivery_address?.line1,
            )}
            value={
              convertPointsContext.informationStepData.delivery_address
                ?.line1 ?? hierarchyNodeUser?.legal_entity_address.line1
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.information.personal_information.line2',
            })}
            isError={Boolean(
              convertPointsContext.error?.form?.delivery_address?.line2,
            )}
            value={
              convertPointsContext.informationStepData.delivery_address
                ?.line2 ?? hierarchyNodeUser?.legal_entity_address.line2
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.information.personal_information.postal_code',
            })}
            isError={Boolean(
              convertPointsContext.error?.form?.delivery_address?.postal_code,
            )}
            value={
              convertPointsContext.informationStepData.delivery_address
                ?.postal_code ??
              hierarchyNodeUser?.legal_entity_address.postal_code
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.information.personal_information.city',
            })}
            isError={Boolean(
              convertPointsContext.error?.form?.delivery_address?.city,
            )}
            value={
              convertPointsContext.informationStepData.delivery_address?.city ??
              hierarchyNodeUser?.legal_entity_address.city
            }
          />
        </InformationContentBlock>
      )}
    </>
  );
};

export { InformationSummary };
