import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

export const useLinkOpener = () => {
  const intl = useIntl();

  const openLink = link => {
    if (!link || link?.length === 0) {
      toast.error(
        intl.formatMessage({ id: 'spider.common.url_not_configured' }),
      );
      return;
    }
    try {
      const url = new URL(link);
      if (!['http:', 'https:'].includes(url.protocol)) {
        throw new Error('Protocol not allowed');
      }
      window.open(link, '_blank');
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'spider.common.invalid_url' }));
    }
  };

  return { openLink };
};
