import React from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import {
  Button,
  
} from '../../../../../../components';
import warningImage from '@spider:src/assets/img/warning.png';

import { withStyles } from '@mui/styles';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    padding: '0.5rem',
  },

}

const UpdateMobileAppModal = () => {
  const userAgent = navigator.userAgent || window.opera;
  const isIos = /iPad|iPhone/.test(userAgent) && !window.MSStream
  const intl = useIntl();
  
  return (

    <Dialog
      open={true}
      PaperProps={{
        style: {
          borderRadius: '16px', // Change the border radius here
          padding: '0.5rem'
        },
      }}
    >
      <DialogContent
        style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', paddingRight:'5px', paddingLeft:'5px' }}
      >

        <img src={warningImage} style={{width: '4.5rem', margin: 'auto'}} />
        <div>
          <Typography
            variant={'h1'}
            component={'h1'}
            style={{ width: '100%', textAlign: 'center', fontSize: '2.5rem' }}
          >
            {intl.formatMessage({ id: 'app_update.title' })}
          </Typography>
          
        </div>

        <div>
          
          <Typography variant={'subtitle1'} style={{ textAlign: 'center', lineHeight: 1.5, marginBottom: 10 }}>
            {intl.formatMessage({ id: 'app_update.message' })}
          </Typography>
        </div>

        
      </DialogContent>

      
      <DialogActions>
        <Button
          size={'small'}
          color={'primary'}
          variant={'contained'}
          style={{width: '100%', backgroundColor: '#0F172A', borderRadius: '0.5rem'}}
          onClick={() => window.open(isIos ? process.env.REACT_APP_MOBILE_APP_STORE_URL : process.env.REACT_APP_MOBILE_PLAY_STORE_URL, '_blank')}
        >
          {intl.formatMessage({ id: 'app_update.submit' })}
        </Button>
      </DialogActions>
    </Dialog>

  );
};

export default withStyles(styles)(UpdateMobileAppModal);
