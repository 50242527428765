import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Formsy from 'formsy-react';
import {
  FormControl,
  FormControlLabel,
  InputAdornment,
  Switch,
  Typography,
} from '@material-ui/core';
import Button from '@spider:src/components/Button';
import useDirtyPristine from '@spider:src/hooks/UseDirtyPristine';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { TextFieldFormsy } from '@spider:components/FormsyComponents/TextFieldFormsy';
import { Loader } from '@src/components';
import {
  useGetContractDetailQuery,
  usePatchContractDetailMutation,
} from '@async-calls/contractDetails';
import { toast } from 'react-toastify';
import { useDecimalValue } from '@src/Spider/hooks/useDecimalValue';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  titleGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
  },
  pointsExpiration: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  pointsExpirationEnabled: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  conversionField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const PointsParameters = ({ ...props }) => {
  const intl = useIntl();
  const { contract } = useParams();
  const { dirtyChange, resetPristinePage } = useDirtyPristine();

  const { data, isFetching } = useGetContractDetailQuery({
    hierarchyNodeId: contract,
  });

  const [patchContractDetails, mutateResult] = usePatchContractDetailMutation({
    hierarchyNodeId: contract,
  });

  const [pointsExpirationEnabled, setPointsExpirationEnabled] = useState(
    data?.additional_points_validity_months !== null,
  );
  const [pointExpirationValue, setPointExpirationValue] = useState(
    data?.additional_points_validity_months ?? '',
  );

  const [
    pointConversionValue,
    savablePointConversionValue,
    setPointConversionValue,
  ] = useDecimalValue(data?.point_value ?? '', 3);

  const checkPointExpiration = event => {
    dirtyChange();
    setPointsExpirationEnabled(event.target.checked);
  };

  const conversionValueChange = e => {
    e.preventDefault();
    dirtyChange();
    setPointConversionValue(e.target.value);
  };

  const pointExpirationValueChange = e => {
    dirtyChange();
    setPointExpirationValue(e.target.value);
  };

  const savePlatformContractDetails = async () => {
    resetPristinePage();

    try {
      await patchContractDetails({
        hierarchyNodeId: contract,
        contractDetail: {
          additional_points_validity_months: pointsExpirationEnabled
            ? pointExpirationValue
            : null,
          point_value: savablePointConversionValue,
        },
      }).unwrap();
      toast.success(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.points.update.success',
        }),
      );
    } catch (error) {
      console.error('Failed to update code:', error);
      toast.error(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.points.update.error',
        }),
      );
    }
  };

  useEffect(() => {
    setPointsExpirationEnabled(
      data?.additional_points_validity_months !== null,
    );
    setPointExpirationValue(data?.additional_points_validity_months ?? '');
    setPointConversionValue(data?.point_value ?? '');
  }, [data]);

  return isFetching || patchContractDetails.isFetching ? (
    <Loader centered />
  ) : (
    <Formsy
      name={'parameter-contract-details'}
      onValidSubmit={savePlatformContractDetails}
      className={props.classes.root}
    >
      <div className={props.classes.titleGroup}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.points.title',
          })}
        </Typography>
      </div>

      <div className={props.classes.conversionField}>
        <Typography
          variant={'subtitle1'}
          style={{ fontWeight: 'bold', width: '4.2rem' }}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.points.preInputLabel',
          })}
        </Typography>

        <TextFieldFormsy
          name={'conversionValue'}
          value={pointConversionValue}
          label={intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.points.conversionValueLabel',
          })}
          variant={'outlined'}
          required
          style={{ width: '50%' }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>€</InputAdornment>,
          }}
          inputProps={{
            min: 0,
          }}
          validations={{
            isNumeric: true,
            isPositive: true,
          }}
          validationErrors={{
            isDefaultRequiredValue: intl.formatMessage({
              id: 'common.form.required_error',
            }),
            isNumeric: intl.formatMessage({
              id: 'common.form.numeric_error',
            }),
            isPositive: intl.formatMessage({
              id: 'common.form.is_more_than_or_equals_0_error',
            }),
          }}
          onChange={conversionValueChange}
        />
      </div>

      <div className={props.classes.pointsExpiration}>
        <FormControl style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            checked={pointsExpirationEnabled}
            control={<Switch color={'primary'} />}
            labelPlacement='start'
            label={intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.parameters.points.pointExpirationEnabledLabel',
            })}
            style={{ margin: 0 }}
            onChange={checkPointExpiration}
          />
        </FormControl>

        {pointsExpirationEnabled && (
          <div className={props.classes.pointsExpirationEnabled}>
            <div>
              <Typography variant={'body2'}>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.points.pointExpirationEnabledInfo',
                })}
              </Typography>
              <Typography variant={'body2'}>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.points.exampleLabel',
                })}
              </Typography>
              <Typography variant={'body2'}>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.points.exampleOneLabel',
                })}
              </Typography>
              <Typography variant={'body2'}>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.points.exampleTwoLabel',
                })}
              </Typography>
              <Typography variant={'body2'}>
                {intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.parameters.points.exampleThreeLabel',
                })}
              </Typography>
            </div>

            <TextFieldFormsy
              name={'pointExpiration'}
              type={'number'}
              value={pointExpirationValue}
              label={intl.formatMessage({
                id: 'spider.hierarchy_node.platform_customization.parameters.points.pointExpirationValueLabel',
              })}
              variant={'outlined'}
              required
              style={{ width: '50%' }}
              inputProps={{
                min: 0,
              }}
              validations={{
                isInt: true,
              }}
              validationErrors={{
                isDefaultRequiredValue: intl.formatMessage({
                  id: 'common.form.required_error',
                }),
                isInt: intl.formatMessage({
                  id: 'common.form.is_int_error',
                }),
              }}
              onChange={pointExpirationValueChange}
            />
          </div>
        )}
      </div>

      <div className={props.classes.actions}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'small'}
          type={'submit'}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.save_button_label',
          })}
        </Button>
      </div>
    </Formsy>
  );
};

export default withStyles(styles)(PointsParameters);
