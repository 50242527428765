import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { Button } from '@spider:src/components';
import ImportDialog from '@spider:src/components/ImportDialog/ImportDialog';
import { ImportLogsHistory } from '@spider:src/components/importLogsHistory';
import { ImportLogsTypes } from '@spider:src/enums/importLogsTypes';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { TitleContainer } from '@spider:src/components/TitleContainer';
import { ParticipantsList } from '@spider:src/components/ParticipantsList';
import { PointsAdminUrl } from '@spider:src/enums/pointsAdminUrl';
import { ParticipantsAdminUrl } from '@spider:src/enums/participantsAdminUrl';
import { TabRoutes } from '@spider:src/components/TabRoutes';
import { UserRoles } from '@src/Spider/enums';

const styles = {
  pointsBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingRight: '0.5rem',
    marginRight: '0.5rem',
  },
};

const AdminParticipants = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const match = useRouteMatch();
  const { contract } = useParams();
  const [openImport, setOpenImport] = useState(false);

  const tabPaths = Object.values(ParticipantsAdminUrl);

  const importPoints = () => {
    setOpenImport(true);
  };

  const redirect = options => {
    if (!options?.type) return;
    if (
      options.type === ImportLogsTypes.POINTS &&
      history.location.pathname.includes('users')
    ) {
      history.push(`/nodes/${contract}/points/${PointsAdminUrl.HISTORY}`);
    }
  };

  const routes = [
    {
      path: ParticipantsAdminUrl.LIST,
      component: (
        <ParticipantsList
          queryParams={{
            id: contract,
            types: [
              UserRoles.SUPER_MANAGER,
              UserRoles.MANAGER,
              UserRoles.COLLABORATOR,
            ],
            include_point_balance: true,
            include_manager: true,
            include_approval: true,
            include_children: true,
          }}
        />
      ),
    },
    {
      path: ParticipantsAdminUrl.HISTORY,
      component: (
        <ImportLogsHistory
          fixedCacheKey={'participants'}
          queryParams={{
            hierarchyNodeId: contract,
            types: [ImportLogsTypes.PARTICIPANTS],
          }}
          emptyLabelParams={intl.formatMessage({
            id: 'spider.importLog.dataTable.no_data_participants',
          })}
        />
      ),
    },
  ];

  return (
    <div className={props.classes.pointsBody}>
      <TitleContainer>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({ id: 'spider.participants.title' })}
        </Typography>
        <Button color={'primary'} variant={'outlined'} onClick={importPoints}>
          {intl.formatMessage({ id: 'spider.participants.import' })}
        </Button>
      </TitleContainer>

      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '1.5rem',
        }}
      >
        <TabRoutes
          basePath={match.path}
          baseUrl={match.url}
          tabPaths={tabPaths}
          tabNamespace='spider.participants.tabs'
          routes={routes}
          defaultTab={ParticipantsAdminUrl.LIST}
        />
      </Box>
      <ImportDialog
        open={openImport}
        setOpen={setOpenImport}
        onFileCreated={redirect}
        defaultTab={'2'}
        fixedCacheKey={'participant'}
      />
    </div>
  );
};

export default withStyles(styles)(AdminParticipants);
