import React from 'react';
import { DialogContent, Drawer, Paper, Typography } from '@material-ui/core';
import { DialogIndicator } from '@spider:src/components/DialogIndicator';
import { Button } from '@spider:src/components';
import { neutralColors } from '@spider:src/themes';
import { createTheme } from '@material-ui/core/styles';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { useMediaQuery } from '@mui/material';
import { CloseButtonIcon } from '@spider:src/components/CloseButtonIcon';

const styles = {
  dialogTitle: {
    display: 'flex !important',
    flexDirection: 'column !important',
    padding: '1rem !important',
    width: '100%',
  },
  closeTitleDialog: {
    display: 'flex !important',
    width: '100%',
    flexDirection: 'row-reverse !important',
    padding: '0rem 1rem 0rem 0rem !important',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '1rem',
    overflow: 'auto',
    backgroundColor: neutralColors.neutralBg,
    [createTheme().breakpoints.down('md')]: {
      backgroundColor: neutralColors.neutralWhite,
      padding: '0rem 2rem 2rem 2rem !important',
    },
  },
  dialogPaperContent: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '1rem',
    marginRight: 'auto',
    marginLeft: 'auto',
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
    width: '928px',
    gap: '2rem',
    [createTheme().breakpoints.down('sm')]: {
      width: '100%',
      gap: '1rem',
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      borderTopLeftRadius: '0.75rem',
      borderTopRightRadius: '0.75rem',
      height: '100vh',
      border: '4px solid',
      borderBottom: 'none',
      borderColor: neutralColors.neutral300,
      [createTheme().breakpoints.down('sm')]: {
        border: 'none',
        height: '90vh',
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    minHeight: '48px',
    [createTheme().breakpoints.down('sm')]: {
      minHeight: '6px',
    },
  },
};

const RewardHelperAboutDialog = ({
  children,
  title,
  open,
  onClose,
  onConvert,
  disableConvert,
  ...props
}) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('sm'));

  const ConvertButton = () => (
    <div>
      <Button
        color='primary'
        variant='contained'
        onClick={onConvert}
        disabled={disableConvert}
      >
        {intl.formatMessage({
          id: 'spider.convertPoints.home.rewardsMain.convertAction',
        })}
      </Button>
    </div>
  );

  return (
    <Drawer
      anchor='bottom'
      open={open}
      onClose={onClose}
      className={props.classes.drawer}
    >
      <DialogContent className={props.classes.dialogContent}>
        <div className={props.classes.header} onClick={onClose}>
          <DialogIndicator />
        </div>
        {!isMobile && (
          <div className={props.classes.closeTitleDialog}>
            <CloseButtonIcon onClick={onClose} />
          </div>
        )}
        <div className={props.classes.dialogTitle}>
          <Typography
            variant='h1'
            component='h1'
            className='title underline-center'
          >
            {title}
          </Typography>
        </div>

        {isMobile && <ConvertButton />}

        {Boolean(children) && (
          <Paper className={props.classes.dialogPaperContent}>{children}</Paper>
        )}

        {!isMobile && <ConvertButton />}
      </DialogContent>
    </Drawer>
  );
};

export default withStyles(styles)(RewardHelperAboutDialog);
