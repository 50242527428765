import { useEffect, useState } from 'react';
/**
 * Custom hook that updates the favicon based on the hostname
 *
 * @param {import('@src/distributors/distributorConfig').DistributorConfig} [distributorConfig]
 * @returns {[string, function]} - Current favicon path and setter function
 */
const useFavicon = distributorConfig => {
  const [favicon, setFavicon] = useState(
    process.env.PUBLIC_URL + (distributorConfig.favicon || '/favicon.svg'),
  );

  useEffect(() => {
    const existingFavicon = document.querySelector("link[rel='icon']");
    if (existingFavicon) {
      document.head.removeChild(existingFavicon);
    }

    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = favicon;
    link.type = 'image/svg+xml';
    document.head.appendChild(link);

    // Cleanup on unmount
    return () => {
      const cleanup = document.querySelector("link[rel='icon']");
      if (cleanup) {
        document.head.removeChild(cleanup);
      }
    };
  }, [favicon]);

  return [favicon, setFavicon];
};

export default useFavicon;
