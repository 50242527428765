import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { RowsPerPageSelector } from '../RowsPerPageSelector';
import { systemColors } from '@spider:src/themes';
import { ImportLogsStatuses } from '../../enums/importLogsStatuses';
import { Button } from '../index';
import { Import, NoteRemove } from 'iconsax-react';
import Pagination from '@material-ui/lab/Pagination';
import { useIntl } from 'react-intl';
import useImportLogFile from '@spider:src/hooks/UseImportLogFile';
import usePagination from '@spider:src/hooks/UsePagination';
import HistoryNoteEmptyData from '@spider:src/assets/img/empty-import-logs-note.png';
import { Loader } from '@src/components';
import { EmptyTableView } from '../EmptyTableView';
import { useLazyGetImportLogsListQuery } from '@async-calls/importLogs';
/** @typedef {import('@async-calls/importLogs').ListImportParams} ListImportParams */

const styles = {
  root: {
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
  },
  emptyData: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '1rem',
  },
  actions: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
  },
};

/**
*
* @param {{
emptyLabelParams: any,
queryParams: ListImportParams
}} props
* @returns
*/
const ImportLogsHistory = ({ emptyLabelParams, queryParams, ...props }) => {
  const intl = useIntl();

  const [fetch, { data, isSuccess, isFetching }] =
    useLazyGetImportLogsListQuery();
  const { getChipsLabel, getChipsStatus } = useImportLogFile();

  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = usePagination({
    dataLength: data?.count ? data?.count : 0,
  });

  const sourceWithFilename = file => {
    if (!file?.src || !file?.filename) return null;
    const filenameSplit = file?.filename.split('/');
    const filename =
      filenameSplit?.length > 0
        ? filenameSplit[filenameSplit.length - 1]
        : file?.filename;

    const csvSplit = file.src.toString().split('.csv');
    const firstPartSlashSplit = csvSplit[0].split('/');
    firstPartSlashSplit[firstPartSlashSplit.length - 1] = filename;

    return `${firstPartSlashSplit.join('/')}${csvSplit[1]}`;
  };

  useEffect(() => {
    fetch({
      ...queryParams,
      pagination,
    });
  }, [pagination.page, pagination.rowsPerPage, queryParams]);

  const columns = [
    {
      field: 'date',
      label: intl.formatMessage({
        id: 'spider.common.history.table.date',
      }),
    },
    {
      field: 'name',
      label: intl.formatMessage({
        id: 'spider.common.history.table.name',
      }),
    },
    {
      field: 'lines',
      label: intl.formatMessage({
        id: 'spider.common.history.table.lines',
      }),
    },
    {
      field: 'status',
      label: intl.formatMessage({
        id: 'spider.common.history.table.status',
      }),
    },
    {
      field: 'action',
      label: '',
    },
  ];

  return (
    <Paper className={props.classes.root}>
      <RowsPerPageSelector
        rowsPerPage={pagination.rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {(isFetching || data?.count > 0) &&
                columns.map((column, index) => {
                  return <TableCell key={index}>{column.label}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <Loader centered />
                </TableCell>
              </TableRow>
            ) : isSuccess && data?.results?.length > 0 ? (
              data?.results.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>
                    <Typography>
                      {row.created_at
                        .toDate()
                        .toLocaleString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })
                        .replaceAll('/', '.')}
                    </Typography>
                    <Typography variant='h6'>
                      {row.created_at.toDate().toLocaleString('fr-FR', {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {row.input_file.filename.replace('.csv', '')}
                    </Typography>
                    <Typography variant='h6'>
                      {row.origin === 'AUTOMATIC'
                        ? intl.formatMessage({
                            id: 'spider.common.history.table.automatic_import',
                          })
                        : row.creator
                          ? `${row.creator.firstname} ${row.creator.lastname}`
                          : ''}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <div>
                      {row.nbTotalLines}
                      <br />
                      {row.linesError > 0 ? (
                        <div
                          style={{
                            fontSize: '10px',
                            color: systemColors.errorDark,
                          }}
                        >{`dont ${row.nbFailedLines} en erreur`}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={getChipsLabel(row.status)}
                      className={getChipsStatus(row.status)}
                    />
                  </TableCell>
                  <TableCell>
                    <Box className={props.classes.actions}>
                      {[ImportLogsStatuses.FAILED].includes(row.status) &&
                        Boolean(row.output_file) && (
                          <Button
                            component='a'
                            variant='text'
                            color='primary'
                            size='small'
                            href={sourceWithFilename(row.output_file)}
                          >
                            <NoteRemove
                              color={systemColors.errorRegular}
                              size={24}
                            />
                          </Button>
                        )}
                      {[
                        ImportLogsStatuses.FINISHED,
                        ImportLogsStatuses.FAILED,
                      ].includes(row.status) &&
                        Boolean(row.input_file) && (
                          <Button
                            component='a'
                            variant='text'
                            color='primary'
                            size='small'
                            href={sourceWithFilename(row.input_file)}
                          >
                            <Import size={24} />
                          </Button>
                        )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>
                  <EmptyTableView
                    imageSrc={HistoryNoteEmptyData}
                    imageAlt='Aucun import'
                    title='spider.importLog.dataTable.no_data_available_title'
                    description='spider.importLog.dataTable.no_data_available_description'
                    emptyLabelParams={emptyLabelParams}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={paginationCount}
        page={pagination.page}
        onChange={handleChangePage}
        shape='rounded'
      />
    </Paper>
  );
};

export default withStyles(styles)(ImportLogsHistory);
