import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import { Button } from '../../index';
import { RequirementHelp } from '../../RequirementHelp';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as LampOnIcon } from '@spider:src/assets/img/lamp-on-icon.svg';
import { DocumentDownload } from 'iconsax-react';
import FileInputDragAndDrop from '../../FileInputDragAndDrop/FileInputDragAndDrop';
import { createTheme } from '@material-ui/core/styles';
import { neutralColors as neutralsColors } from '../../../themes';
import { ImportLogsTypes } from '@src/Spider/enums/importLogsTypes';
import { getIntlEnumLabel } from '@src/Spider/enums/utils';
import useFileDownload from '@src/Spider/hooks/UseFileDownload';

const styles = {
  importContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  importContentHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
    [createTheme().breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  importContentSubtitle: {
    textAlign: 'left',
    [createTheme().breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  accordion: {
    '&:before': {
      borderColor: 'transparent',
      display: 'none !important',
    },
  },
  accordionSummary: {
    padding: '1.5rem !important',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
    },
  },
};

/**
 * @typedef {import('@src/Spider/enums/importLogsTypes').ImportLogsTypes} ImportLogsTypes
 */

/**
 * @typedef {Object} ImportContentProps
 * @property {ImportLogsTypes} importLogType - Type of importContent
 * @property {(files: Object[], importLogType: ImportLogsTypes) => void} validateImport - Callback after validating file
 */

/**
 * Import file content component for ImportLogsTypes
 * @param {ImportContentProps} props
 * @returns {JSX.Element}
 */
const Component = ({ importLogType, validateImport, classes }) => {
  const intl = useIntl();
  const { downloadBlob } = useFileDownload();

  const [helpExpanded, setHelpExpanded] = useState(
    importLogType !== ImportLogsTypes.USERS,
  );
  const [files, setFiles] = useState([]);

  const minSize = 0;
  const maxSize = 10;
  const multiple = false;
  const authorizedFileTypes = ['CSV'];

  const clearState = () => {
    setFiles([]);
  };

  const fileChanged = files => {
    clearState();

    setHelpExpanded(false);
    if (!files || files.find(file => !file) !== undefined) return;

    setFiles(files);
  };

  const downloadPattern = () => {
    let file;
    let filename;
    switch (importLogType) {
      case ImportLogsTypes.USERS:
        file = new Blob(
          [
            "TYPE;MATRICULE;NOM;PRENOM;EMAIL;RESPONSABLE N+1;STATUT D'INSCRIPTION;RAISON SOCIALE;ADRESSE 1;ADRESSE 2;CODE POSTAL;VILLE\r\n",
          ],
          {
            type: 'text/csv',
          },
        );
        filename = 'participant-modele.csv';
        break;
      case ImportLogsTypes.POINTS:
        file = new Blob(['MATRICULE;POINTS;COMMENTAIRE\r\n'], {
          type: 'text/csv',
        });
        filename = 'points-modele.csv';
        break;
      default:
        file = new Blob([''], {
          type: 'text/csv',
        });
        filename = 'empty-model.csv';
        break;
    }
    if (!file || !filename) return;
    downloadBlob(file, filename);
  };

  const helpAlertInformation = () => {
    switch (importLogType) {
      case ImportLogsTypes.USERS:
        return [
          intl.formatMessage({
            id: 'spider.participants.helpImport.helpInfo.waitedFormat',
          }),
          intl.formatMessage({
            id: 'spider.participants.helpImport.helpInfo.orderFormat',
          }),
          intl.formatMessage({
            id: 'spider.participants.helpImport.helpInfo.optionalInfo',
          }),
        ];
      case ImportLogsTypes.POINTS:
        return [
          intl.formatMessage({
            id: 'spider.points.helpImport.helpInfo.waitedFormat',
          }),
          intl.formatMessage({
            id: 'spider.points.helpImport.helpInfo.orderFormat',
          }),
          intl.formatMessage({
            id: 'spider.points.helpImport.helpInfo.optionalInfo',
          }),
        ];
      default:
        return [];
    }
  };

  const requirements = () => {
    switch (importLogType) {
      case ImportLogsTypes.USERS:
        return [
          {
            title: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.type.title',
            }),
            description: intl.formatMessage(
              {
                id: 'spider.participants.helpImport.requirements.type.description',
              },
              { b: chunks => <strong>{chunks}</strong> },
            ),
            required: true,
          },
          {
            title: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.number.title',
            }),
            description: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.number.description',
            }),
            required: true,
          },
          {
            title: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.lastName.title',
            }),
            description: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.lastName.description',
            }),
            required: true,
            category: null,
          },
          {
            title: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.name.title',
            }),
            description: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.name.description',
            }),
            required: true,
            category: null,
          },
          {
            title: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.email.title',
            }),
            description: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.email.description',
            }),
            required: true,
            category: null,
          },
          {
            title: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.senior.title',
            }),
            description: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.senior.description',
            }),
            required: false,
            category: null,
          },
          {
            title: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.inscriptionStatus.title',
            }),
            description: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.inscriptionStatus.description',
            }),
            required: true,
            category: null,
          },
          {
            title: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.socialRaison.title',
            }),
            description: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.socialRaison.description',
            }),
            required: true,
            category: intl.formatMessage({
              id: 'spider.participants.helpImport.category.moralePerson',
            }),
          },
          {
            title: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.primaryAddress.title',
            }),
            description: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.primaryAddress.description',
            }),
            required: true,
            category: intl.formatMessage({
              id: 'spider.participants.helpImport.category.moralePerson',
            }),
          },
          {
            title: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.secondaryAddress.title',
            }),
            description: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.secondaryAddress.description',
            }),
            required: false,
            category: intl.formatMessage({
              id: 'spider.participants.helpImport.category.moralePerson',
            }),
          },
          {
            title: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.postalCode.title',
            }),
            description: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.postalCode.description',
            }),
            required: true,
            category: intl.formatMessage({
              id: 'spider.participants.helpImport.category.moralePerson',
            }),
          },
          {
            title: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.city.title',
            }),
            description: intl.formatMessage({
              id: 'spider.participants.helpImport.requirements.city.description',
            }),
            required: true,
            category: intl.formatMessage({
              id: 'spider.participants.helpImport.category.moralePerson',
            }),
          },
        ];
      case ImportLogsTypes.POINTS:
        return [
          {
            title: intl.formatMessage({
              id: 'spider.points.helpImport.requirements.number.title',
            }),
            description: intl.formatMessage({
              id: 'spider.points.helpImport.requirements.number.description',
            }),
            required: true,
          },
          {
            title: intl.formatMessage({
              id: 'spider.points.helpImport.requirements.points.title',
            }),
            description: intl.formatMessage({
              id: 'spider.points.helpImport.requirements.points.description',
            }),
            required: true,
            category: null,
          },
          {
            title: intl.formatMessage({
              id: 'spider.points.helpImport.requirements.comment.title',
            }),
            description: intl.formatMessage({
              id: 'spider.points.helpImport.requirements.comment.description',
            }),
            required: false,
            category: null,
          },
        ];
      default:
        return [];
    }
  };

  if (
    importLogType !== ImportLogsTypes.USERS &&
    importLogType !== ImportLogsTypes.POINTS
  )
    return <></>;

  return (
    <div className={classes.importContent}>
      <div className={classes.importContentHeader}>
        <Typography variant={'h3'} component={'h3'}>
          {intl.formatMessage({
            id: getIntlEnumLabel(
              'import_log_content_type_title',
              importLogType,
            ),
          })}
        </Typography>

        <Button
          color={'primary'}
          variant={'outlined'}
          size={'small'}
          onClick={downloadPattern}
        >
          <DocumentDownload />
          {intl.formatMessage({ id: 'spider.importDialog.download' })}
        </Button>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {importLogType === ImportLogsTypes.USERS && (
          <Typography
            variant={'h4'}
            component={'h4'}
            style={{ textAlign: 'left', fontWeight: 'normal' }}
          >
            {intl.formatMessage({
              id: getIntlEnumLabel(
                'import_log_content_type_subtitle',
                importLogType,
              ),
            })}
          </Typography>
        )}
        {importLogType === ImportLogsTypes.POINTS && (
          <div className={classes.importContentSubtitle}>
            <Typography
              variant={'h4'}
              component={'h4'}
              style={{ textAlign: 'left', fontWeight: 'normal' }}
            >
              {' '}
              {intl.formatMessage({
                id: getIntlEnumLabel(
                  'import_log_content_type_subtitle',
                  importLogType,
                ),
              })}
            </Typography>
          </div>
        )}
        <FileInputDragAndDrop
          minSize={minSize}
          maxSize={maxSize}
          multiple={multiple}
          authorizedFileTypes={authorizedFileTypes}
          customInfo={intl.formatMessage({
            id: 'spider.importDialog.importInformation',
          })}
          customError={intl.formatMessage({
            id: 'spider.importDialog.importError',
          })}
          fileOrFilesChanged={fileChanged}
        />
      </Box>
      <Accordion
        expanded={helpExpanded}
        style={{
          borderRadius: '1rem',
          border: `2px solid ${neutralsColors.neutral450}`,
          boxShadow: 'none',
        }}
        className={classes.accordion}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: neutralsColors.neutral900 }} />
          }
          className={classes.accordionSummary}
          aria-controls='panel1a-content'
          id='panel1a-header'
          onClick={() => setHelpExpanded(!helpExpanded)}
        >
          <LampOnIcon fill={{ color: neutralsColors.neutral900 }} />
          <Typography variant={'h3'} component={'h3'}>
            {intl.formatMessage({ id: 'spider.importDialog.help' })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RequirementHelp
            helpAlertInformation={helpAlertInformation()}
            requirements={requirements()}
          />
        </AccordionDetails>
      </Accordion>

      {importLogType === ImportLogsTypes.USERS && (
        <Typography
          variant={'body2'}
          style={{ textAlign: 'left', color: neutralsColors.neutral500 }}
        >
          {intl.formatMessage({
            id: 'spider.participants.import_log.import_information',
          })}
        </Typography>
      )}

      {files && files.length > 0 && (
        <Button
          color={'primary'}
          variant={'contained'}
          size={'small'}
          style={{ width: 'fit-content', margin: 'auto' }}
          onClick={() =>
            validateImport(
              files,
              importLogType === ImportLogsTypes.USERS
                ? ImportLogsTypes.PARTICIPANTS
                : importLogType,
            )
          }
        >
          {intl.formatMessage({ id: 'common.submit' })}
        </Button>
      )}
    </div>
  );
};

const ImportContent = withStyles(styles)(Component);

export { ImportContent };
