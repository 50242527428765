import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { neutralColors } from '@spider:src/themes';
import { Link, Typography } from '@material-ui/core';
import { PointPriceRatio } from '../PointPriceRatio';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@material-ui/core/styles';
import { ReactComponent as PlusIndicator } from '@spider:src/assets/img/homeParticipantsConvert/plus-indicator.svg';
import { Button } from '@spider:src/components';
import { useIntl } from 'react-intl';
import HomeConvertPointPP from '@spider:src/config/home-convert-point-pp.json';
import HomeConvertPointPM from '@spider:src/config/home-convert-point-pm.json';
import UseRewardConvertCard from '@spider:src/hooks/UseRewardConvertCard';
import { Loader } from '@src/components';
import UseLinkFileToUserType from '@spider:src/hooks/UseLinkFileToUserType';
import { ThemeContentDialog } from '../ThemeContentDialog';
import { ThemeCard } from '../ThemeCard';
import { RewardHelperAboutDialog } from '@src/Spider/scenes/Home/components/UserHome/components/RewardHelperAboutDialog';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { useRewardHelper } from '@src/Spider/hooks/useRewardHelper';
import { useRewardHelperTitle } from '@src/Spider/hooks/useRewardHelperTitle';

const styles = {
  root: {
    display: 'flex',
    paddingBottom: '2rem',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '1rem',
    background: neutralColors.neutralWhite,
    boxShadow: '0px 6px 10px 2px rgba(10, 31, 94, 0.12)',
  },
  rootHeader: {
    display: 'flex',
    padding: '2rem 0rem 6rem 0rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2.5rem',
    alignSelf: 'stretch',
    position: 'relative',
    overflow: 'clip',
    borderRadius: '1rem',
    background: '#FFFAEE',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    justifyContent: 'center',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    zIndex: 5,
    boxShadow: '#FFFAEE 0px 0px 2.5rem 5.25rem',
    backgroundColor: '#FFFAEE',
    textAlign: 'center',
    padding: '0rem 1rem',
    margin: 'auto',
    width: 'max-content',
    minWidth: '17rem',
    alignItems: 'center',
    borderRadius: '1rem',
    [createTheme().breakpoints.down('md')]: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  sphere: {
    width: '27.25rem',
    height: '26.0625rem',
    position: 'absolute',
    left: '-8rem',
    top: '-4.3125rem',
    borderRadius: '27.25rem',
    opacity: 0.3,
    filter: 'blur(100px)',
    background: 'linear-gradient(135deg, #FAAF0C 0%, #FFDC60 100%)',
  },
  blurryZones: {
    width: '8.3125rem',
    height: '10.5rem',
    flexShrink: 0,
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFFAEE 69.92%)',
  },
  logoLists: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    position: 'absolute',
  },
  logos: {
    display: 'flex',
    gap: '0.5rem',
    flexDirection: 'row',
    alignItems: 'flex-start',
    zIndex: 3,
  },
  logo: {
    display: 'flex',
    width: '5rem',
    height: '5rem',
    borderRadius: '5rem',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    backgroundColor: neutralColors.neutralWhite,
  },
  themes: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1',
    gap: '1rem',
  },
  themesCard: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-2rem',
    gap: '2.5rem',
  },
  snackInfo: {
    display: 'flex',
    padding: '0.25rem 0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    borderRadius: '0.5rem',
    border: '1px solid #FAAF0C',
    background: '#FFF',
    boxShadow: '-2px 2px 0px 0px #FAAF0C',
    width: 'fit-content',
  },
  pointPriceRatio: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  rewardIntroduction: {
    zIndex: 5,
    width: '60%',
    textAlign: 'center',
    margin: 'auto',
    gap: '0.5rem',
  },
  rewardSubIntroduction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    justifyContent: 'center',
  },
  rewardSubIntroductionIcon: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '0.5rem',
  },
};

const MainRewardConvertCard = ({ reward, convert, ...props }) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('md'));

  const HelperAbout = useRewardHelper(reward);
  const helperDialogTitle = useRewardHelperTitle(reward);
  const { simulationResult, defaultPoint, disableConvert } =
    UseRewardConvertCard(reward);
  const { linkObject } = UseLinkFileToUserType({
    moraleObj: HomeConvertPointPM,
    physicObj: HomeConvertPointPP,
  });

  const [isOpenThemeDetails, setIsOpenThemeDetails] = useState(false);
  const [currentTheme, setCurrentTheme] = useState(null);
  const { open, onOpen, onClose } = useDisclosure();

  const openThemeDetails = theme => {
    setCurrentTheme(theme);
    setIsOpenThemeDetails(true);
  };

  const LogoList = ({ logoList, styleOvv }) => {
    return (
      <div className={props.classes.logos} style={styleOvv}>
        {logoList.map((logo, index) => {
          return (
            <div key={index} className={props.classes.logo}>
              {logo && logo.length > 0 && (
                <img src={logo} alt='logo' style={{ zIndex: 2 }} />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const HeadContent = () => {
    return (
      <div className={props.classes.headerContent}>
        <div className={props.classes.snackInfo}>
          <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
            {intl.formatMessage({
              id: 'spider.convertPoints.home.rewardsMain.designChipLabel',
            })}
          </Typography>
        </div>

        <div className={props.classes.pointPriceRatio}>
          <Typography
            variant={isMobile ? 'h3' : 'h2'}
            component={isMobile ? 'h3' : 'h2'}
            style={{ zIndex: 4, width: '15rem', margin: 'auto' }}
          >
            {intl.formatMessage({
              id: 'spider.convertPoints.home.ksc.title',
            })}
          </Typography>

          {simulationResult.isFetching ? (
            <Loader centered />
          ) : simulationResult.isSuccess ? (
            <PointPriceRatio
              points={simulationResult.data.max_points_usable}
              showInfo={false}
              pointsPrice={simulationResult.data.order_suggested.monetary_value}
            />
          ) : (
            <PointPriceRatio points={defaultPoint} pointsPrice={0} />
          )}
        </div>
      </div>
    );
  };

  const ConvertButton = () => (
    <Button
      color={'primary'}
      variant={'contained'}
      size={isMobile ? '' : 'large'}
      style={{ zIndex: 2, margin: 'auto' }}
      onClick={() => convert(reward)}
      disabled={disableConvert}
    >
      {intl.formatMessage({
        id: 'spider.convertPoints.home.rewardsMain.convertAction',
      })}
    </Button>
  );

  const LinkButton = () => (
    <Link className={'bold'} onClick={onOpen}>
      {intl.formatMessage({
        id: 'spider.convertPoints.home.rewardsCard.help2',
      })}
    </Link>
  );

  return (
    <div className={props.classes.root}>
      <div className={props.classes.rootHeader}>
        <div className={props.classes.header}>
          <div className={props.classes.logoLists}>
            {!isMobile && (
              <LogoList logoList={linkObject.firstLineLogos} styleOvv={{}} />
            )}
            {!isMobile && (
              <LogoList
                logoList={linkObject.secondLineLogos}
                styleOvv={{ marginLeft: '-2.75rem' }}
              />
            )}
          </div>
          <HeadContent />
        </div>

        {isMobile && <ConvertButton />}

        <div className={props.classes.rewardIntroduction}>
          <Typography variant={'body1'}>
            {intl.formatMessage({ id: 'spider.convertPoints.home.ksc.intro' })}
          </Typography>

          <div className={props.classes.rewardSubIntroduction}>
            <div className={props.classes.rewardSubIntroductionIcon}>
              <PlusIndicator />
            </div>
            <Typography variant={'body1'}>
              {intl.formatMessage({
                id: 'spider.convertPoints.home.ksc.introSupp',
              })}
            </Typography>
          </div>

          {isMobile && <LinkButton />}
        </div>

        <div style={{ zIndex: 1 }} className={props.classes.sphere} />
      </div>

      <div className={props.classes.themesCard}>
        {linkObject && linkObject.themes && (
          <div className={props.classes.themes}>
            {linkObject.themes.map((theme, index) => {
              return (
                <ThemeCard
                  key={index}
                  theme={theme}
                  onClick={() => openThemeDetails(theme)}
                />
              );
            })}
          </div>
        )}

        {!isMobile && (
          <div className={props.classes.actions}>
            <ConvertButton />
            <LinkButton />
          </div>
        )}
      </div>

      {currentTheme && (
        <ThemeContentDialog
          theme={currentTheme}
          setOpen={setIsOpenThemeDetails}
          open={isOpenThemeDetails}
          onConvert={() => convert(reward)}
          disableConvert={disableConvert}
        />
      )}

      <RewardHelperAboutDialog
        title={helperDialogTitle}
        onClose={onClose}
        open={open}
        disableConvert={disableConvert}
        onConvert={() => convert(reward)}
      >
        <HelperAbout />
      </RewardHelperAboutDialog>
    </div>
  );
};

export default withStyles(styles)(MainRewardConvertCard);
