import React from 'react';
import { useGetHierarchyNodeQuery } from '@src/data/api/endpoints/teamGroups';
import { useSelector } from 'react-redux';

const withHierarchyNode = WrappedComponent => props => {
  const { hierarchyNodeUser } = useSelector(
    state => state.activeHierarchyNodeUser,
  );
  const { data, isLoading, error } = useGetHierarchyNodeQuery({
    hierarchyNodeId: hierarchyNodeUser?._context?.contract?.code ?? null,
  });

  return (
    <WrappedComponent
      {...props}
      hierarchyNodeData={data}
      isHierarchyNodeLoading={isLoading}
      hierarchyNodeError={error}
    />
  );
};

export default withHierarchyNode;
