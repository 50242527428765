import React from 'react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { TitleContainer } from '@spider:src/components/TitleContainer';
import { ParticipantsList } from '@src/Spider/components/ParticipantsList';
import { UserRoles } from '@src/Spider/enums';
import { useAuth } from '@src/auth';

const styles = {
  ordersBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingRight: '0.5rem',
  },
};

const ManagerParticipants = ({ ...props }) => {
  const intl = useIntl();
  const { hierarchyNodeUser } = useAuth();

  return (
    <div className={props.classes.ordersBody}>
      <TitleContainer>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({ id: 'spider.sidebar.manager.participants' })}
        </Typography>
      </TitleContainer>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '1.5rem',
          padding: '1rem',
        }}
      >
        <ParticipantsList
          queryParams={{
            id: hierarchyNodeUser._context?.team?.code,
            types: [
              UserRoles.SUPER_MANAGER,
              UserRoles.MANAGER,
              UserRoles.COLLABORATOR,
            ],
            include_point_balance: true,
            include_manager: true,
            include_approval: true,
            include_children: true,
          }}
        />
      </Box>
    </div>
  );
};

const Component = withStyles(styles)(ManagerParticipants);
export { Component as ManagerParticipants };
