import React, { useEffect, useState } from 'react';
import { Document as DocumentIcon } from 'iconsax-react';
import { useIntl } from 'react-intl';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useParams } from 'react-router-dom';
import RegulationDocument from './components/RegulationDocument/RegulationDocument';
import { Button } from '@src/Spider/components';
import { Loader } from '@src/components';
import { RightDialogFileInput } from '@spider:components/RightDialogFileInput';
import { neutralColors } from '@spider:src/themes';
import { SystemFileStatuses } from '@spider:src/enums/systemFileStatuses';
import usePagination from '@spider:src/hooks/UsePagination';
import {
  useDeleteSystemImageMutation,
  useLazyGetHierarchyNodeSystemImagesListQuery,
  useUpdateHierarchyNodeSystemImageMutation,
} from '@async-calls/systemImages';
import useSystemFile from '@src/Spider/hooks/useSystemFile';

const useStyles = makeStyles(theme => ({
  regulationCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1rem',
    backgroundColor: neutralColors.neutralColdWhite,
    padding: '1rem',
    borderRadius: '1rem',
  },
  regulationHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '1rem',
    },
  },
  regulationUploader: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  regulationTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
  documents: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
}));

const Regulation = ({ regulation }) => {
  const intl = useIntl();
  const { contract } = useParams();
  const classes = useStyles();

  const [isOpenScreenRightDialog, setIsOpenScreenRightDialog] = useState(false);

  const [fetch, { data, isLoading, isSuccess, isFetching }] =
    useLazyGetHierarchyNodeSystemImagesListQuery();

  const { pagination, handleChangePage, paginationCount } = usePagination({
    defaultRowPerPage: 4,
    dataLength: isSuccess ? data.count : 0,
  });

  useEffect(() => {
    fetch({
      fixedCacheKey: `regulation${regulation.id}`,
      hierarchyNodeId: contract,
      codes: [regulation.code],
      statuses: [SystemFileStatuses.ACTIVE, SystemFileStatuses.INACTIVE],
      pagination,
    });
  }, [pagination.page]);

  const onFileUpdated = () => {
    setIsOpenScreenRightDialog(false);
  };

  const { updateSystemFile } = useSystemFile({
    fixedCacheKey: `regulation${regulation.id}`,
    onFileUpdated,
    onFileDeleted: () => {},
    deleteSystemFileMutation: useDeleteSystemImageMutation,
    updateSystemFileMutation: useUpdateHierarchyNodeSystemImageMutation,
  });

  const openRegulationUpload = () => {
    setIsOpenScreenRightDialog(true);
  };

  const updateFile = async files => {
    const code = regulation.code;
    const file = new FormData();
    file.append('code', code);
    file.append('src', files[0]);
    await updateSystemFile(file, {
      hierarchyNodeId: contract,
      code,
    });
  };

  return (
    <div className={classes.regulationCard}>
      <div className={classes.regulationHeader}>
        <div className={classes.regulationTitle}>
          <DocumentIcon />
          <Typography variant={'h3'} component={'h3'}>
            {regulation.label}
          </Typography>
        </div>

        {isSuccess && (
          <Button
            variant={'outlined'}
            color={'primary'}
            size='small'
            className={classes.regulationUploader}
            onClick={openRegulationUpload}
          >
            {data.results.length === 0
              ? intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.regulations.addDocument',
                })
              : intl.formatMessage({
                  id: 'spider.hierarchy_node.platform_customization.regulations.updateDocument',
                })}
          </Button>
        )}
      </div>

      {(isLoading || isFetching) && <Loader centered />}
      {isSuccess && data.results.length === 0 && (
        <Typography
          variant={'body1'}
          style={{ color: neutralColors.neutral600 }}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.regulations.emptyRegulations',
          })}
        </Typography>
      )}
      {isSuccess && (
        <div className={classes.documents}>
          {data.results.map((document, index) => (
            <React.Fragment key={document.id}>
              {index !== 0 && <Divider />}
              <RegulationDocument
                document={document}
                showStatus={regulation?.multiple}
              />
            </React.Fragment>
          ))}
        </div>
      )}

      {isSuccess && regulation?.multiple && (
        <Pagination
          count={paginationCount}
          page={pagination.page}
          onChange={handleChangePage}
          shape='rounded'
        />
      )}

      <RightDialogFileInput
        open={isOpenScreenRightDialog}
        title={regulation.label}
        authorizedFileTypes={['PDF']}
        setOpen={value => setIsOpenScreenRightDialog(value)}
        alertComponent={regulation.alert}
        onValid={updateFile}
      />
    </div>
  );
};

export default Regulation;
