import React from 'react';
import {
  Box,
  makeStyles,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import image from '../../../assets/img/avatar.png';
import { getFullName } from '@src/helpers/StringHelper';
import { gradients, neutralColors } from '@src/Spider';
import { useIntl } from 'react-intl';
import { getIntlEnumLabel } from '@src/Spider/enums/utils';
import { AuthorizedRolesWrapper } from '@src/Spider/components/AuthorizedRolesWrapper';
import { UserRoles } from '@src/Spider/enums';
import { formatDate } from '@src/helpers/DateHelper';
import { InfoCircle } from 'iconsax-react';

const useStyles = makeStyles({
  root: {
    backgroundColor: neutralColors.neutralBg,
    borderRadius: '0.5rem',
    padding: '1rem',
    width: '100%',
    display: 'flex',
    gap: '1rem',
  },
  image: {
    width: '2.25rem',
    height: '2.25rem',
  },
  matricule: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  points: {
    fontSize: '2rem',
    fontWeight: 'bold',
    background: gradients.gradientLightRedRose,
    '-webkit-background-clip': 'text',
    'background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    color: 'black', // Fallback for browsers that don't support background-clip: text
  },
  sub: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
  },
  tooltip: {
    marginLeft: '0.25rem',
  },
});

/**
 * @param {{user: import("@async-calls/hierarchyNodeUsers").UserBodyDetailed, className: string}} props
 */
const ProfileCard = ({ user, className }) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Box className={`${classes.root} ${className}`}>
      <Box>
        <img src={image} className={classes.image} />
      </Box>
      <Box>
        <Typography className={classes.matricule}>
          {user.first_identifier}
        </Typography>
        <Box className={classes.sub}>
          <Typography>
            {user.type ? intl.formatMessage({
              id: getIntlEnumLabel('participant_type', user.type),
            }) : ''}
          </Typography>
          <Typography>{getFullName(user)}</Typography>
          {user.point_balance && (
            <AuthorizedRolesWrapper
              roles={[UserRoles.ADMINISTRATOR, UserRoles.SUPER_ADMINISTRATOR]}
            >
              <Typography className={classes.points}>
                {user?.point_balance?.available}
                <Tooltip
                  className={classes.tooltip}
                  title={intl.formatMessage(
                    {
                      id: 'spider.convertShortcut.tooltips_information',
                    },
                    {
                      points: user?.point_balance?.available,
                      date: formatDate(
                        user?.point_balance?.next_expiration?.usability_end,
                      ),
                    },
                  )}
                  placement='right'
                >
                  <InfoCircle fill={neutralColors.neutralWhite} />
                </Tooltip>
              </Typography>
            </AuthorizedRolesWrapper>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { ProfileCard };
