import instance from '../instance';
import { appendSearchParams } from '@src/helpers/UrlHelper';
import { baseApi } from '@src/store/api-query';
import { tagTypes } from '@src/store/api-tags';
const baseUrl = 'configs/';

const configsOld = {
  permanent(teamGroup = 1, codes = [], period = null, legacy = false) {
    const url = legacy
      ? `${baseUrl}permanent/`
      : `hierarchy-nodes/${teamGroup}/config`; // @todo endpoint dans team_group

    const params = new URLSearchParams();
    if (codes && codes.length > 0) {
      codes.forEach(code => {
        params.append('codes', code);
      });
    }
    return instance.get(appendSearchParams(url, { period }), { params });
  },
  detail(code) {
    const url = `${baseUrl}${code}/`;
    return instance.get(url);
  },
  update(id, value) {
    const url = `${baseUrl}${id}/`;
    return instance.patch(url, {
      value: value != null && value.toString() != '' ? value.toString() : null,
    });
  },
};

const configs = {
  /** @param {ListConfigParams} queryParams */
  list({ hierarchyNodeId, codes = [], period = null }) {
    const url = `hierarchy-nodes/${hierarchyNodeId}/config`;

    const params = new URLSearchParams();
    if (codes && codes.length > 0) {
      codes.forEach(code => {
        params.append('codes', code);
      });
    }
    return instance.get(appendSearchParams(url, { period }), { params });
  },
  /** @param {GetConfigParams} queryParams */
  get({ hierarchyNodeId, code }) {
    const url = `hierarchy-nodes/${hierarchyNodeId}/config/${code}/`;
    return instance.get(url);
  },
  /** @param {UpdateConfigParams} queryParams */
  update({ id, value }) {
    const url = `${baseUrl}${id}/`;
    return instance.patch(url, {
      value: value != null && value.toString() != '' ? value.toString() : null,
    });
  },
};

const configQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<ListConfigParams, typeof builder.query, 'api-query', ConfigsListResult>}*/
    getConfigsList: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => configs.list(params));
      },
      providesTags: [tagTypes.configs],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<GetConfigParams, typeof builder.query, 'api-query', ConfigResult>}*/
    getConfig: builder.query({
      queryFn: async (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => configs.get(params));
      },
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<UpdateConfigParams, typeof builder.mutation, 'api-query', ConfigResult>}*/
    updateConfig: builder.mutation({
      queryFn: async (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => configs.update(params));
      },
      invalidatesTags: (_, __, arg) => [tagTypes.configs],
    }),
  }),
});

export const {
  useGetConfigsListQuery,
  useGetConfigQuery,
  useLazyGetConfigsListQuery,
  useUpdateConfigMutation,
} = configQueries;

export default configsOld;

/**
 * @typedef {Object} ListConfigParams
 * @property {string} hierarchyNodeId Id du hierarchy node user
 * @property {import('@spider:src/enums/configCodes').ConfigCodesType[]} codes Les codes que l'on souhaite récupérer
 * @property {string} period La période sur laquelle on veut ces codes
 */

/**
 * @typedef {Object} GetConfigParams
 * @param {import('@spider:src/enums/configCodes').ConfigCodesType} code Le code que l'on souhaite récupérer
 */

/**
 * @typedef {Object} UpdateConfigParams
 * @property {number} id Id de la config à modifier
 * @property {any | null} value La valeur de la config à modifier
 */

/**
 * @typedef {Object} ConfigResult
 * @property {import('@spider:src/enums/configCodes').ConfigCodesType} code Le code de la config
 * @property {string} description La description de al config
 * @property {number} id L'id de la config
 * @property {number} team La team à laquelle la config est liée
 * @property {any} value La valeur de la config
 * */

/** @typedef {{
 results: ConfigResult[];
 count: number;
 }} ConfigsListResult */
