import React, { useState } from 'react';
import {
  DialogContent,
  Drawer,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { DialogIndicator } from '@spider:components/DialogIndicator';
import { neutralColors } from '@src/Spider/themes';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useAuth } from '@src/auth';
import { CloseButtonIcon } from '@spider:components/CloseButtonIcon';
import useSystemFile from '@src/Spider/hooks/useSystemFile';
import { useCreateImportLogMutation } from '@async-calls/importLogs';
import { TabbedContent } from '../TabbedContent';
import { ImportContent } from '../ImportDialog/components/ImportContent';

const useStyles = makeStyles({
  dialogTitle: {
    display: 'flex !important',
    flexDirection: 'column !important',
    padding: '1rem !important',
    width: '100%',
  },
  closeTitleDialog: {
    position: 'absolute',
    right: '1rem',
    top: '2rem',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '1rem',
    overflow: 'auto',
    backgroundColor: neutralColors.neutralBg,
    padding: '0rem',
    [createTheme().breakpoints.down('md')]: {
      backgroundColor: neutralColors.neutralBg,
      padding: '0rem 2rem 2rem 2rem',
    },
  },
  dialogPaperContent: {
    borderRadius: '1rem',
    marginRight: 'auto',
    marginLeft: 'auto',
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
    padding: '1rem',
    width: '75%',
    [createTheme().breakpoints.down('md')]: {
      backgroundColor: neutralColors.neutralBg,
      padding: '0rem',
      width: '100%',
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      borderTopLeftRadius: '0.75rem',
      borderTopRightRadius: '0.75rem',
      height: '85vh',
      border: '4px solid',
      borderBottom: 'none',
      borderColor: neutralColors.neutral300,
      [createTheme().breakpoints.down('sm')]: {
        border: 'none',
        height: '90vh',
      },
    },
  },
  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: neutralColors.neutralBg,
    cursor: 'pointer',
    minHeight: '48px',
    [createTheme().breakpoints.down('sm')]: {
      minHeight: '24px',
    },
  },
});

const ImportDialog = ({
  fixedCacheKey,
  open,
  setOpen,
  defaultTab,
  onFileCreated,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { contract } = useParams();
  const { hierarchyNodeUser } = useAuth();

  const isMobile = useMediaQuery(createTheme().breakpoints.down('sm'));

  const { updateSystemFile } = useSystemFile({
    fixedCacheKey,
    onFileUpdated: additionalProps => {
      onFileCreated(additionalProps);
      onClose();
    },
    updateSystemFileMutation: useCreateImportLogMutation,
  });

  const tabs = [
    {
      label: intl.formatMessage({ id: 'spider.points.title' }),
      index: '1',
      content: onValidate => (
        <ImportContent
          key={'imports'}
          importLogType={'POINTS'}
          validateImport={onValidate}
        />
      ),
    },
    {
      label: intl.formatMessage({ id: 'spider.participants.title' }),
      index: '2',
      content: onValidate => (
        <ImportContent
          key={'participants'}
          importLogType={'USERS'}
          validateImport={onValidate}
        />
      ),
    },
  ];

  const getDefaultTab = () => {
    return defaultTab ? defaultTab : tabs[1].index;
  };

  const [tabValue, setTabValue] = useState(getDefaultTab());

  const onValidate = async (files, importLogType) => {
    const file = new FormData();
    file.append('type', importLogType);
    file.append('file', files[0]);
    file.append('hierarchy_node_user', hierarchyNodeUser.uuid);
    await updateSystemFile(file, {
      hierarchyNodeId: contract,
      type: importLogType,
    });
  };

  const onClose = () => {
    setOpen(false);
    setTabValue(getDefaultTab());
  };

  return (
    <Drawer
      anchor='bottom'
      open={open}
      onClose={onClose}
      className={classes.drawer}
    >
      <div className={classes.header} onClick={onClose}>
        <DialogIndicator />
        {!isMobile && (
          <CloseButtonIcon
            onClick={onClose}
            className={classes.closeTitleDialog}
          />
        )}
      </div>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dialogTitle}>
          <div>
            <Typography
              variant={'h1'}
              component={'h1'}
              className={'title underline-center'}
            >
              {intl.formatMessage({ id: 'spider.importDialog.title' })}
            </Typography>
          </div>
        </div>
        <TabbedContent
          tabValue={tabValue}
          setTabValue={setTabValue}
          tabs={tabs}
          onValidate={onValidate}
        />
      </DialogContent>
    </Drawer>
  );
};

export default ImportDialog;
