import { PointCommandIds } from '@src/Spider/enums';
import { useIntl } from 'react-intl';
import { ReactComponent as CardDetailGlobe } from '@spider:src/assets/img/homeParticipantsConvert/card-details-globe.svg';
import { ReactComponent as CardDetailShop } from '@spider:src/assets/img/homeParticipantsConvert/card-details-shop.svg';
import { ReactComponent as CardDetailCalendar } from '@spider:src/assets/img/homeParticipantsConvert/card-details-calendar.svg';

export const useDefaultRewardDetails = reward => {
  const intl = useIntl();

  if (!reward?.uuid) {
    console.warn('useDefaultRewardDetails: missing reward or reward uuid');
    return [];
  }

  const createDetails = type => [
    {
      Icon: CardDetailGlobe,
      label: intl.formatMessage({
        id: `spider.convertPoints.home.${type}.globeLabel`,
      }),
    },
    {
      Icon: CardDetailShop,
      label: intl.formatMessage({
        id: `spider.convertPoints.home.${type}.shopLabel`,
      }),
    },
    {
      Icon: CardDetailCalendar,
      label: intl.formatMessage({
        id: `spider.convertPoints.home.${type}.calendarLabel`,
      }),
    },
  ];

  const rewardDetails = () => {
    switch (reward.uuid) {
      case PointCommandIds.CKS:
        return createDetails('cks');
      case PointCommandIds.CKU:
        return createDetails('cku');
      case PointCommandIds.KS:
        return createDetails('ks');
      default:
        console.warn(
          `useRewardHelper: reward type non managed: ${reward.uuid}`,
        );
        return [];
    }
  };

  return rewardDetails();
};
