import React, { useMemo } from 'react';
import Formsy from 'formsy-react';
import { Button } from '@src/Spider/components';
import { useIntl } from 'react-intl';
import { neutralColors, systemColors } from '@src/Spider/themes';
import { makeStyles, Typography } from '@material-ui/core';
import { ReactComponent as PointDeclaration } from '@src/Spider/assets/img/points-declaration.svg';
import { useAuth } from '@src/auth';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { useFormHelper } from '@src/Spider/hooks/useFormHelper';
import { BaseAddressForm } from '@spider:components/Form/BaseAddressForm';
import { LFSSPersonalInformationForm } from '@src/Spider/scenes/ConvertPoints/components/components/DeclarationComponents/LFSSPersonalInformationForm';
import { IdentificationForm } from '@src/Spider/scenes/ConvertPoints/components/components/DeclarationComponents/IdentificationForm';
import { RequiredCheckboxCheck } from '@spider:components/RequiredCheckboxCheck';
import { useFormDataHelper } from '@src/Spider/hooks/useFormDataHelper';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formFields: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  informationCard: {
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    gap: '0.5rem',
    justifyContent: 'center',
    borderRadius: '1rem',
    overflow: 'clip',
    position: 'relative',
    backgroundColor: neutralColors.neutralColdWhite,
  },
  block: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
}));

/**
 * @param {{
 *   nextStep: ({ ignoreProps: string[] }) => void,
 *   previousStep: () => void,
 *   stepKeys: string[]
 *   nextStepLoading: boolean
 * }} props
 */
const DeclarationStep = ({
  nextStep,
  nextStepLoading,
  previousStep,
  stepKeys,
}) => {
  const intl = useIntl();
  const { hierarchyNodeUser } = useAuth();
  const convertPointsContext = useConvertPoints();
  const classes = useStyles();

  const defaultFormData = useMemo(() => {
    const lfss_form = {
      terms_accepted: false,
      first_name: hierarchyNodeUser.firstname,
      last_name: hierarchyNodeUser.lastname,
      birth_name: '',
      email: hierarchyNodeUser.email,
    };

    if (convertPointsContext.requestFlags?.lfss_form?.personal_address) {
      lfss_form.personal_address = {
        line1: '',
        line2: '',
        postal_code: '',
        city: '',
      };
    }

    if (convertPointsContext.requestFlags?.lfss_form?.employer_address) {
      lfss_form.employer_address = {
        line1: '',
        line2: '',
        postal_code: '',
        city: '',
        company_name: '',
      };
    }

    if (convertPointsContext.requestFlags?.lfss_form?.personal_infos) {
      lfss_form.personal_infos = {
        birth_date: '',
        birth_department: '',
        birth_city: '',
        birth_country: '',
        nationality_country: '',
        social_security_number: '',
      };
    }

    return lfss_form;
  }, [
    convertPointsContext.reward,
    hierarchyNodeUser.cku,
    hierarchyNodeUser.type,
  ]);

  const { formData, handleFormChange } = useFormDataHelper(
    convertPointsContext.declarationStepData,
    defaultFormData,
  );

  const {
    formRef,
    isSubmitButtonDisabled,
    onInvalid,
    onValid,
    onSubmit,
    formChange,
  } = useFormHelper({
    onSubmitCallback: () => nextStep({ ignoreProps: stepKeys }),
    onInvalidCallback: () => {
      convertPointsContext.removeDeclarationStepData(true);
    },
    onValidCallback: formData => {
      convertPointsContext.setDeclarationStepData({
        value: formData,
        setInSessionStorage: true,
      });
    },
    onFormChange: convertPointsContext.setErrorOnFormChange,
    errorHook: useConvertPoints,
  });

  return (
    <Formsy
      ref={formRef}
      name={'declaration-form'}
      className={classes.root}
      onValidSubmit={onSubmit}
      onValid={() => onValid(formData)}
      onInvalid={onInvalid}
      onChange={event => formChange(event, defaultFormData, stepKeys)}
    >
      <div className={classes.rootTitle}>
        <PointDeclaration
          fill={neutralColors.neutral900}
          style={{ width: '1.5rem', height: '1.5rem' }}
        />
        <Typography variant={'h3'} component={'h3'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.title',
          })}
        </Typography>
      </div>

      <Typography variant={'body1'} style={{ fontStyle: 'italic' }}>
        {intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.subtitle',
        })}
      </Typography>

      <div className={classes.informationCard}>
        <Typography
          variant={'subtitle1'}
          style={{ color: systemColors.infoRegular, fontWeight: 'bold' }}
        >
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.infoLfssTitle',
          })}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant={'body2'} style={{ lineHeight: '1.5rem' }}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.infoLfssMessageOne',
            })}
          </Typography>
        </div>
      </div>

      <div className={classes.block}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.identification.title',
          })}
        </Typography>
        <IdentificationForm
          formData={formData}
          error={convertPointsContext?.error?.lfss_form}
          classes={classes}
          handleFormChange={handleFormChange}
        />
      </div>
      {convertPointsContext.requestFlags?.lfss_form?.personal_address && (
        <div className={classes.block}>
          <Typography variant={'h4'} component={'h4'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.personalAddress.title',
            })}
          </Typography>
          <BaseAddressForm
            formData={formData.personal_address}
            baseProps={'personal_address'}
            error={convertPointsContext?.error?.lfss_form?.personal_address}
            classes={classes}
            handleFormChange={handleFormChange}
          />
        </div>
      )}
      {convertPointsContext.requestFlags?.lfss_form?.employer_address && (
        <div className={classes.block}>
          <Typography variant={'h4'} component={'h4'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.employerAddress.title',
            })}
          </Typography>
          <BaseAddressForm
            formData={formData.employer_address}
            baseProps={'employer_address'}
            error={convertPointsContext?.error?.lfss_form?.employer_address}
            classes={classes}
            handleFormChange={handleFormChange}
            showCompanyName={true}
          />
        </div>
      )}
      {convertPointsContext.requestFlags?.lfss_form?.personal_infos && (
        <div className={classes.block}>
          <Typography variant={'h4'} component={'h4'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.personalInformation.title',
            })}
          </Typography>
          <LFSSPersonalInformationForm
            formData={formData.personal_infos}
            error={convertPointsContext?.error?.lfss_form?.personal_infos}
            classes={classes}
            baseProps={'personal_infos'}
            handleFormChange={handleFormChange}
          />
        </div>
      )}

      <RequiredCheckboxCheck
        name={'terms_accepted'}
        formData={formData.terms_accepted}
        error={convertPointsContext.error?.lfss_form?.terms_accepted}
        handleFormChange={handleFormChange}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.honorAttestation',
        })}
      />

      <div className={classes.actions}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'large'}
          onClick={previousStep}
          load={nextStepLoading}
        >
          {intl.formatMessage({ id: 'spider.common.back' })}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'large'}
          type={'submit'}
          disabled={isSubmitButtonDisabled}
          load={nextStepLoading}
        >
          {intl.formatMessage({ id: 'spider.common.submit' })}
        </Button>
      </div>
    </Formsy>
  );
};

export { DeclarationStep };
