import { useDocumentTitle } from '@src/Spider/hooks/useDocumentTitle';
import useFavicon from '@src/Spider/hooks/useFavicon';

/**
 * Custom hook that updates the distribution configuration automatically.
 *
 * @param {import('@src/distributors/distributorConfig').DistributorConfig} [distributorConfig] - The distributorConfig
 * @example
 * // Usage in a component
 * useConfigureDistribution(myDistributionConfig);
 *
 * @returns {void}
 */
export const useConfigureDistribution = distributorConfig => {
  useDocumentTitle(distributorConfig);
  useFavicon(distributorConfig);
};
