import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { TextFieldFormsy } from '@spider:components/FormsyComponents/TextFieldFormsy';
import useDirtyPristine from '@spider:src/hooks/UseDirtyPristine';
import Button from '@spider:src/components/Button';
import Formsy from 'formsy-react';
import { useHistory, useParams } from 'react-router-dom';
import { AdministrationUrl } from '@src/Spider/enums/administrationUrl';
import { neutralColors } from '@src/Spider/themes';
import { Loader } from '@src/components';
import {
  useGetHierarchyNodeQuery,
  usePatchHierarchyNodeMutation,
} from '@async-calls/teamGroups';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  platformId: {
    display: 'flex',
    flexDirection: 'column',
  },
  platformCode: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  formBlock: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  platformCodeResult: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const PlatformParameters = () => {
  const intl = useIntl();
  const history = useHistory();
  const { dirtyChange, resetPristinePage } = useDirtyPristine();
  const fullURL = new URL(window.location.href);
  const { contract } = useParams();
  const classes = useStyles();

  const hierarchyNodeResult = useGetHierarchyNodeQuery({
    hierarchyNodeId: contract,
  });

  const [patchHierarchyNode, mutateResult] = usePatchHierarchyNodeMutation({
    hierarchyNodeId: contract,
  });

  const [platformName, setPlatformName] = useState(
    hierarchyNodeResult?.data?.name,
  );
  const maxPlatformNameLength = 128;

  const [platformCode, setPlatformCode] = useState(
    hierarchyNodeResult?.data?.code,
  );
  const maxPlatformCodeLength = 64;

  const [parentName, setParentName] = useState(
    hierarchyNodeResult?.data?.parent_name,
  );
  const maxParentNameLength = 128;

  const platformNameFieldChange = e => {
    dirtyChange();
    setPlatformName(e.target.value);
  };

  const platformCodeFieldChange = e => {
    dirtyChange();
    setPlatformCode(e.target.value.replaceAll(' ', '-').toLowerCase());
  };

  const contractDetailsNameFieldChange = e => {
    dirtyChange();
    setParentName(e.target.value);
  };

  const savePlatformParameters = async () => {
    resetPristinePage();

    try {
      await patchHierarchyNode({
        hierarchyNode: {
          baseCode: hierarchyNodeResult?.data?.code,
          code: platformCode,
          name: platformName,
          parent_name: parentName,
        },
      }).unwrap();
      toast.success(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.platform.update.success',
        }),
      );
      history.push(
        `/nodes/${platformCode}/administration/${AdministrationUrl.PARAMETERS}`,
        { shallow: true },
      );
    } catch (error) {
      console.error('Failed to update code:', error);
      toast.error(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.platform.update.error',
        }),
      );
    }
  };

  useEffect(() => {
    setPlatformCode(hierarchyNodeResult?.data?.code ?? '');
    setPlatformName(hierarchyNodeResult?.data?.name ?? '');
    setParentName(hierarchyNodeResult.data?.parent_name ?? '');
  }, [hierarchyNodeResult]);

  return hierarchyNodeResult.isFetching || patchHierarchyNode.isFetching ? (
    <Loader centered />
  ) : (
    <Formsy
      name={'parameter-platform'}
      onValidSubmit={savePlatformParameters}
      className={classes.root}
    >
      <Typography variant={'h4'} component={'h4'}>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.platform.title',
        })}
      </Typography>

      <div className={classes.platformId}>
        <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
          {hierarchyNodeResult?.data?.id}
        </Typography>
        <Typography
          variant={'body1'}
          style={{ color: neutralColors.neutral700 }}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.platform.platformIdLabel',
          })}
        </Typography>
      </div>

      <div className={classes.formBlock}>
        <TextFieldFormsy
          name={'platformName'}
          value={platformName}
          label={intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.platform.platformNameLabel',
          })}
          style={{ width: '100%' }}
          variant={'outlined'}
          required
          validations={{
            maxLength: maxPlatformNameLength,
          }}
          validationErrors={{
            isDefaultRequiredValue: intl.formatMessage({
              id: 'common.form.required_error',
            }),
            maxLength: intl
              .formatMessage({
                id: 'common.form.max_length_custom_error',
              })
              .format(maxPlatformNameLength),
          }}
          onChange={platformNameFieldChange}
        />
        <TextFieldFormsy
          name={'contractDetailsName'}
          value={parentName}
          label={intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.customer_name_label',
          })}
          style={{ width: '100%' }}
          variant={'outlined'}
          required
          validations={{
            maxLength: maxParentNameLength,
          }}
          validationErrors={{
            isDefaultRequiredValue: intl.formatMessage({
              id: 'common.form.required_error',
            }),
            maxLength: intl
              .formatMessage({
                id: 'common.form.max_length_custom_error',
              })
              .format(maxParentNameLength),
          }}
          onChange={contractDetailsNameFieldChange}
        />
      </div>

      <div className={classes.platformCode}>
        <TextFieldFormsy
          name={'platformCode'}
          value={platformCode}
          label={intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.platform.platformUrlLabel',
          })}
          variant={'outlined'}
          required
          style={{ width: '100%' }}
          validations={{
            maxLength: maxPlatformCodeLength,
            isSlug: platformCode,
          }}
          validationErrors={{
            isDefaultRequiredValue: intl.formatMessage({
              id: 'common.form.required_error',
            }),
            maxLength: intl
              .formatMessage({
                id: 'common.form.max_length_custom_error',
              })
              .format(maxPlatformCodeLength),
            isSlug: intl.formatMessage({
              id: 'common.form.is_slug',
            }),
          }}
          onChange={platformCodeFieldChange}
        />
        <div className={classes.platformCodeResult}>
          <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
            {`${fullURL.host}/${platformCode}`}
          </Typography>
          <Typography
            variant={'body1'}
            style={{ color: neutralColors.neutral700 }}
          >
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.parameters.platform.platformUrlLabel',
            })}
          </Typography>
        </div>
      </div>

      <div className={classes.actions}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'small'}
          type={'submit'}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.platform.saveButtonLabel',
          })}
        </Button>
      </div>
    </Formsy>
  );
};

export { PlatformParameters };
