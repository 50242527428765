import { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { useSessionStorage } from '@src/data/session/useSessionStorage';
import { useDebounceFunction } from '@src/Spider/hooks/useDebounceFunction';
/**
 * @param {{
 *   isDirtySubmit: boolean,
 *   onInvalidCallback: (formData: Object) => void,
 *   onValidCallback: (formData: Object) => void,
 *   onSubmitCallback: (formData: Object) => void,
 *   onFormChange: (deleteProps: string[], forceDelete: boolean) => void,
 *   errorHook: () => {},
 *   useDebounceOnValid: boolean,
 *   debounceDelay: number,
 * }} props
 */
export const useFormHelper = ({
  isDirtySubmit = false,
  onSubmitCallback = () => {},
  onValidCallback = () => {},
  onInvalidCallback = () => {},
  onFormChange = () => {},
  errorHook = () => {
    return { error: null };
  },
  useDebounceOnValid = false,
  debounceDelay = 300,
}) => {
  const formRef = useRef(null);
  const [isFormSubmitted, setIsFormSubmitted, removeIsFormSubmitted] =
    useSessionStorage('isFormSubmitted', false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const isSubmitButtonDisabled = useMemo(
    () => !canSubmit || (isDirtySubmit ? !isFormDirty : false),
    [canSubmit, isFormDirty, isDirtySubmit],
  );
  const errorHookResult = errorHook();

  const validateForm = () => {
    if (formRef.current) {
      formRef.current.validateForm();
      formRef.current.setFormPristine(false);
    }
  };

  const disableSubmit = () => setCanSubmit(false);
  const enableSubmit = () => setCanSubmit(true);

  const dirtyForm = () => setIsFormDirty(true);
  const pristineForm = () => setIsFormDirty(false);

  const onInvalid = formData => {
    try {
      disableSubmit();
      onInvalidCallback(formData);
    } catch (error) {
      console.error('onInvalid error:', error);
    }
  };

  const onValid = useDebounceFunction(
    formData => {
      try {
        enableSubmit();
        onValidCallback(formData);
      } catch (error) {
        console.error('onValid error:', error);
      }
    },
    useDebounceOnValid ? debounceDelay : 0,
  );

  const onSubmit = formData => {
    try {
      setIsFormSubmitted({ value: true, setInSessionStorage: true });
      validateForm();
      onSubmitCallback(formData);
    } catch (error) {
      console.error('onSubmit error:', error);
    }
  };

  const formChange = (event, originalData, deleteProps) => {
    if (_.isEqual({ ...originalData }, { ...event })) {
      pristineForm();
    } else {
      dirtyForm();
      onFormChange(deleteProps, true);
    }
  };

  useEffect(() => {
    if (
      !errorHookResult.error ||
      Object.keys(errorHookResult.error).length === 0
    )
      return;

    if (isFormDirty && isFormSubmitted) {
      validateForm();
    }
  }, [isFormSubmitted, errorHookResult.error, isFormDirty]);

  useEffect(() => {
    return () => {
      removeIsFormSubmitted();
    };
  }, []);

  return {
    formRef,
    canSubmit,
    isFormDirty,
    isSubmitButtonDisabled,
    disableSubmit,
    enableSubmit,
    dirtyForm,
    pristineForm,
    validateForm,
    onInvalid,
    onValid,
    onSubmit,
    formChange,
  };
};
