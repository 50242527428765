import { useEffect } from 'react';

/**
 * Custom hook that updates the document title, automatically .
 *
 * @param {import('@src/distributors/distributorConfig').DistributorConfig} [distributorConfig]
 * @example
 * // Usage in a component
 * useDocumentTitle('My Page Title');
 *
 * @returns {void}
 */
export function useDocumentTitle(distributorConfig) {
  useEffect(() => {
    document.title = distributorConfig.title || '';
  }, [distributorConfig]);
}
