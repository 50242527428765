import React from 'react';
import { withStyles } from '@mui/styles';
import { Link, Paper, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useGetHierarchyNodeQuery } from '@async-calls/teamGroups';
import { Loader } from '@src/components';
import { HierarchyNodeTypes } from '@src/Spider/enums';
import { useGetHierarchyNodeListQuery } from '@async-calls/hierarchyNodes';

const styles = {
  root: {
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      boxShadow: 'none !important',
      width: '288px',
      overflow: 'auto',
      position: 'relative',
      padding: '0.5rem 1rem',
      borderRadius: 16,
    },
  },
  hierarchyNodeLogo: {
    height: '3.5rem',
    width: '3.5rem',
    objectFit: 'cover',
  },
  hierarchyNodeInfo: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  hierarchyNodeName: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
  },
  hierarchyNodeDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  hierarchyNodeAction: {
    display: 'flex',
    justifyContent: 'center',
  },
};

const OperationDialog = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const { contract } = useParams();

  const { data, isFetching } = useGetHierarchyNodeQuery({
    hierarchyNodeId: contract,
  });

  const useGetHierarchyNodeListResult = useGetHierarchyNodeListQuery({
    type: HierarchyNodeTypes.CONTRACT,
  });

  const toNodes = () => {
    history.push('/nodes');
  };

  return isFetching ? (
    <Loader centered />
  ) : (
    <div className={props.classes.root}>
      <Paper>
        <div className={props.classes.hierarchyNodeInfo}>
          <img
            src={data?._context?.logo?.src ?? null}
            alt='platform-logo'
            className={props.classes.hierarchyNodeLogo}
          />
          <div className={props.classes.hierarchyNodeDetails}>
            <Typography
              variant={'h6'}
              component={'h4'}
              className={props.classes.hierarchyNodeName}
            >
              {data?.name}
            </Typography>
            <Typography>{data?.parent_name}</Typography>
          </div>
        </div>
        {useGetHierarchyNodeListResult?.data?.results?.length > 1 && (
          <div className={props.classes.hierarchyNodeAction}>
            <Link
              className={'bold small'}
              onClick={toNodes}
              style={{ textDecoration: 'none' }}
            >
              {intl.formatMessage({
                id: 'spider.org.contract_selection.switch',
              })}
            </Link>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default withStyles(styles)(OperationDialog);
