import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { Button } from './index';
import { createTheme } from '@material-ui/core/styles';
import { gradients, neutralColors } from '@src/Spider';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row !important',
    gap: '1rem',
    padding: ({ enableBackgroundImage }) =>
      enableBackgroundImage ? '0 1.5rem 0 0' : '1.5rem 1.5rem 1.5rem 0',
    [createTheme().breakpoints.down('md')]: {
      flexDirection: ({ enableBackgroundImage }) =>
        enableBackgroundImage ? 'column !important' : 'row !important',
      padding: ({ enableBackgroundImage }) =>
        enableBackgroundImage ? '1.5rem' : '1.5rem 1.5rem 1.5rem 0',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  imageContent: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ({ enableBackgroundImage }) =>
      enableBackgroundImage ? '0.5rem' : '0rem',
    [createTheme().breakpoints.down('md')]: {
      width: ({ enableBackgroundImage }) =>
        enableBackgroundImage ? '100%' : 'fit-content',
    },
  },
  backgroundImage: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    borderRadius: '1rem',
    padding: '0.5rem',
    border: `3px solid ${neutralColors.neutralWhite}`,
    opacity: '0.5',
    background: gradients.gradientLightRedRoseBackground,
    zIndex: 1,
  },
  contentDisposition: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'space-between',
    width: '100%',
    [createTheme().breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  actionButton: {
    maxHeight: 'max-content',
    margin: 'auto 0rem',
    whiteSpace: 'nowrap',
  },
});

const Component = ({
  title,
  description,
  sourceImage,
  altImage = 'card-image',
  enableBackgroundImage = false,
  buttonLabel,
  onClick,
}) => {
  const classes = useStyles({
    enableBackgroundImage,
  });
  return (
    <Card className={`${classes.root} selectable`} onClick={onClick}>
      <div className={classes.imageContent}>
        {enableBackgroundImage && <div className={classes.backgroundImage} />}
        <img src={sourceImage} alt={altImage} style={{ zIndex: 2 }} />
      </div>

      <div className={classes.contentDisposition}>
        <div className={classes.content}>
          <Typography variant='h4' component='h4'>
            {title}
          </Typography>
          <Typography variant='body1'>{description}</Typography>
        </div>

        <Button
          variant='contained'
          color='primary'
          className={classes.actionButton}
        >
          {buttonLabel}
        </Button>
      </div>
    </Card>
  );
};

export { Component as CardAction };
