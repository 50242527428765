import { useState } from 'react';

export const useFormErrorHandling = () => {
  const [error, setError] = useState(null);

  const setErrorOnFormChange = (deleteProps, forceDelete) => {
    setError(prevError => {
      if (!prevError) return null;
      const newError = { ...prevError };
      if (forceDelete || !newError.preventDelete) {
        deleteProps.forEach(props => {
          delete newError[props];
        });
      } else {
        newError.preventDelete = false;
      }
      return newError;
    });
  };

  return {
    error,
    setError,
    setErrorOnFormChange,
  };
};
