import React from 'react';
import ObjowRoutes from '../Routes';
import SpiderRoutes from '../Spider/Routes';
import { useEdenredTheme } from '../Spider/themes';

/** @type {DistributorConfig} */
export const objow = {
  title: 'Objow',
  favicon: '/favicon.svg',
  routes: <ObjowRoutes />,
  baseUrl: process.env.REACT_APP_OBJOW_API_URL,
  mediaUrl: '',
  scripts: {
    toucan: true,
    onetrust: false,
    intercom: '',
  },
  persistMiddleware: store => next => action => {
    const result = next(action);
    const stateToPersist = {
      accountDetail: store.getState().accountDetail,
      activeHierarchyNodeUser: store.getState().activeHierarchyNodeUser,
    };
    localStorage.setItem('reduxState', JSON.stringify(stateToPersist));
    return result;
  },
  loadPersistedState: () => {
    try {
      const serializedState = localStorage.getItem('reduxState');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch {
      return console.warn('Unable to load persisted state');
    }
  },
  clearPersistedState: () => {
    localStorage.removeItem('reduxState');
  },
};

/** @type {DistributorConfig} */
export const edenred = {
  title: 'Edenred France - Kadéos Incentive',
  favicon: '/favicon-edenred.svg',
  routes: <SpiderRoutes />,
  useTheme: useEdenredTheme,
  baseUrl: process.env.REACT_APP_EDENRED_API_URL,
  mediaUrl: '',
  scripts: {
    toucan: false,
    onetrust: true,
    intercom: '',
  },
  persistMiddleware: store => next => action => {
    return next(action);
  },
  loadPersistedState: () => {},
  clearPersistedState: () => {},
};

/**
 * @typedef {{
 *  title: string,
 *  favicon: string,
 *  routes: React.ReactNode,
 *  useTheme?: () => {},
 *  baseUrl: string,
 *  mediaUrl: string,
 *  loadLocalStorage: boolean,
 *  scripts: {
 *    toucan: boolean,
 *    onetrust: boolean,
 *    intercom: string,
 *  },
 *  persistMiddleware: () => {},
 *  loadPersistedState: () => {},
 *  clearPersistedState: () => {},
 * }} DistributorConfig
 * @exports { DistributorConfig };
 */
