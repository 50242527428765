import React, { useEffect } from 'react';
import { Loader } from '@src/components';
import { PersonalisationPanel } from './components/PersonalisationPanel';
import { ColorPicker } from './components/ColorPicker';
import { getConfigsKeyStart } from '@spider:src/features/config/getConfigs/slices';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ConfigCodes } from '@spider:src/enums/configCodes';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
}));

const PersonalizationTab = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const classes = useStyles();

  useEffect(() => {
    dispatch(
      getConfigsKeyStart({
        teamGroupId: params.contract,
        codes: [ConfigCodes.MAIN_COLOR],
      }),
    );
  }, []);

  const configs = useSelector(state => state.getConfigsKey);

  return (
    <div className={classes.root}>
      {configs.success && <ColorPicker />}
      <PersonalisationPanel />
      {configs.loading && <Loader centered />}
    </div>
  );
};

export default PersonalizationTab;
