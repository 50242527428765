import React from 'react';
import { withFormsy } from 'formsy-react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  Select,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: '0.5rem',
  },
}));

/**
 * @param {{
 *   isPristine: boolean,
 *   isValid: boolean,
 *   errorMessage: string,
 *   helperText: string,
 * }} props
 */
const Component = ({ ...props }) => {
  const hasError = !props.isPristine && !props.isValid;
  const helperText = hasError ? props.errorMessage : props.helperText;
  const classes = useStyles();
  return (
    <FormControl
      variant={props.variant}
      required
      error={Boolean(hasError)}
      className={classes.formControl}
    >
      <InputLabel htmlFor={props.id}>{`${props.label}`}</InputLabel>
      <Select
        {...props}
        error={hasError}
        required
        helperText={helperText}
        inputProps={{
          id: props.id,
        }}
      />
      {Boolean(hasError) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const SelectFormsy = withFormsy(Component);

export { SelectFormsy };
