import React, { useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useSessionStorage } from '@src/data/session/useSessionStorage';
import { useFormErrorHandling } from '@src/Spider/hooks/useFormErrorHandling';

const ConvertPointsContext = createContext({
  reward: null,
  setReward: () => {},
  removeReward: () => {},
  simulation: null,
  setSimulation: () => {},
  pointStepData: null,
  setPointStepData: () => {},
  removePointStepData: () => {},
  informationStepData: null,
  setInformationStepData: () => {},
  removeInformationStepData: () => {},
  declarationStepData: null,
  setDeclarationStepData: () => {},
  removeDeclarationStepData: () => {},
  summaryStepData: null,
  setSummaryStepData: () => {},
  removeSummaryStepData: () => {},
  error: null,
  setError: () => {},
  setErrorOnFormChange: () => {},
  requestFlags: null,
  setRequestFlags: () => {},
});

export const ConvertPointsProvider = ({ rewardData, children }) => {
  const { contract } = useParams();

  const [reward, setReward, removeReward] = useSessionStorage(
    'reward',
    rewardData,
  );

  const [pointStepData, setPointStepData, removePointStepData] =
    useSessionStorage('convertPointsStep', null);
  const [
    informationStepData,
    setInformationStepData,
    removeInformationStepData,
  ] = useSessionStorage('informationStep', null);
  const [
    declarationStepData,
    setDeclarationStepData,
    removeDeclarationStepData,
  ] = useSessionStorage('declarationStep', null);
  const [summaryStepData, setSummaryStepData, removeSummaryStepData] =
    useSessionStorage('summaryStep', null);

  const [simulation, setSimulation] = useState(null);

  const [requestFlags, setRequestFlags] = useState(null);

  const { error, setError, setErrorOnFormChange } = useFormErrorHandling();

  useEffect(() => {
    return () => {
      removeReward();
      removePointStepData();
      removeInformationStepData();
      removeDeclarationStepData();
      removeSummaryStepData();

      setSimulation(null);
      setRequestFlags(null);
      setError(null);
    };
  }, []);

  if (!reward) {
    return <Redirect to={`/nodes/${contract}/home`} />;
  }

  return (
    <ConvertPointsContext.Provider
      value={{
        reward,
        setReward,
        removeReward,
        simulation,
        setSimulation,
        pointStepData,
        setPointStepData,
        removePointStepData,
        informationStepData,
        setInformationStepData,
        removeInformationStepData,
        declarationStepData,
        setDeclarationStepData,
        removeDeclarationStepData,
        summaryStepData,
        setSummaryStepData,
        removeSummaryStepData,
        error,
        setError,
        setErrorOnFormChange,
        requestFlags,
        setRequestFlags,
      }}
    >
      {children}
    </ConvertPointsContext.Provider>
  );
};

export const useConvertPoints = () => {
  const context = useContext(ConvertPointsContext);

  if (!context) {
    throw new Error(
      'Convert points context provider needs to be declared to be used',
    );
  }

  return context;
};
