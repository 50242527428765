import React, { useMemo } from 'react';
import { withStyles } from '@mui/styles';
import { neutralColors } from '@spider:src/themes';
import { Link, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Button } from '@spider:src/components';
import { PointPriceRatio } from '../PointPriceRatio';
import { Loader } from '@src/components';
import UseRewardConvertCard from '@spider:src/hooks/UseRewardConvertCard';
import { RewardHelperAboutDialog } from '@src/Spider/scenes/Home/components/UserHome/components/RewardHelperAboutDialog';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { DecorationCard } from '@src/Spider/scenes/Home/components/UserHome/components/DecorationCard';
import { PointCommandIds } from '@src/Spider/enums';
import { useDefaultRewardDetails } from '@src/Spider/hooks/useDefaultRewardDetails';
import { useRewardHelper } from '@src/Spider/hooks/useRewardHelper';
import { useRewardHelperTitle } from '@src/Spider/hooks/useRewardHelperTitle';

const styles = {
  rewardCard: {
    display: 'flex',
    position: 'relative',
    paddingBottom: '1.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1.5rem',
    flexShrink: 0,
    alignSelf: 'stretch',
    borderRadius: '1rem',
    background: neutralColors.neutralWhite,
    boxShadow: '0px 6px 10px 2px rgba(10, 31, 94, 0.12)',
  },
  iconDetail: {
    display: 'flex',
    padding: '0.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '3.875rem',
    background: neutralColors.neutralColdWhite,
  },
  details: {
    display: 'flex',
    padding: '0rem 1rem',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '1rem',
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '1rem',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '0.5rem',
  },
};

const RewardConvertCard = ({ reward, convert, ...props }) => {
  const intl = useIntl();

  const HelperAbout = useRewardHelper(reward);
  const helperDialogTitle = useRewardHelperTitle(reward);
  const rewardDetails = useDefaultRewardDetails(reward);
  const { open, onOpen, onClose } = useDisclosure();
  const { simulationResult, defaultPoint, disableConvert } =
    UseRewardConvertCard(reward);

  const decorationTitle = useMemo(() => {
    switch (reward.uuid) {
      case PointCommandIds.CKS:
        return intl.formatMessage({
          id: 'spider.convertPoints.home.cks.title',
        });
      case PointCommandIds.CKU:
        return intl.formatMessage({
          id: 'spider.convertPoints.home.cku.title',
        });
      case PointCommandIds.KSC:
        return intl.formatMessage({
          id: 'spider.convertPoints.home.ksc.title',
        });
      case PointCommandIds.KS:
        return intl.formatMessage({ id: 'spider.convertPoints.home.ks.title' });
      default:
        return intl.formatMessage({
          id: 'spider.convertPoints.home.cku.title',
        });
    }
  }, [reward.uuid]);

  return (
    <div className={props.classes.rewardCard}>
      <DecorationCard reward={reward} title={decorationTitle} />

      {simulationResult.isFetching ? (
        <Loader centered />
      ) : simulationResult.isSuccess ? (
        <PointPriceRatio
          points={simulationResult.data?.max_points_usable}
          pointsPrice={simulationResult.data?.order_suggested?.monetary_value}
        />
      ) : (
        <PointPriceRatio points={defaultPoint} pointsPrice={0} />
      )}

      {rewardDetails && (
        <div className={props.classes.details}>
          {rewardDetails.map(({ Icon, label }, index) => {
            return (
              <div key={index} className={props.classes.detail}>
                <div className={props.classes.iconDetail}>
                  <Icon />
                </div>
                <Typography variant={'body1'}>{label}</Typography>
              </div>
            );
          })}
        </div>
      )}

      <div className={props.classes.actions}>
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={() => convert(reward)}
          disabled={disableConvert}
        >
          {intl.formatMessage({
            id: 'spider.convertPoints.home.rewardsCard.convertAction',
          })}
        </Button>
        <Link className={'bold'} onClick={onOpen}>
          {intl.formatMessage({
            id: 'spider.convertPoints.home.rewardsCard.help',
          })}
        </Link>
      </div>

      <RewardHelperAboutDialog
        title={helperDialogTitle}
        onClose={onClose}
        open={open}
        disableConvert={disableConvert}
        onConvert={() => convert(reward)}
      >
        <HelperAbout />
      </RewardHelperAboutDialog>
    </div>
  );
};

export default withStyles(styles)(RewardConvertCard);
