import React, { useState } from 'react';
import { Button } from '@src/Spider/components';
import { Trash, Edit } from 'iconsax-react';
import { RightDialogFileInput } from '@spider:components/RightDialogFileInput';
import { neutralColors } from '@src/Spider';
import UseSystemFile from '@src/Spider/hooks/useSystemFile';
import {
  useDeleteSystemImageMutation,
  useUpdateHierarchyNodeSystemImageMutation,
} from '@async-calls/systemImages';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  editableImage: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'clip',
    background: ({ imageData, defaultColor }) =>
      !imageData ? defaultColor : neutralColors.neutralWhite,
  },
  editableImageActions: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '0.4rem',
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  buttonActions: {
    backgroundColor: neutralColors.neutralWhite,
    color: neutralColors.neutral900,
  },
}));

const EditableImage = ({
  imageData,
  code,
  title,
  defaultColor = '',
  altImage,
  imageWidth,
  imageHeight,
}) => {
  const { contract } = useParams();
  const [isOpenScreenRightDialog, setIsOpenScreenRightDialog] = useState(false);
  const classes = useStyles({ imageData, defaultColor });
  const onFileUpdated = () => {
    setIsOpenScreenRightDialog(false);
  };

  const onFileDeleted = () => {};

  const { updateSystemFile, deleteSystemFile } = UseSystemFile({
    fixedCacheKey: `editableImage${code}`,
    onFileUpdated,
    onFileDeleted,
    deleteSystemFileMutation: useDeleteSystemImageMutation,
    updateSystemFileMutation: useUpdateHierarchyNodeSystemImageMutation,
  });

  const deleteImage = async () => {
    if (!imageData) return;
    await deleteSystemFile(imageData.uuid);
  };

  const updateImage = async files => {
    if (!code) return;
    const file = new FormData();
    file.append('code', code);
    file.append('src', files[0]);
    await updateSystemFile(file, {
      hierarchyNodeId: contract,
      code,
    });
  };

  const openEditImage = () => {
    setIsOpenScreenRightDialog(true);
  };

  return (
    <div className={classes.editableImage}>
      {imageData && (
        <img
          alt={altImage}
          src={imageData.src}
          width={imageWidth}
          height={imageHeight}
          style={{ objectFit: 'cover' }}
        />
      )}
      <div className={classes.editableImageActions}>
        {imageData && (
          <Button
            variant={'contained'}
            color={'primary'}
            size={'small'}
            className={`size-tiny icon ${classes.buttonActions}`}
            onClick={deleteImage}
          >
            <Trash />
          </Button>
        )}
        <Button
          variant={'contained'}
          color={'primary'}
          size={'small'}
          className={`size-tiny icon ${classes.buttonActions}`}
          onClick={openEditImage}
        >
          <Edit />
        </Button>
      </div>

      <RightDialogFileInput
        open={isOpenScreenRightDialog}
        title={title}
        setOpen={value => setIsOpenScreenRightDialog(value)}
        onValid={updateImage}
      />
    </div>
  );
};

export { EditableImage };
