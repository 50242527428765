import { Box, CircularProgress, Divider, Typography } from '@material-ui/core';
import { getIntlEnumLabel } from '@src/Spider/enums/utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { Info } from './components';
import {
  Building,
  Card,
  DocumentText,
  Global,
  Import,
  InfoCircle,
  UserOctagon,
} from 'iconsax-react';
import { formatDate } from '@src/helpers/DateHelper';
import { useApprovedRulesQuery } from '@src/data/api/endpoints/hierarchyNodeUsers';
import { Button } from '@src/Spider/components';
import { neutralColors } from '@src/Spider';
/** @typedef {import("@async-calls/hierarchyNodeUsers").UserBodyDetailed} UserBodyDetailed */

/** @param {{user: UserBodyDetailed,isLoading:boolean, className: string}} props */
export const DetailsTab = ({ user, isLoading }) => {
  const { data: approvedRules } = useApprovedRulesQuery({ user_id: user.uuid });
  const intl = useIntl();

  if (isLoading || !user) return <CircularProgress />;
  
  const generalInfos = [
    { label: 'matricule', value: user.first_identifier },
    { label: 'firstname', value: user.firstname },
    { label: 'lastname', value: user.lastname },
    {
      label: 'type',
      value: user.type
        ? intl.formatMessage({
            id: getIntlEnumLabel('participant_type', user.type),
          })
        : '',
    },
    { label: 'contactEmail', value: user.contact_email },
    { label: 'creationDate', value: formatDate(user.created_at) },
    { label: 'lastUpdated', value: formatDate(user.updated_at) },
  ];

  const professionalInfos = [
    { label: 'socialReason', value: user?.legal_entity_address?.company_name },
    {
      label: 'address',
      value: <Info.AddressValue address={user?.legal_entity_address} />,
    },
  ];

  const managerInfos = [
    { label: 'matricule', value: user.manager?.first_identifier },
    { label: 'firstname', value: user.manager?.firstname },
    { label: 'lastname', value: user.manager?.lastname },
  ];

  const ckuInfos = [
    { label: 'key', value: user.cku?.key },
    {
      label: 'creationDate',
      value: formatDate(user.cku?.created_at),
    },
    { label: 'firstname', value: user.cku?.owner?.firstname },
    { label: 'lastname', value: user.cku?.owner?.lastname },
    { label: 'email', value: user.cku?.owner?.email },
    {
      label: 'address',
      value: <Info.AddressValue address={user.cku?.owner?.personal_address} />,
    },
  ];

  const kscInfos = [
    { label: 'key', value: user.ksc?.key },
    { label: 'creationDate', value: formatDate(user.ksc?.created_at) },
    { label: 'firstname', value: user.ksc?.owner?.firstname },
    { label: 'lastname', value: user.ksc?.owner?.lastname },
    { label: 'email', value: user.ksc?.owner?.email },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <Info.Card
        title='spider.participants.details.generalInfos.title'
        icon={<InfoCircle />}
      >
        {generalInfos.map(({ value, label }, i) => (
          <Info.Item
            key={i}
            value={value}
            label={intl.formatMessage({
              id: `spider.participants.details.generalInfos.fields.${label}`,
            })}
          />
        ))}
      </Info.Card>
      {Boolean(user?.legal_entity_address) && (
        <Info.Card
          title='spider.participants.details.professionalInfos.title'
          icon={<Building />}
        >
          {professionalInfos.map(({ value, label }, i) => (
            <Info.Item
              key={i}
              value={value}
              label={intl.formatMessage({
                id: `spider.participants.details.professionalInfos.fields.${label}`,
              })}
            />
          ))}
        </Info.Card>
      )}
      {Boolean(user.manager) && (
        <Info.Card
          title='spider.participants.details.managerInfos.title'
          icon={<UserOctagon />}
        >
          {managerInfos.map(({ value, label }, i) => (
            <Info.Item
              key={i}
              value={value}
              label={intl.formatMessage({
                id: `spider.participants.details.managerInfos.fields.${label}`,
              })}
            />
          ))}
        </Info.Card>
      )}
      {Boolean(approvedRules?.results.length) && (
        <Info.Card
          title='spider.participants.details.approvedRulesList.title'
          icon={<DocumentText />}
          column
        >
          {approvedRules.results.map((element, i) => (
            <React.Fragment key={element.system_file.uuid}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    color: neutralColors.neutral600,
                  }}
                >
                  <Typography color={neutralColors.neutral600}>
                    {element.system_file.filename}
                  </Typography>
                  <Typography color={neutralColors.neutral600}>
                    {formatDate(element.system_file.created_on)}
                  </Typography>
                </Box>
                <Button
                  variant='text'
                  color='primary'
                  size='small'
                  component='a'
                  href={element.system_file.src}
                  style={{ backgroundColor: neutralColors.neutralWhite }}
                >
                  <Import />
                </Button>
              </Box>
              {i < approvedRules.results.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Info.Card>
      )}
      {Boolean(user?.cku) && (
        <Info.Card
          title='spider.participants.details.ckuInfos.title'
          icon={<Card />}
        >
          {ckuInfos.map(({ value, label }, i) => (
            <Info.Item
              key={i}
              value={value}
              label={intl.formatMessage({
                id: `spider.participants.details.ckuInfos.fields.${label}`,
              })}
            />
          ))}
        </Info.Card>
      )}
      {Boolean(user.ksc) && (
        <Info.Card
          title='spider.participants.details.kscInfos.title'
          icon={<Global />}
        >
          {kscInfos.map(({ value, label }, i) => (
            <Info.Item
              key={i}
              value={value}
              label={intl.formatMessage({
                id: `spider.participants.details.kscInfos.fields.${label}`,
              })}
            />
          ))}
        </Info.Card>
      )}
    </Box>
  );
};
