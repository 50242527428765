import React from 'react';
import { useIntl } from 'react-intl';
import { Link, withStyles } from '@material-ui/core';
import { useLinkOpener } from '@src/Spider/hooks/useLinkOpener';
import { createTheme } from '@material-ui/core/styles';
import { useAuth } from '@src/auth';

const styles = {
  legalDocumentList: {
    display: 'flex',
    alignItems: 'center',
    paddingInlineStart: '0 !important',
    flexDirection: 'row',
    listStyleType: 'disc',
    gap: '0',
    '& li': {
      listStylePosition: 'inside',
      marginLeft: '14px',
    },
    [createTheme().breakpoints.down('md')]: {
      flexDirection: 'column',
      listStyleType: 'none',
      gap: '0.5rem',
      '& li': {
        listStylePosition: 'none',
        marginLeft: '0px',
      },
    },
  },
};

const Footer = ({ oneTrustKey, ...props }) => {
  const intl = useIntl();
  const { openLink } = useLinkOpener();

  return (
    <>
      <footer>
        <div className={'footer-content'}>
          {/* TODO: le n° de version */}
          <ul className={props.classes.legalDocumentList}>
            <li> {intl.formatMessage({ id: 'spider.app_name' })} V1.0.0</li>
            <li key={'general-condition'}>
              <Link className={'bold small'} onClick={() => openLink('')}>
                {intl.formatMessage({
                  id: 'spider.footer.generalUseConditions',
                })}
              </Link>
            </li>
            <li key={'rgpd'}>
              <Link
                className={'bold small'}
                // onClick={() => openLinkByCode(SystemFileCodes.RGPD)}
              >
                {intl.formatMessage({ id: 'spider.footer.dataProtection' })}
              </Link>
            </li>
            <li key={'legal-mention'}>
              <Link
                className={'bold small'}
                // onClick={() => openLinkByCode(SystemFileCodes.LEGAL_MENTIONS)}
              >
                {intl.formatMessage({ id: 'spider.footer.legalMentions' })}
              </Link>
            </li>
            {oneTrustKey && (
              <li key={'cookies'}>
                <Link
                  id='ot-sdk-btn'
                  className={'ot-sdk-show-settings bold small'}
                >
                  {intl.formatMessage({ id: 'spider.footer.cookies' })}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </footer>
    </>
  );
};

export default withStyles(styles)(Footer);
