import React, { useMemo } from 'react';
import { TextFieldFormsy } from '@spider:components/FormsyComponents/TextFieldFormsy';
import { useIntl } from 'react-intl';
import { useBaseFormsyValidation } from '@src/Spider/hooks/useBaseFormsyValidation';

/**
 * @param {{
 *   formData: Object,
 *   baseProps: string|null,
 *   classes: any,
 *   error: any,
 *   showCompanyName: boolean,
 * }} props
 */
const Component = ({
  formData,
  baseProps = null,
  classes,
  error,
  handleFormChange,
  showCompanyName = false,
}) => {
  const intl = useIntl();
  const { baseFormsyValidationErrors, baseFormsyValidations } =
    useBaseFormsyValidation();

  const baseNameProps = useMemo(
    () => `${baseProps ? `${baseProps}.` : ''}`,
    [baseProps],
  );

  const line1MaxLength = 35,
    line2MaxLength = 35,
    zipCodeMaxLength = 5,
    cityMaxLength = 29,
    socialRaisonMaxLength = 35;

  return (
    <div className={classes.formFields}>
      {showCompanyName && (
        <TextFieldFormsy
          name={`${baseNameProps}company_name`}
          value={formData.company_name}
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.employerAddress.socialRaison',
          })}
          variant={'outlined'}
          required
          validations={{
            ...baseFormsyValidations({
              maxLength: socialRaisonMaxLength,
              customErrorMessage: error?.company_name,
            }),
          }}
          validationErrors={{
            ...baseFormsyValidationErrors({
              isRequired: true,
              maxLength: socialRaisonMaxLength,
              customErrorMessage: error?.company_name,
            }),
          }}
          onChange={handleFormChange}
        />
      )}
      <TextFieldFormsy
        name={`${baseNameProps}line1`}
        value={formData.line1}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalAddress.addressField',
        })}
        variant={'outlined'}
        required
        validations={{
          ...baseFormsyValidations({
            maxLength: line1MaxLength,
            customErrorMessage: error?.line1,
          }),
        }}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
            maxLength: line1MaxLength,
            customErrorMessage: error?.line1,
          }),
        }}
        onChange={handleFormChange}
      />
      <TextFieldFormsy
        name={`${baseNameProps}line2`}
        value={formData.line2}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalAddress.complementaryAddressField',
        })}
        variant={'outlined'}
        validations={{
          ...baseFormsyValidations({
            maxLength: line2MaxLength,
            customErrorMessage: error?.line2,
          }),
        }}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
            maxLength: line2MaxLength,
            customErrorMessage: error?.line2,
          }),
        }}
        onChange={handleFormChange}
      />
      <TextFieldFormsy
        name={`${baseNameProps}postal_code`}
        value={formData.postal_code}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalAddress.zipCodeField',
        })}
        variant={'outlined'}
        required
        validations={{
          ...baseFormsyValidations({
            maxLength: zipCodeMaxLength,
            customErrorMessage: error?.postal_code,
          }),
          hasDigitCharacterOnly: true,
        }}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
            maxLength: zipCodeMaxLength,
            customErrorMessage: error?.postal_code,
          }),
          hasDigitCharacterOnly: intl.formatMessage({
            id: 'common.form.has_digit_character_only',
          }),
        }}
        onChange={handleFormChange}
      />
      <TextFieldFormsy
        name={`${baseNameProps}city`}
        value={formData.city}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalAddress.cityField',
        })}
        variant={'outlined'}
        required
        validations={{
          ...baseFormsyValidations({
            maxLength: cityMaxLength,
            customErrorMessage: error?.city,
          }),
        }}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
            maxLength: cityMaxLength,
            customErrorMessage: error?.city,
          }),
        }}
        onChange={handleFormChange}
      />
    </div>
  );
};

const BaseAddressForm = Component;

export { BaseAddressForm };
